import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HandlebarsTextBox from 'handlebars-textbox';
import EditAttachmentModal from '../../components/EditAttachmentModal';
import { FromField } from './FromField';
import lodash from 'lodash';


class XEmailHeaders extends Component {

  constructor (props) {
    super(props);
    this.state = {
      expanded: false,      
    };
  }

  changeState (name, value) {
    this.props.onHeaderChange(name, value);
  }

  renderField (id, title, value, fixed = true) {
    if (fixed || this.state.expanded) {
      return <div className='field'>
        <span className={'field-caption'}>{title}:</span>
        <span className={'field-input'}>
          <HandlebarsTextBox
            className='field-input'
            value={value || ''}
            onChange={(v) => this.changeState(id, v)} />
        </span>
      </div>;
    }
    return null;
  }

  onOpenEditAttachment(a, index) {
    this.setState({
      attachmentToEdit: a,
      attachmentModalOpen: true,
      attachmentToEditIndex: index,
    });
  }

  renderAttachmentField(a, i) {
    if (!a) {
      return null;
    }

    const { t } = this.props;
    return <div className={'attachment-field'}>
      <i className={'fa fa-trash x-action'} onClick={() => {
        let att = (this.props.headers || {}).attachments;
        att.splice(i,1);
        this.props.onHeaderChange('attachments', att);
      }}/>
      &nbsp;
      <span style={{ cursor:'pointer' }} onClick={()=>this.onOpenEditAttachment(a, i)}>
        {t('attachments.view-attachment-filename')}&nbsp;<span className={'attachment-field-filename'}>{a.filename}</span>,&nbsp;
        {a.contenttype
          ? <Fragment>{t('attachments.view-attachment-content-type')}&nbsp;<span className={'attachment-field-contenttype'}>{a.contenttype}</span>,&nbsp;</Fragment>
          : null}
        {t('attachments.view-attachment-content')}&nbsp;<span className={'attachment-field-filename'}>{lodash.truncate(a.content)}</span>
      </span>
    </div>;
  }

  renderAttachments(attachments, fixed) {
    const { t } = this.props;
    if (fixed || this.state.expanded) {
      return <div className='field attachments'>
        <span className={'field-caption'}>{this.props.t('attachments.view-field-name')}:</span>
        <span className={'attachment-field-input'}>
          {attachments.map((a,i) => this.renderAttachmentField(a, i))}
          <div className={'attachment-add-button-wrap'}>
            <span className="attachment-add-button" onClick={() => {
              let h = (this.props.headers || {}).attachments || [];
              h.push({
                filename: 'untitled',
                content: ''
              });
              this.props.onHeaderChange('attachments', h);
            }}>
              <i className={'fa fa-plus x-action'} />&nbsp;{t('add')}
            </span>
          </div>
        </span>
      </div>;
    }
    return null;
  }

  render () {
    const { t } = this.props;
    const expanded = this.state.expanded;
    const headers = this.props.headers || {};

    return (
      <div className={'xemail-headers'}>
        <EditAttachmentModal
          t={t}
          modalOpen={this.state.attachmentModalOpen}
          attachment={this.state.attachmentToEdit}
          onClose={() => this.setState({
            attachmentModalOpen: false,
            attachmentToEdit: null,
          })}
          onConfirm={(a) => {
            let h = (this.props.headers || {}).attachments || [];
            h[this.state.attachmentToEditIndex] = a;
            this.setState({
              attachmentModalOpen: false,
              attachmentToEdit: null,
            }, () =>
              this.props.onHeaderChange('attachments', h));
          }}
        />
        {this.state.expanded 
          ? <FromField from={headers.from} onChange={newFrom => {
            this.changeState('from', newFrom);
          }}/> : null}
        {this.renderField('to', t('to'), headers.to)}
        {this.renderField('replyTo', t('reply_to'), headers.replyTo, false)}
        {this.renderField('cc', t('cc'), headers.cc, false)}
        {this.renderField('bcc', t('bcc'), headers.bcc, false)}
        {this.renderField('subject', t('subject'), headers.subject)}
        {this.renderAttachments(headers.attachments || [], false)}
        {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className='expand-headers-button' onClick={() => this.setState({ expanded: !expanded })}>
          {expanded
            ? <span><i className='fa fa-caret-up' /> {t('show_fewer')}</span>
            : <span><i className='fa fa-caret-down' /> {t('show_more')}</span>}
        </a>
      </div>);
  }
}

XEmailHeaders.propTypes = {
  onHeaderChange: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired
};

export default XEmailHeaders;
