export enum ProfileName {
  Default = 'DEFAULT',
  Embedded = 'EMBEDDED',
  Advanced = 'ADVANCED'
}

export interface TFlag {
  enabled: boolean;
}
export interface TDProfile {
  header: TFlag;
  feedbackSticker: TFlag,
  features: {
    preview: TFlag,
    saveAs: TFlag,
    manageAccess: TFlag,
    deleteTemplate: TFlag
  },
  templateComponents: {
    htmlBlock: TFlag,
    mjmlBlock: TFlag,
    subTemplate: TFlag   
  }
}

const ENABLED: TFlag = { enabled: true };
const DISABLED: TFlag = { enabled: false };

export const defaultProfile: TDProfile = {
  header: ENABLED,
  feedbackSticker: ENABLED,
  features: {
    preview: ENABLED,
    saveAs: ENABLED,
    manageAccess: ENABLED,
    deleteTemplate: ENABLED
  },
  templateComponents: {
    htmlBlock: DISABLED,
    mjmlBlock: ENABLED,
    subTemplate: ENABLED
  }
};

export const advancedProfile: TDProfile = {
  header: ENABLED,
  feedbackSticker: ENABLED,
  features: {
    preview: ENABLED,
    saveAs: ENABLED,
    manageAccess: ENABLED,
    deleteTemplate: ENABLED
  },
  templateComponents: {
    htmlBlock: ENABLED,
    mjmlBlock: ENABLED,
    subTemplate: ENABLED
  }
};

export const embeddedProfile: TDProfile = {
  header: DISABLED,
  feedbackSticker: DISABLED,
  features: {
    preview: ENABLED,
    saveAs: DISABLED,
    manageAccess: DISABLED,
    deleteTemplate: DISABLED
  },
  templateComponents: {
    htmlBlock: DISABLED,
    mjmlBlock: DISABLED,
    subTemplate: DISABLED
  }
};

export const getDesignerProfile = (name: ProfileName): TDProfile => {
  switch (name) {
    case ProfileName.Embedded:
      return embeddedProfile;
    case ProfileName.Advanced:
      return advancedProfile;
    default:
      return defaultProfile;
  }
};