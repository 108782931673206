import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { draggableResource, XResource } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import Icon from './icon_column.svg';

class XColumnResource extends Component {
  render () {
    return draggableResource(this.props, <div>
      <XResource
        resourceName={'Column'}
        resourceIcon={Icon} />
    </div>
    );
  }
}

const source = {
  beginDrag (_props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.COLUMN
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.COLUMN, source, collect)(XColumnResource);
