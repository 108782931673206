import React, { Component, Fragment } from 'react';
import { ColorUtils } from '../../../../../../utils/helpers';
import debounce from 'debounce';
import TextFieldColorPicker from '../../../../../generic/TextFieldColorPicker';
import { Checkbox, Radio, RadioGroup, Select, TextField, Tooltip } from '@cimpress/react-components';
import { parse } from 'mailto-parser';
import CssPaddingSelector from '../../../../../generic/CssPaddingSelector';
import {
  convertToOptions,
  SupportedAlignmentsOptions,
  SupportedBorderSizesOptions,
  SupportedButtonWidthsOptions
} from '../../../../../../utils/options';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { DEBOUNCE_TIME_INTERVAL } from '../../../../../../constants';
import LeftRightBlock from '../../../../../generic/LeftRightBlock';

interface XButtonPropertiesState {
  radioButtonSelected: string;
  linkMailTo: any;
  updates: any;
  linkHref: any;
  addPureMailSignature: any;
}

const parseMailTo = (link) => {
  let linkMailTo: any = null;
  if ((link || '').startsWith('mailto:')) {
    try {
      const parsed = parse(decodeURIComponent(link));
      linkMailTo = Object.assign({}, parsed.attributeKey, { to: parsed.to });
      if (linkMailTo.body && linkMailTo.body.indexOf('*|PUREMAIL_SIGNATURE_URLENCODED|*') !== -1) {
        linkMailTo.body = linkMailTo.body.replace(new RegExp(/(%0A|\n)*\*\|PUREMAIL_SIGNATURE_URLENCODED\|\*$/), '');
      }
    } catch (e) {
      //
    }
  }
  return linkMailTo;
};

const generateMailToLink = (linkMailTo, addPureMailSignature) => {
  const l = linkMailTo || {};
  let response = 'mailto:' + (l.to ? `${l.to}?` : '?');
  let attrs: string[] = ['cc', 'bcc', 'subject', 'body']
    .reduce((accum: string[], attr) => {
      if (l[attr]) {
        accum.push(`${attr}=${l[attr].replace(/\n/g, '%0A')}`);
      }
      return accum;
    }, []);

  if (addPureMailSignature) {
    const signature = '%0A%0A*|PUREMAIL_SIGNATURE_URLENCODED|*';
    if (l.body) {
      attrs[attrs.length - 1] += signature;
    } else {
      attrs.push(`body=${signature}`);
    }
  }
  let r = response + attrs.join('&');
  return r;
};

class XButtonProperties extends Component<XBlockPropertiesProps, XButtonPropertiesState> {
  updateProperties: Function;

  constructor(props: XBlockPropertiesProps) {
    super(props);
    const mailTo = parseMailTo(props.properties.href);
    this.state = {
      updates: props.properties,
      linkHref: mailTo ? null : props.properties.href,
      linkMailTo: mailTo,
      radioButtonSelected: mailTo === null ? 'link' : 'mail',
      addPureMailSignature: (props.properties.href || '').indexOf('PUREMAIL_SIGNATURE_URLENCODED') !== -1
    };
    this.updateProperties = debounce(this.confirm, DEBOUNCE_TIME_INTERVAL);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.properties) !== JSON.stringify(this.props.properties)) {
      this.setState({
        updates: this.props.properties
      });
    }
    if (JSON.stringify(prevState) !== JSON.stringify(this.state) && prevProps.id === this.props.id) {
      this.updateProperties();
    }
    if (prevProps.id !== this.props.id) {
      this.props.onUpdateItem(
        { id: prevProps.id },
        Object.assign({},
          this.state.updates, {
            href: this.state.radioButtonSelected === 'mail'
              ? generateMailToLink(this.state.linkMailTo, this.state.addPureMailSignature)
              : this.state.linkHref
          }));
    }
  }

  confirm = () => {
    this.props.onUpdateItem(
      { id: this.props.id },
      Object.assign({},
        this.state.updates, {
          href: this.state.radioButtonSelected === 'mail'
            ? generateMailToLink(this.state.linkMailTo, this.state.addPureMailSignature)
            : this.state.linkHref
        }));
  }

  changeEmailLink(item) {
    this.setState({ linkMailTo: Object.assign({}, this.state.linkMailTo, item) });
  }

  renderEmailLinkForm() {
    const MAX_CHARACTERS = 2000; // Body max characters, 2083 due to some email clients doesn't support longer ones
    const { t } = this.props;
    let linkMailTo = this.state.linkMailTo || {};
    const body = linkMailTo && (linkMailTo.body || '');

    return <React.Fragment>
      <TextField
        label={t('toolbar.to')}
        value={linkMailTo.to || ''}
        onChange={(e) => this.changeEmailLink({ to: e.target.value })}
        autoFocus />
      <TextField
        label={t('toolbar.cc')}
        value={linkMailTo.cc || ''}
        onChange={(e) => this.changeEmailLink({ cc: e.target.value })}
      />
      <TextField
        label={t('toolbar.bcc')}
        value={linkMailTo.bcc || ''}
        onChange={(e) => this.changeEmailLink({ bcc: e.target.value })} />
      <TextField
        label={t('toolbar.subject')} value={linkMailTo.subject || ''}
        onChange={(e) => this.changeEmailLink({ subject: e.target.value })} />
      <TextField
        type='textarea'
        label={t('toolbar.body')}
        value={body}
        onChange={(e) => {
          const bodyLength = e.target.value.length;
          if (bodyLength <= MAX_CHARACTERS) {
            this.changeEmailLink({ body: e.target.value });
          }
        }}
        helpText={`${body.length} ${t('toolbar.characters_of')} ${MAX_CHARACTERS}`} />

      <LeftRightBlock wrap={true}
        left={<Checkbox
          label={`${t('toolbar.add_puremail_signature')} (${t('toolbar.expert')})`}
          checked={this.state.addPureMailSignature}
          onChange={() => this.setState({ addPureMailSignature: !this.state.addPureMailSignature })} />}
        right={<Tooltip contents={t('toolbar.explanation_puremail_signature_checkbox')} direction={'right'}>
          <i className={'fa fa-2x fa-question-circle context-button-icon'} />
        </Tooltip>} />
    </React.Fragment>;
  }

  renderLinkForm() {
    return <TextField
      name='link'
      label={this.props.t('toolbar.link_url')}
      value={this.state.linkHref}
      onChange={(e) => this.setState({ linkHref: e.target.value })} autoFocus />;
  }

  render() {
    const { t } = this.props;
    const props = this.state.updates || this.props.properties || {};

    const stylesProperties = <Fragment>
      <TextFieldColorPicker
        name='color'
        label={t('blocks.color')}
        helpText={t('blocks.color_help_text')}
        color={ColorUtils.rgb2hex(props.color)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { color: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { color: hexColor }) });
        }} />
      <TextFieldColorPicker
        name='backgroundColor'
        label={t('blocks.background_color')}
        helpText={t('blocks.background_color_help_text')}
        color={ColorUtils.rgb2hex(props.backgroundColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: hexColor }) });
        }} />

      <Select
        name='width'
        label={t('blocks.width')}
        helpText={t('blocks.width_help_text')}
        value={convertToOptions(props.width, SupportedButtonWidthsOptions)}
        options={SupportedButtonWidthsOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { width: e ? e.value : null }) })}
        isClearable={true}
      />
      <Select
        name='textAlign'
        label={t('blocks.text_align')}
        helpText={t('blocks.text_align_help_text')}
        value={convertToOptions(props.textAlign, SupportedAlignmentsOptions)}
        options={SupportedAlignmentsOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { textAlign: e ? e.value : null }) })}
        isClearable={true}
      />

      <Select
        name='borderSize'
        label={t('blocks.border_size')}
        helpText={t('blocks.border_size_help_text')}
        value={convertToOptions(props.borderSize, SupportedBorderSizesOptions)}
        options={SupportedBorderSizesOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { borderSize: e ? e.value : null }) })}
        isClearable={true}
      />
      <TextFieldColorPicker
        name='borderColor'
        label={t('blocks.border_color')}
        helpText={t('blocks.border_color_help_text')}
        color={ColorUtils.rgb2hex(props.borderColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { borderColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { borderColor: hexColor }) });
        }}
      />

      <CssPaddingSelector
        t={t}
        label={t('blocks.padding')}
        helpText={t('blocks.padding_help_text')}
        padding={props.padding}
        onChange={(value) => this.setState({ updates: Object.assign({}, this.state.updates, { padding: value }) })}
      />

      <TextField
        name='class'
        label={t('blocks.class')}
        helpText={t('blocks.class_help_text')}
        value={props.class}
        onChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { class: e.target.value }) });
        }} />

    </Fragment>;

    let linksProperties = <Fragment>

      <TextField
        label={t('blocks.button_caption')}
        value={props.content || ''}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { content: e.target.value }) })}
        autoFocus />

      <RadioGroup
        className='linkTypeRadioGroup'
        onChange={(e, radioButtonSelected) => this.setState({ radioButtonSelected })}
        inline
        defaultSelected={this.state.radioButtonSelected}
        name='radioTest'>
        <Radio label='Link' value='link' />
        <Radio label={t('toolbar.email_to')} value='mail' />
      </RadioGroup>

      {this.state.radioButtonSelected === 'link'
        ? this.renderLinkForm()
        : this.renderEmailLinkForm()}

    </Fragment>;

    return <React.Fragment>
      <h5>{t('blocks.button_properties_link_tab')}</h5>
      {linksProperties}
      <h5>{t('blocks.button_properties_styles_tab')}</h5>
      {stylesProperties}
    </React.Fragment>;
  }
}

export default XButtonProperties;
