import { AuthActions } from '../storeActions';
import { AuthActionTypes, AuthState } from './types';

const initialAuthState: AuthState  = {
  profile: {
    sub: '',
    email: '',
    name: ''
  },
  accessToken: '',
  loading: false
};

const authReducer = (state = initialAuthState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AuthActions.REFRESH_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.accessToken
      });
    case AuthActions.REFRESH_PROFILE:
      return Object.assign({}, state, {
        profile: action.profile
      });
    case AuthActions.LOGOUT:
      return Object.assign({}, state, {
        profile: {
          email: '',
          name: '',
          sub: ''
        },
        accessToken: ''
      });
    case AuthActions.LOGIN_LOADING:
      return Object.assign({}, state, {
        loading: action.loading || false
      });
    default:
      return state;
  }
};
export default authReducer;
