import React, { Component } from 'react';

import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { TerminalCommonProps } from '../../../models/XBlockProps';
import { XBlockStateWithContent } from '../../../models/XBlockState';
import XSubTemplateHeader from './XSubTemplateHeader';
import { Tag, Tooltip } from '@cimpress/react-components';

class XSubTemplate extends Component<TerminalCommonProps, XBlockStateWithContent>{

  render () {
    const { t } = this.props;
    const itemProps = this.props.renderProps.item.properties;
    let content = <span>
      {Object.keys(itemProps.parameters || {}).length > 0
        ? Object.keys(itemProps.parameters).map(paramKey => (
          <>{`${paramKey} = ${itemProps.parameters[paramKey]}`}<br/></>
        ))
        : t('blocks.no_parameters')}
    </span>;

    return <div
      className='xemail-block-default-container xemail-subtemplate-container'
      {...this.props.defaultHandlers}>

      <XSubTemplateHeader {...this.props}/>

      <div className="xemail-subtemplate-body">
        {t('template') + ': '}
        {itemProps.templateUrn
          ? <>
            {itemProps.templateName || (itemProps.templateUrn || '').split(':').slice(-1)[0]}
            &nbsp;&nbsp;
            {<Tooltip direction="right" contents={content}><Tag value={t('blocks.parameters')} /></Tooltip>}
          </>
          : t('blocks.no_template_selected')}
      </div>

    </div>;
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.SUB_TEMPLATE,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}

export default DragSource(EDIE_BLOCK_TYPE.SUB_TEMPLATE, source, collect)(XSubTemplate);
