import React , { useState } from 'react';
import { colors, Tooltip } from '@cimpress/react-components';
import EscapableModal from '../../components/EscapableModal';
import DeleteButton from '../../../generic/buttons/DeleteButton';
import CancelButton from '../../../generic/buttons/CancelButton';
import LeftRightBlock from '../../../generic/LeftRightBlock';
import { useTranslation } from 'react-i18next';
import { Context } from '../../components/SideNav';

const TwoColumns = (props) => {
  return <div className={'row'}>
    <div className={'col-md-6'}>
      {props.left}
    </div>
    <div className={'col-md-6'}>
      {props.right}
    </div>
  </div>;
};

const BlockDeleteButton = (props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const deleteChild = props.haveChildren ? <div>{t('confirm_child_block_deletion')}</div> : '' ;
  const renderModalFooter = () =>{
    let right = <div>
      <CancelButton
        faIcon={'fa-window-minimize'}
        title= {t('close')}
        onClick={() => setShowConfirmationModal(false) } /> 
      &nbsp;
      <DeleteButton
        title={t('confirm')}
        onClick={() =>{
          setShowConfirmationModal(false);
          props.onClick();
        }} />
    </div>;
  
    return <LeftRightBlock left={null} right={right} />;
  };

  return <>
    <EscapableModal
      status={'danger'}
      show={showConfirmationModal}
      onRequestHide={() => setShowConfirmationModal(true)}
      title={t('delete_block')}
      footer={renderModalFooter(props)}>
      <div>{t('confirm_delete_block')}</div>
      {deleteChild}
    </EscapableModal>
    <span className={'xemail-header-button'} onClick={() => setShowConfirmationModal(true)}>
      <i className={'fa fa-trash x-action'} aria-hidden={'true'}/>
    </span>
  </>;
};

const BlockConfigButton = (props) => {
  return <span className='xemail-header-button' onClick={() => props.onClick()}>
    <i className={'fa fa-cog'} aria-hidden={'true'}/>
  </span>;
};

const BlockCloneButton = (props) =>{
  return <span className= 'xemail-header-button' onClick ={props.onClick}>
    <i className={'fa fa-clone'} aria-hidden={'true'}/>
  </span>;
};

const XResource = (props) => {
  const { t } = useTranslation();
  return <div className='card' style={{ marginBottom: '10px' }}>
    <Tooltip direction='right' contents={t(`block-description.xcsv.${props.resourceName}`)} style={{ display: 'block' }}>
      <div className='draggable-resource' style={{ cursor: '-webkit-grab', padding: '10px' }}>
        <Context.Consumer>
          { value =>
            <div className={'clearfix'}>
              <div className={'pull-left'}>
                <img alt='' src={props.resourceIcon} width={19} height={19} />
              </div>
              { value && <>
                <div className={'pull-left'}>
                  &nbsp;
                </div>
                <div className={'pull-left'}>
                  <div style={{ lineHeight:'24px' }}>{props.resourceName}</div>
                </div>  
                <div style={{ lineHeight:'24px',color: colors.slate, cursor: '-webkit-grab' }} className={'pull-right'}>
                  <i className='fa fa-ellipsis-v' aria-hidden='true' style={{ paddingRight: '2px' }} />
                  <i className='fa fa-ellipsis-v' aria-hidden='true' style={{ paddingRight: '2px' }} />
                </div>
              </>}
            </div>
          }
        </Context.Consumer>
      </div>
    </Tooltip>
  </div>;
};

const FullWidthVerticalCenter = (props) => {
  return <FullWidthItem {...props}>
    <div className={'outer'}>
      <div className={'middle'}>
        <div className={'inner'}>
          {props.children}
        </div>
      </div>
    </div>
  </FullWidthItem>;
};

const FullWidthItem = (props) => {
  return <div className='flex-full-width' onDoubleClick={props.onDoubleClick}>
    {props.children}
  </div>;
};

const draggableResource = (props, node) => {
  return props.connectDragPreview(props.connectDragSource(node), {
    anchorX: 0,
    anchorY: 0,
    offsetX: 0,
    offsetY: 0
  });
};


export {
  TwoColumns,
  BlockCloneButton,
  BlockConfigButton,
  BlockDeleteButton,
  FullWidthItem,
  FullWidthVerticalCenter,
  XResource,
  draggableResource
};
