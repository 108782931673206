import { ValueOf } from './utils/helpers';

const RawEditorSettings = {

  defaults: {
    mode: 'xml',
    theme: 'monokai',
    fontSize: '12'
  },

  supportedModes: ['xml', 'json', 'html', 'markdown', 'handlebars'],
  supportedThemes: ['github', 'monokai'],
  supportedFontSizes: [10, 12, 14]
};

const TemplateType = {
  XEMAIL: 'xemail',
  XDOC: 'xdoc',
  XCSV: 'xcsv',
  RAW: 'raw',
} as const;

const Stereotype = {
  xCimpressRelBlacklist: 'https://fulfillment.at.cimpress.io/rels/jobsheet,https://fulfillment.at.cimpress.io/rels/document,https://fulfillment.at.cimpress.io/rels/notifications,account',
  // xCimpressRelWhitelist: '', // 'mentionedPrincipal,currentComment,resource,comments,createdByPrincipal,updatedByPrincipal',
  defaultTimeout: 30000,
  acceptPreference: 'image/*;q=0.1,application/pdf;q=0.95,application/links+json;q=0.9,application/hal+json;q=0.8,application/json;q=0.7,*/*;q=0.6',
  maximumCrawlDepth: 3,
  crawlerSoftErrors: '403,404',
  curie: 'https://relations.cimpress.io/pool/print-job;fen',
};

const SupportedContentTypesValueLabel = [
  {
    value: 'text/x-handlebars-template', label: 'Handlebars'
  }, {
    value: 'text/x-handlebars-template; postprocessors=mjml', label: 'Handlebars + MJML'
  }, {
    value: 'application/vnd.cimpress.edie+json', label: 'EDIE'
  }, {
    value: 'application/vnd.cimpress.edie+json; processors="edie,handlebars,mjml"', label: 'EDIE/HTML'
  }, {
    value: 'application/vnd.cimpress.edie+json; processors="edie,handlebars,mjml,htmlpdf"', label: 'EDIE/PDF'
  }, {
    value: 'application/vnd.cimpress.edie+json; processors="ediecsv,handlebars"', label: 'EDIE/CSV'
  }, {
    value: 'application/vnd.cimpress.edie+json; processors="ediecsv,handlebars,xlsx"', label: 'EDIE/XLSX'
  }
];

const PureMail = {
  XCimpressReportGroupHeader: 'x-cimpress-report-group',
  XCimpressPropertiesHeader: 'x-cimpress-properties'
};

export type TemplateTypeValues = ValueOf<typeof TemplateType>;

export {
  TemplateType,
  RawEditorSettings,
  Stereotype,
  PureMail,
  SupportedContentTypesValueLabel,
};

export const DEBOUNCE_TIME_INTERVAL = 1000;

export const downloadablePreviewContentType = ['htmlpdf', 'xlsx'];

export const xlsxTemplateContentType = 'application/vnd.cimpress.edie+json; processors="ediecsv,handlebars,xlsx"';
export const csvTemplateContentType = 'application/vnd.cimpress.edie+json; processors="ediecsv,handlebars"';