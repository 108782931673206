import { XBlockProps } from '../../../models/XBlockProps';
import React from 'react';
import { FullWidthItem } from '../../commonComponents';
import { DragSource } from 'react-dnd';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';

const XConditionHeader: React.FC<XBlockProps> = props =>{

  const { t } = props;
  const item = props.renderProps.flatStructure[props.id];
  const itemProps = item.properties;

  return props.connectDragSource && props.connectDragSource(
    <div className={`xemail-block-default-header xemail-condition-header ${props.isElementFocused ? 'xboard-block-highlight ' : ''}`}>
      <FullWidthItem>
        <span className={'highlight'}>{t('blocks.if')}</span>
        {' '}
        <strong>{itemProps.condition}</strong>
      </FullWidthItem>
    </div>
  );
};

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.CONDITION,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}


export default DragSource(EDIE_BLOCK_TYPE.CONDITION, source, collect)(XConditionHeader);