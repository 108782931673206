import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@cimpress/react-components';
import { Portal } from 'react-portal';
import CancelButton from '../../generic/buttons/CancelButton';
import { connect } from 'react-redux';
import { UsersTable } from '@cimpress-technology/react-cimpress-users';
import SharingOptions from './SharingOptions';
import i18n from '../../../utils/i18n';

class AccessDrawer extends Component {

  render () {
    const { t } = this.props;

    let sharingLink = `${window.location.origin}/designer/${encodeURI(this.props.templateProperties.templateId)}`;
    let sharingPayload = JSON.stringify(this.props.providedOriginalPayload);
    let sharingHeaders = JSON.stringify(this.props.headers);
    if (this.props.providedOriginalPayload && sharingPayload !== '{}') {
      sharingLink += '?payload=' + encodeURIComponent(sharingPayload);
      if (this.props.headers && sharingHeaders !== '{}') {
        sharingLink += '&headers=' + encodeURIComponent(sharingHeaders);
      }
    }

    let drawerContent = <div style={{ marginTop:'-20px' }}>
      <SharingOptions
        t={t}
        accessToken={this.props.accessToken}
        templateId={this.props.templateProperties.templateId}
        templateDesignerUrl={sharingLink} />
      <UsersTable
        language={i18n.language}
        accessToken={this.props.accessToken}
        groupUrl={this.props.groupUrl}
        mutuallyExclusiveRoles
        showAdminsOnlyFilter={false}
        allowedRoles={[{
          roleName: 'Template Editor',
          roleCaption: 'Editor',
          isManagerRole: true
        }, {
          roleName: 'Template Reader',
          roleCaption: 'Reader',
          isManagerRole: false
        }]}
      />
    </div>;

    return <Portal>
      <Drawer
        closeOnClickOutside
        show={this.props.show}
        header={
          <div>
            <h4>{t('access.drawer_title')}</h4>
            <h5 style={{ textAlign: 'left' }}>
              {t('access.template_name')}:<br/>
              <span className={'active-template-name-full'}>{this.props.templateProperties.templateName}</span><br/>
              {t('access.template_id')}:<br/>
              <span className={'active-template-name-full'}>{this.props.templateProperties.templateId}</span><br/>
            </h5>
          </div>
        }
        children={this.props.show ? drawerContent : null}
        onRequestHide={() => this.props.onHide()}
        footer={<div>
          <CancelButton title={t('access.cancel_button')} onClick={() => { this.props.onHide(); }} />
        </div>
        } />
    </Portal>;
  }
}

AccessDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  groupUrl: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    templateProperties: state.appReducer.templateProperties,
    providedOriginalPayload: state.payload.providedOriginalPayload,
    headers: state.payload.headers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessDrawer);
