import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StereotypeClient } from '@cimpress-technology/stereotype-client';
import { connect } from 'react-redux';
import CoamEnabledActionsBarButton from './base/CoamEnabledActionsBarButton';
import CancelButton from '../../../generic/buttons/CancelButton';
import * as appActions from '../../../../store/app/actions';
import Loading from '../../../generic/Loading';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import EscapableModal from '../EscapableModal';
import ConfirmButton from '../../../generic/buttons/ConfirmButton';
import ErrorDetails from '../../../generic/ErrorDetails';
import { Trans } from 'react-i18next';
import config from '../../../Pages/Samples/config.json';

const BASE_URL =  process.env.REACT_APP_TEMPLATE_DESIGNER_API_URL;

class SaveTemplateButton extends Component {

  constructor (props) {
    super(props);
    this.state = {
      modalOpen: false,
      completed: false
    };
  }

  saveTemplate () {

    // eslint-disable-next-line no-undef
    showOverlay();

    // Get the action "thing" to save
    this.props.boardExporterPromise({
      templateObject: this.props.templateObject,
      edie: this.props.edie
    })
      .then(templateData => {

        const data = templateData;

        // Store
        const client = new StereotypeClient(`Bearer ${this.props.accessToken}`,{ baseUrl: BASE_URL });
        client.putTemplateById({
          templateId: this.props.templateProperties.templateId,
          templateType: this.props.templateProperties.templateType,
          contentType: this.props.templateProperties.contentType,
          templateBody: data,
          templateName: this.props.templateProperties.templateName,
          templateDescription: null,
          metadata: this.props.templateProperties.metadata
        },
        this.props.templateProperties.isPublic        
        )
          .then(() => {
            this.props.templateContentUpdated(data);
            this.props.setIsTemplateModified(false);

            // eslint-disable-next-line no-undef
            hideOverlay();

            this.setState({
              saving: false,
              completed: true,
              errorSaving: undefined
            });
          })
          .catch(e => {

            // eslint-disable-next-line no-undef
            hideOverlay();

            this.setState({
              saving: false,
              completed: false,
              errorSaving: e
            });
          });
      })
      .catch(e => {
        // eslint-disable-next-line no-undef
        hideOverlay();
        throw e;
      });
  }

  onClose () {
    this.setState({
      modalOpen: false,
      saving: false,
      completed: false,
      errorSaving: undefined
    });
  }

  onConfirm () {

    this.setState({
      saving: true,
      completed: false,
      errorSaving: null
    },
    () => this.saveTemplate());
  }

  renderErrors () {
    if (this.state.errorSaving) {
      return <ErrorDetails title={this.props.t('failed_to_save')} error={this.state.errorSaving} />;
    }
    return null;
  }

  renderModal () {
    const { t } = this.props;
    let errors = this.renderErrors();

    return <EscapableModal
      status={!errors ? (this.state.saving ? 'info' : 'success') : 'danger'}
      show={this.state.modalOpen && errors}
      title={<span><Trans>save_template</Trans></span>}
      onRequestHide={() => this.onClose()}
      footer={this.state.completed
        ? <DefaultButton autoFocus onClick={() => this.onClose()} title={t('got_it')} faIcon={'fa-check-circle'} />
        : <div>
          <CancelButton disabled={this.state.saving} onClick={(e) => this.onClose()} />
          <ConfirmButton
            autoFocus
            disabled={this.state.saving}
            onClick={this.state.saving ? null : (e) => this.onConfirm()} />
        </div>}>

      {this.state.completed
        ? <div type={'info'}>
          <Trans i18nKey={'template_x_saved'}><strong>{this.props.templateProperties.templateId}</strong></Trans>
        </div>
        : null}

      {this.state.saving
        ? <Loading message={t('saving_ellip')} raw />
        : null}

      {errors}

    </EscapableModal>;
  }

  render () {
    const { t } = this.props;
    const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';
    const sampleTemplates = isProduction ? config.production : config.integration;
    return <div>
      {this.renderModal()}
      <CoamEnabledActionsBarButton
        t={t}
        operation={'update'}
        onClick={() => {
          this.setState({ modalOpen: true }, () => {
            // eslint-disable-next-line no-undef
            this.onConfirm();
          });
        }}
        disabled={sampleTemplates.map(a => a.templateId).includes(this.props.templateProperties.templateId)}
        disabledTooltip={t('action-bar.button_save_tooltip_use_save_as')}
        title={t('action-bar.button_save')}
        faIcon={'fa-floppy-o'} />
    </div>;
  }
}

SaveTemplateButton.propTypes = {
  accessToken: PropTypes.string,
  boardExporterPromise: PropTypes.any,
  templateProperties: PropTypes.any,
  templateObject: PropTypes.any,
  edie: PropTypes.any
};

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    templateProperties: state.appReducer.templateProperties,
    templateObject: state.appReducer.templateObject,
    edie: state.edie
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    templateContentUpdated: (newContent) => {
      dispatch(appActions.templateObjectChanged(newContent));
    },
    setIsTemplateModified: (name, value) => {
      dispatch(appActions.setIsTemplateModified(name, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveTemplateButton);
