import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as payloadActions from '../../../../store/payload/actions';
import Loading from '../../../generic/Loading';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import JSONTree from './JSONTree';
import { expandPayload } from '../../../../api/stereotype.api';
import { Checkbox } from '@cimpress/react-components';

class PlaceholdersEditor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      autoFilterLinks: true,
      autoFilterCoam: true,
      error: null
    };

    this.mounted = false;
  }

  _safeSetState(state, onAfterStateUpdate) {
    if (this.mounted) {
      this.setState(state, onAfterStateUpdate);
    }
  }

  componentDidMount () {
    this.mounted = true;
    this._triggerPayloadExpansion(this.props.providedOriginalPayload, this.props.providedHeaders);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((this.props.providedOriginalPayload !== prevProps.providedOriginalPayload) || (this.props.providedHeaders !== prevProps.providedHeaders)) {
      this._triggerPayloadExpansion(this.props.providedOriginalPayload, this.props.providedHeaders);
    }
  }

  _triggerPayloadExpansion (payload, headers) {
    if (!payload) {
      return;
    }

    this._safeSetState({ loading: true }, () => {
      expandPayload(this.props.accessToken, payload, headers)
        .then(res => {
          this.props.setProvidedExpandedPayload(JSON.parse(res));
          this._safeSetState({ loading: false, error: null });
        })
        .catch((err) => {
          this._safeSetState({ loading: false, error: true });
        });
    });
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  _prefilterKeys(tree) {
    if (!tree) {
      return tree;
    }

    if (Array.isArray(tree)) {
      return tree.map(x => this._prefilterKeys(x));
    }

    if ({}.constructor === tree.constructor) {
      let f = {};
      Object.keys(tree).forEach(k => {
        if (this.state.autoFilterLinks && k === 'links') {
          // ignore
        } else if (this.state.autoFilterCoam && ['fulfillerCoamAdminGroup'].includes(k)) {
          // ignore
        } else {
          f[k] = this._prefilterKeys(tree[k]);
        }
      });
      return f;
    }

    return tree;
  }

  render () {
    const { t } = this.props;

    let content;

    if (this.state.loading && (!this.props.providedExpandedPayload || Object.keys(this.props.providedExpandedPayload).length === 0)) {
      content = <Loading message={t('loading_dynamic_content')} raw />;
    } else {

      let baseJson = this.props.providedExpandedPayload;
      if (this.props.basePath && baseJson) {
        let parts = this.props.basePath.split('.');
        for (let i = 0; i < parts.length && baseJson; i++) {
          baseJson = baseJson[parts[i]];
        }
        if (!baseJson) {
        // Go back to 'all'
          baseJson = this.props.providedExpandedPayload;
        }
      }

      if (this.state.autoFilterLinks || this.state.autoFilterCoam) {
        baseJson = this._prefilterKeys(baseJson);
      }

      if (this.state.error) {
        content = <div style={{ paddingBottom: '10px' }}>
          <div style={{ padding: '10px 0px' }}>
            <i className={'fa fa-exclamation-circle text-danger'} />&nbsp;
            {this.state.errorDescription || t('err_retrieving_placeholders')}
          </div>
          <div style={{ display: 'flex', paddingBottom: '10px' }}>
            <DefaultButton
              title={t('retry')}
              size={'sm'}
              faIcon={'fa-repeat'}
              onClick={() => {this._triggerPayloadExpansion(this.props.providedOriginalPayload, this.props.providedHeaders);}}/>
          </div>
        </div>;
      } else if (this.props.providedOriginalPayload && Object.keys(this.props.providedExpandedPayload).length > 0) {
        content = <div>
          <JSONTree
            t={this.props.t}
            onClick={this.props.onClick}
            providedExpandedPayload={baseJson} />
          <Checkbox
            label={t('sample_data_hide_links')}
            inline
            checked={this.state.autoFilterLinks}
            onChange={()=>this.setState({ autoFilterLinks:!this.state.autoFilterLinks })} />
          <Checkbox
            label={t('sample_data_hide_fulfiller_coam_group')}
            inline
            checked={this.state.autoFilterCoam}
            onChange={()=>this.setState({ autoFilterCoam:!this.state.autoFilterCoam })} />
        </div>;
      } else {
        content = <div style={{ paddingBottom: '10px' }}>
          <div style={{ padding: '10px 0px' }}>
            <i className={'fa fa-info-circle text-info'} style={{ paddingRight: '5px' }} />
          &nbsp;{t('no_placeholders_yet')}
          </div>
        </div>;
      }
    }

    return (<div>{content}</div>);
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    providedExpandedPayload: state.payload.providedExpandedPayload,
    providedOriginalPayload: state.payload.providedOriginalPayload,
    providedHeaders: state.payload.headers,
    templateType: state.appReducer.templateType,
    templateProperties: state.appReducer.templateProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProvidedExpandedPayload: providedExpandedPayload => dispatch(payloadActions.setExpandedPayload(providedExpandedPayload)),
    setProvidedOriginalPayload: providedOriginalPayload => dispatch(payloadActions.setOriginalPayload(providedOriginalPayload)),
    setCustomStereotypeHeaders: headers => dispatch(payloadActions.setCustomStereotypeHeaders(headers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceholdersEditor);
