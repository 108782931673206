import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ConfirmButton from '../../../generic/buttons/ConfirmButton';
import CancelButton from '../../../generic/buttons/CancelButton';
import Loading from '../../../generic/Loading';
import ErrorInfo from '../../../generic/ErrorInfo';
import LeftRightBlock from '../../../generic/LeftRightBlock';

import { sendEmail } from '../../../../api/puremail.api';
import EscapableModal from '../EscapableModal';
import Mustache from 'mustache';
import { Trans } from 'react-i18next';

import './Emailing.scss';

class SendEmailModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount () {
    this.mount = true;
  }

  componentWillUnmount () {
    this.mount = false;
  }

  sendEmail () {
    this.setState({
      isSending: true,
      sentSuccessful: undefined,
      error: undefined
    });

    sendEmail(
      this.props.accessToken,
      this.props.templateId,
      this.props.providedOriginalPayload)
      .then(ok => {

        if (this.props.open) {
          this.props.onSent({
            templateId: this.props.templateId,
            requestId: ok.requestId,
            status: 'RUNNING',
            statusDate: moment().format(),
            addressingHeaders: this.getAddressingHeaders(),
            autoClearOnSuccess: true
          });

          this.setState({
            isSending: false,
            sentSuccessful: true,
          });

          this.onClose();
        }
      })
      .catch(err => {
        this.setState({
          isSending: false,
          sentSuccessful: false,
          error: err
        });
      });
  }

  onClose () {
    this.setState({
      sentSuccessful: undefined,
      isSending: false,
      error: undefined
    }, () => this.props.onClose());
  }

  renderModalFooter () {
    const { t } = this.props;

    let addressingHeaders = this.getAddressingHeaders();
    let _hasRecipients = this.hasRecipients(addressingHeaders);
    let _hasSender = this.hasSender(addressingHeaders);
    let _disabledTooltip = !_hasRecipients
      ? t('dialogs.please_specify_recipients')
      : t('dialogs.please_specify_sender');

    let right = <div>
      <CancelButton
        faIcon={'fa-window-minimize'}
        disabled={this.state.isSending}
        title={this.state.sentSuccessful !== true && this.props.pendingEmails && this.props.pendingEmails.length > 0
          ? t('minimize_ellip')
          : t('close')}
        onClick={() => this.onClose()} />
      &nbsp;
      <ConfirmButton
        disabled={this.state.isSending || !_hasRecipients || !_hasSender}
        disabledTooltip={_disabledTooltip}
        title={t('send')}
        faIcon={'fa-envelope-o'}
        onClick={() => this.sendEmail()} />
    </div>;

    return <LeftRightBlock left={null} right={right} />;
  }

  getAddressingHeaders () {
    try {
      let payload = this.props.providedExpandedPayload;
      if (JSON.stringify(payload) === '{}') {
        payload = this.props.providedOriginalPayload;
      }
      return {
        to: Mustache.render(this.props.mimeHeaders.to || '', payload).trim(),
        cc: Mustache.render(this.props.mimeHeaders.cc || '', payload).trim(),
        bcc: Mustache.render(this.props.mimeHeaders.bcc || '', payload).trim(),
        from: (({ address, name }) => ({
          address: Mustache.render(address || '', payload).trim(),
          name: Mustache.render(name || '', payload).trim(),
        }))(this.props.mimeHeaders.from || {}),
      };
    } catch (e) {
      return {
        to: (this.props.mimeHeaders.to || '').trim(),
        cc: (this.props.mimeHeaders.cc || '').trim(),
        bcc: (this.props.mimeHeaders.bcc || '').trim(),
        from: (({ address, name }) => ({
          address: (address || '').trim(),
          name: (name || '').trim(),
        }))(this.props.mimeHeaders.from || {}),
      };
    }
  }

  hasRecipients (addressingHeaders) {
    return addressingHeaders.to !== '' || addressingHeaders.cc !== '' || addressingHeaders.bcc !== '';
  }

  hasSender (addressingHeaders) {
    return addressingHeaders.from && addressingHeaders.from.address && addressingHeaders.from.address[0] !== '@';
  }

  renderAddressingHeaders () {
    let addressingHeaders = this.getAddressingHeaders();
    let to = addressingHeaders['to'];
    let cc = addressingHeaders['cc'];
    let bcc = addressingHeaders['bcc'];
    let from = addressingHeaders['from'];

    return <p>
      <ul>
        {to !== '' ? <li>({this.props.t('to').toLowerCase()}) <strong>{to}</strong></li> : null}
        {cc !== '' ? <li>({this.props.t('cc').toLowerCase()}) <strong>{cc}</strong></li> : null}
        {bcc !== '' ? <li>({this.props.t('bcc').toLowerCase()}) <strong>{bcc}</strong></li> : null}
      </ul>
      {from && from.address
        ? (<span>{this.props.t('from_header').toLowerCase()}: <strong>{from.name} &lt;{from.address}&gt;</strong></span>)
        : null}
    </p>;
  }

  render () {
    const { t } = this.props;

    let addressingHeaders = this.getAddressingHeaders();
    let renderedAddressingHeaders = this.renderAddressingHeaders();
    let _hasRecipients = this.hasRecipients(addressingHeaders);
    let _hasSender = this.hasSender(addressingHeaders);

    return <EscapableModal
      status={'success'}
      show={this.props.open}
      title={t('dialogs.send_email_title')}
      onRequestHide={() => this.onClose()}
      footer={this.renderModalFooter()}>

      {this.state.isSending
        ? <Loading message={t('sending_ellip')} raw />
        : this.state.sentSuccessful
          ? <div><i className={'fa fa-check-circle text-success'} />&nbsp;<Trans>email_sent</Trans><br />
            <Trans>addressingHeaders</Trans>{renderedAddressingHeaders}
          </div>
          : _hasSender && _hasRecipients
            ? <div><Trans>would_you_like_to_send_to_these</Trans><br />
              {renderedAddressingHeaders}
            </div>
            : _hasRecipients
              ? <div className={'text-danger'}><Trans i18nKey={'dialogs.please_specify_sender_detailed'} /></div>
              : <div className={'text-danger'}><Trans i18nKey={'dialogs.please_specify_recipients_detailed'} /></div>}
      <br />

      {this.state.error
        ? <ErrorInfo message={t('oops')} details={this.state.error} minimal />
        : null}

    </EscapableModal>;
  }
}

SendEmailModal.propTypes = {
  accessToken: PropTypes.string,
  templateId: PropTypes.string,
  providedOriginalPayload: PropTypes.object,
  providedExpandedPayload: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSent: PropTypes.func.isRequired,
  pendingEmails: PropTypes.array,
  mimeHeaders: PropTypes.object
};

export default SendEmailModal;
