import XEmailBoard from './XEmailBoard';
import XEmailResourcesGroup from './XEmailResourcesGroup';
import XEmailTemplateRenderer from './XEmailTemplateRenderer';
import { convertFlatToEdieStructure } from '../../../../store/helpers/edieInternalStateHelpers';
import cloneDeep from 'lodash/cloneDeep';

// TODO: Add help

// TODO: Add 'default' option in case something is not provided

const config = {
  board: XEmailBoard,
  resources: XEmailResourcesGroup,

  exportTemplate: (props) => {
    let edieJson = cloneDeep(props.edie);

    edieJson.structure = convertFlatToEdieStructure(
      props.edie,
      edieJson.flatStructure
    );

    delete edieJson.flatStructure;
    delete edieJson.currentlySelectedElementId;

    return Promise.resolve(JSON.stringify(edieJson));
  },

  templateObjectAdaptor: (templateObject) => {
    return JSON.parse(templateObject);
  },

  createTemplateRendererFunction: (templateContentType, materializationContentType) => {
    return new XEmailTemplateRenderer(templateContentType, materializationContentType);
  }
};

export default config;
