import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const Loading = (props) => {

  let loading = <div className='clearfix'>
    <div className='pull-left'><Spinner size={'medium'} /></div>
    <div className='pull-left'>&nbsp;&nbsp;</div>
    <div className='pull-left' style={{ lineHeight: '30px' }}>{props.message}</div>
  </div>;

  return props.raw
    ? loading
    : <div className='card'>
      <div className='card-block'>
        {loading}
      </div>
    </div>;
};

Loading.propTypes = {
  message: PropTypes.string.isRequired,
  raw: PropTypes.bool
};

Loading.defaultProps = {
  raw: false
};

export default Loading;
