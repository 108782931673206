import { createEmptyBlock, createEmptyFormat, EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import cloneDeep from 'lodash/cloneDeep';
import { EdieActions } from '../storeActions';

import { inplaceItemUpdate, inplaceItemMove, inplaceItemRemove, inplaceItemAdd, inplaceItemClone, flattenStructure, addNItems } from '../helpers/edieInternalStateHelpers';
import { EdieActionTypes, EdieState } from './types';

const defaultState: EdieState = createEmptyFormat('v1.1');

const edie = (state = defaultState, action: EdieActionTypes): EdieState => {
  switch (action.type) {
    case EdieActions.INIT_STRUCTURE: {
      if (action.data && action.data.structure) {
        let newStructure = cloneDeep(action.data.structure);
        return Object.assign({}, state, {
          formatVersion: action.data.formatVersion,
          structure: newStructure,
          meta: action.data.meta,
          mimeHeaders: action.data.mimeHeaders,
          plainText: action.data.plainText,
          flatStructure: flattenStructure(newStructure)
        });
      }
      return state;
    }
    case EdieActions.ADD_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      let newItem = createEmptyBlock(action.item.type);
      newItem.properties.metadata = action.item.metadata;
      inplaceItemAdd(newFlatStructure, action.parent, newItem, action.position);
      if (action.item.type === EDIE_BLOCK_TYPE.CONDITION){
        let ifItem = createEmptyBlock(EDIE_BLOCK_TYPE.IF_CONDITION);
        let elseItem = createEmptyBlock(EDIE_BLOCK_TYPE.ELSE_CONDITION);
        inplaceItemAdd(newFlatStructure, newItem, ifItem, 0);
        inplaceItemAdd(newFlatStructure, newItem, elseItem, 1);
      }  
      return Object.assign({}, state, {
        flatStructure: newFlatStructure,
        currentlySelectedElementId: newItem.id
      });
    }

    case EdieActions.ELSE_CONDITION_ADD_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      let newItem = createEmptyBlock(action.item.type);
      newItem.properties.metadata = action.item.metadata;
      inplaceItemAdd(newFlatStructure, action.parent, newItem, action.position);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure,
        currentlySelectedElementId: newItem.id
      });
    }
    case EdieActions.SELECT_ITEM: {
      if (action.itemId !== state.currentlySelectedElementId) {
        return Object.assign({}, state, {
          currentlySelectedElementId: action.itemId
        });
      }
      return state;
    }
    case EdieActions.CLONE_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      let clonedItemId = inplaceItemClone(newFlatStructure, action.parent, action.item);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure,
        currentlySelectedItemId: clonedItemId
      });
    }
    case EdieActions.REMOVE_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      inplaceItemRemove(newFlatStructure, action.item);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure
      }, state.currentlySelectedElementId === action.item.id ? { currentlySelectedItemId: undefined } : null);
    }
    case EdieActions.MOVE_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      inplaceItemMove(newFlatStructure, action.newParent, action.existingItem, action.position);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure
      });
    }
    case EdieActions.UPDATE_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      inplaceItemUpdate(newFlatStructure, action.item, action.data);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure
      });
    }
    case EdieActions.ADD_N_ITEMS:{
      let newFlatStructure = cloneDeep(state.flatStructure);
      addNItems(newFlatStructure,action.parent,action.item);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure
      });
    }
    case EdieActions.SET_MIME_HEADER: {
      let newMimeHeaders = cloneDeep(state.mimeHeaders || {});
      newMimeHeaders[action.header] = action.headerValue;
      return Object.assign({}, state, {
        mimeHeaders: newMimeHeaders
      });
    }
    case EdieActions.SET_PLAIN_TEXT_VERSION: {
      return Object.assign({}, state, {
        plainText: action.content
      });
    }
    case EdieActions.SET_PLAIN_TEXT_AUTO_GENERATION_FLAG: {
      return Object.assign({}, state, {
        meta: Object.assign({},
          state.meta, {
            plainTextAutoGeneration: action.flag
          })
      });
    }
    case EdieActions.XCSV_UPDATE_METADATA: {
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.xCsvMetaData
        }
      };
    }
    case EdieActions.XCSV_UPDATE_BLOCK_METADATA: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      let blockProperties = newFlatStructure && newFlatStructure[action.item.id].properties;
      blockProperties.metadata = {
        ...blockProperties.metadata,
        ...action.data
      };
      inplaceItemUpdate(newFlatStructure, action.item, blockProperties);
      return Object.assign({}, state, {
        flatStructure: newFlatStructure
      });
    }
    case EdieActions.XCSV_ADD_ITEM: {
      let newFlatStructure = cloneDeep(state.flatStructure);
      let newItem = createEmptyBlock(action.item.type);
      if (action.item.type === EDIE_BLOCK_TYPE.COLUMN) {
        newItem.properties.metadata = { text: '', header: '', contentType: 'text' };
      }
      inplaceItemAdd(newFlatStructure, action.parent, newItem, action.position);

      if (action.item.type === EDIE_BLOCK_TYPE.LOOP) {
        let newRow = createEmptyBlock(EDIE_BLOCK_TYPE.ROW);
        inplaceItemAdd(newFlatStructure, newItem, newRow, 0);
      }

      return Object.assign({}, state, {
        flatStructure: newFlatStructure,
        currentlySelectedItemId: newItem.id
      });
    }
    case EdieActions.RESET_EDIE_REDUCER:
      return defaultState;
    default:
      return state;
  }
};

export default edie;
