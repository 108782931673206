import React from 'react';
import ErrorInfo from './ErrorInfo';
import { withTranslation, Trans } from 'react-i18next';
import { shapes } from '@cimpress/react-components';
import { withRouter } from 'react-router-dom';

const { Robot } = shapes;

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render () {
    const { t } = this.props;
    if (this.state.error) {

      return <div className='container margin-top'>

        <div className={'card'}>
          <div className={'card-block'}>

            <div className={'row'}>
              <div className={'col-md-9'}>
                <ErrorInfo title={t('oops')} minimal details={this.state.error} />
                <br />
                <button className={'btn btn-outline-secondary'} onClick={() => {
                  this.setState({ error: undefined }, () => {
                    this.props.history.push('/');
                  });
                }}>
                  <Trans i18nKey={'errors.error_boundary_clear_button'} />
                </button>
              </div>

              <div className={'col-md-3'}>
                <Robot status='danger' />
              </div>
            </div>

          </div>
        </div>

      </div>;
    }

    return this.props.children;
  }
}

export default withTranslation()(withRouter(ErrorBoundary));
