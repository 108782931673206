import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CancelButton from '../../../generic/buttons/CancelButton';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import LeftRightBlock from '../../../generic/LeftRightBlock';
import EscapableModal from '../EscapableModal';
import { Trans } from 'react-i18next';

import './Emailing.scss';
import { formatDate, statusLabel } from './Emailing';

class EmailStatusDetailsModal extends Component {

  renderNoEmails () {
    return <tr>
      <td colSpan={4}>
        <em><Trans>no_pending_emails</Trans></em>
      </td>
    </tr>;
  }

  renderStatusHeader () {
    return <tr>
      <th><Trans>status</Trans></th>
      <th><Trans>status_date</Trans></th>
      <th><Trans>template</Trans></th>
      <th />
      <th className='email-context-button-icon'>&nbsp;</th>
    </tr>;
  }

  renderStatusRow (data, i) {
    return <tr key={i}>
      <td className={'email-status-cell'}>{statusLabel(data.status)}</td>
      <td className={'email-status-cell-text'}>{formatDate(data.statusDate)}</td>
      <td className={'email-status-cell-text'}>{data.templateId}</td>
      <td className={'email-status-cell-text'} width='100%'>
        <div>{data.status === 'FAILURE'
          ? <div className='email-error-details'>{data.details}</div>
          : null}</div>
      </td>
      <td className={'email-status-cell'}>
        <div className='email-context-button-icon' onClick={() => this.props.onClear(data.requestId)}>
          <i className={'fa fa-trash'} />
        </div>
      </td>
    </tr>;
  }

  render () {
    const { t } = this.props;

    return <EscapableModal
      status={'info'}
      show={!!this.props.open}
      title={t('email_status')}
      onRequestHide={() => this.props.onClose()}
      footer={
        <LeftRightBlock
          left={<DefaultButton
            disabled={!this.props.pendingEmails || this.props.pendingEmails.length === 0}
            title={t('clear_all')}
            onClick={() => this.props.onClear()} />}
          right={<CancelButton
            faIcon={'fa-window-minimize'}
            title={this.props.pendingEmails && this.props.pendingEmails.length > 0
              ? t('minimize_ellip')
              : t('close')}
            onClick={() => this.props.onClose()} />
          } />
      }>
      <div>
        <table className='table table-hover'>
          <thead>{this.renderStatusHeader()}</thead>
          <tbody>{
            this.props.pendingEmails.length > 0
              ? this.props.pendingEmails.map((n, i) => this.renderStatusRow(n, i))
              : this.renderNoEmails()}</tbody>
        </table>
      </div>
    </EscapableModal>;
  }
}

EmailStatusDetailsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClear: PropTypes.func
};

export default EmailStatusDetailsModal;
