import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import classNames from 'classnames';
import { DropTargetProps } from '../../../models/XBlockProps';

class XRowDropTarget extends Component<DropTargetProps> {
  render () {
    // Hack: https://stackoverflow.com/questions/36575846/how-to-make-div-fill-td-height-in-firefox/36576083#36576083
    let target = <td style={{ height: '1px' , width: '1%' }}>
      <div className={classNames([
        'xemail-vertical-drop-target',
        { 'xemail-dragging-can-drop-and-over': this.props.isDragging && this.props.canDrop && this.props.isOver },
        { 'xemail-dragging-can-drop': this.props.isDragging && this.props.canDrop && !this.props.isOver && !this.props.disabled }
      ])}>
        &nbsp;&nbsp;
      </div>
    </td>;

    return this.props.disabled ? target : this.props.connectDropTarget(target);
  }
}

const columnTarget = {
  drop (props, monitor, _component) {
    props.onDrop({ id: props.containerId }, { type: monitor.getItemType(), metadata: monitor.getItem().resourceTypeMetadata, id: monitor.getItem().itemId }, props.position);
  }
};

function collect (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    isDragging: [EDIE_BLOCK_TYPE.COLUMN].indexOf(monitor.getItemType()) !== -1
  };
}

export default DropTarget([EDIE_BLOCK_TYPE.COLUMN], columnTarget, collect)(XRowDropTarget);
