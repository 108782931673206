import React, { Component, Fragment } from 'react';

import XRowDropTarget from './XRowDropTarget';
import XRowHeader from './XRowHeader';
import { XRowProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';
import { InlineEdit } from '@cimpress/react-components';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DefaultColumnWidth } from '../../../../../../utils/options';
import { parseHandlebarsExpression, PropertyType } from '../../../../../../utils/helpers';
import { renderBlock } from '../../blockRenderer';
class XRow extends Component<XRowProps, XBlockState> {

  renderDropTarget(disabled, position) {
    return <XRowDropTarget
      disabled={disabled}
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  validateNumber(noOfColumns) {
    let re = new RegExp(/^[0-7]$/);
    const t = this.props.t;
    if (!re.test(noOfColumns)) {
      return t('validNumberOfColumns');
    }
    return null;
  }

  addNColumns(noOfColumns) {
    if (noOfColumns > 0) {
      let child = { type: EDIE_BLOCK_TYPE.TEXT, noOfItems: 1, properties: null, child: null };
      let properties = DefaultColumnWidth[noOfColumns].map(item => { return { width: item }; });
      this.props.renderProps.onAddingNItems({ id: this.props.id }, { type: EDIE_BLOCK_TYPE.COLUMN, noOfItems: noOfColumns, properties: properties, child: child });
    }
  }

  renderBody(totalWidth) {
    const disableDropWithin = this.props.draggingItemId === this.props.id || this.props.renderProps.disableDrop;
    const t = this.props.t;
    let columnConfig = <td style={{ padding: '2%' }}><InlineEdit
      name='numberOfColumns'
      placeholder={'0'}
      label={t('input_no_of_columns')}
      value={0}
      style={{ width: '100%', fontStyle: 'italic' }}
      onSave={(e) => this.addNColumns(e.value)}
      validateInput={(noOfColumns) => this.validateNumber(noOfColumns)}
    />
    </td>;

    // TODO: Figure out how to use Flexbox instead table

    return <table width='100%' className='xrow-table'>
      <tbody>
        <tr>
          {this.renderDropTarget(disableDropWithin, 0)}
          {this.props.elements.length === 0 ? columnConfig
            : <Fragment>
              {(this.props.elements || []).map((element, index) => <React.Fragment key={index}>
                {renderBlock(element,
                  Object.assign({},
                    this.props.renderProps,
                    { rowWidth: totalWidth, disableDrop: disableDropWithin }),
                  // Children of a row are not top-level nodes
                  false)}
                {this.renderDropTarget(disableDropWithin, index + 1)}
              </React.Fragment>
              )}
            </Fragment>
          }
        </tr>
      </tbody>
    </table>;
  }

  render() {
    let itemProps = this.props.renderProps.flatStructure[this.props.id].properties;
    let totalWidth = 0;

    const  defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';

    (this.props.elements || []).forEach(columnId => {
      let columnProperties = this.props.renderProps.flatStructure[columnId].properties;
      if (columnProperties.width) {
        totalWidth += parseInt(columnProperties.width.replace('%', ''));
      }
    });

    return <div
      className={`${defaultClass} xemail-row-container`}
      {...this.props.defaultHandlers}>
      <div style={{
        display: 'flex',
        width: '100%',
        backgroundColor: parseHandlebarsExpression(PropertyType.COLOR, itemProps.backgroundColor, this.props.renderProps.providedOriginalPayload),
        border: (itemProps.borderSize || '0px') + ' solid ' + parseHandlebarsExpression(PropertyType.COLOR, itemProps.borderColor, this.props.renderProps.providedOriginalPayload, 'transparent')
      }}>
        <XRowHeader {...this.props} />
        <div className='xemail-row-body'>
          {this.renderBody(totalWidth)}
        </div>
      </div>
    </div>;
  }
}

export default XRow;
