import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TextField, Select } from '@cimpress/react-components';
import ActionsBarButton from './base/ActionsBarButton';
import CancelButton from '../../../generic/buttons/CancelButton';
import ConfirmButton from '../../../generic/buttons/ConfirmButton';
import { StereotypeClient } from '@cimpress-technology/stereotype-client';
import { CustomizrClient } from 'cimpress-customizr';
import { connect } from 'react-redux';
import * as appActions from '../../../../store/app/actions';
import Loading from '../../../generic/Loading';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import EscapableModal from '../EscapableModal';
import { Trans } from 'react-i18next';
import { convertToOptions } from '../../../../utils/options';
import { getDesignerProfile } from '../../../../profiles/profiles';

const BASE_URL =  process.env.REACT_APP_TEMPLATE_DESIGNER_API_URL;
class SaveAsTemplateButton extends Component {

  constructor (props) {
    super(props);
    this.state = {
      modalOpen: false,
      saving: false,
      templateName: '',
      newTemplateId: '',
      completed: false,
      errorSaving: undefined,
      isPublic: false
    };
  }

  forward () {
    this.props.renameLoadedTemplate(this.state.newTemplateId, this.state.templateName, this.state.isPublic);

    let search = new URLSearchParams(this.props.history.location.search);
    search.delete('creating');
    search.delete('copy');
    this.props.history.push({
      pathname: '/designer/' + this.state.newTemplateId,
      search: '?' + search.toString()
    });

    this.onClose();
  }

  saveTemplate () {
    // Get the action "thing" to save
    this.props.boardExporterPromise({
      templateObject: this.props.templateObject,
      edie: this.props.edie
    })
      .then(templateData => {
        const accessToken = this.props.accessToken;
        const stereotype = new StereotypeClient(`Bearer ${accessToken}`,{ baseUrl: BASE_URL });
        const customizr = new CustomizrClient({
          timeout: 5000
        });
        const data = templateData;
        let newTemplateId;

        stereotype
          .createTemplate({
            templateType: this.props.templateProperties.templateType,
            contentType: this.props.templateProperties.contentType,
            templateBody: data,
            templateName: this.state.templateName,
            metadata: this.props.templateProperties.metadata,
          },
          this.state.isPublic
          )
          .then(templateInfo => {
            this.props.templateContentUpdated(data);
            newTemplateId = templateInfo.templateId;
            const coamLink = templateInfo.links.coamAdminGroup.href;
            const oldTemplateLinks = this.props.templateProperties.links || {};
            

            this.props.templatePropertyChanged('links',
              Object.assign({}, oldTemplateLinks, { coamAdminGroup: { href: coamLink } }));

            if (this.props.providedOriginalPayload) {
              const resource = `${window.location.origin}/designer/${encodeURI(newTemplateId)}`;
              const payloads = [{
                payload: JSON.stringify(this.props.providedOriginalPayload),
                updatedAt: Date.now()
              }];
              return customizr
                .putSettings(accessToken, { payloads }, resource)
                .catch((err) => { /* ignore failure */ });
            }
          })
          .then(() => {
            this.props.setIsTemplateModified(false);
            this.setState({ newTemplateId, completed: true, saving: false });
          })
          .catch(e => {
            this.setState({
              saving: false,
              errorSaving: e.response?.data || e.message
            });
          });
      });
  }

  onClose () {
    if (this.canClose()) {
      this.setState({
        modalOpen: false,
        templateName: '',
        saving: false,
        newTemplateId: '',
        completed: false,
        errorSaving: undefined,
        isPublic: false
      });
    }
  }

  onConfirm () {
    this.setState({ saving: true }, () => this.saveTemplate());
  }

  renderErrors () {
    const { t } = this.props;
    let errors = [];
    if (!this.state.templateName) {
      errors.push(t('non_empty_template_name'));
    }

    if (this.state.errorSaving) {
      errors.push(this.state.errorSaving ? this.state.errorSaving : t('unknown_save_err'));
    }

    if (errors.length === 0) {
      return null;
    }

    return errors.map((e, i) => {
      return <div key={i} className={'text-danger'}>&bull;&nbsp;{e}</div>;
    });
  }

  renderModalBodyDefault (errors) {
    const { t } = this.props;
    const visibilityOptions = [{
      value: 'true', label: t('public')
    }, {
      value: 'false', label: t('private')
    }];
    const selectedVisibility = convertToOptions(this.state.isPublic.toString(), visibilityOptions);

    return <div>
      <TextField
        autoFocus
        name='templateName'
        readOnly={this.state.saving}
        label={t('enter_new_name')}
        onKeyDown={(e) => e.key === 'Enter'
          ? this.onConfirm()
          : null}
        value={this.state.templateName}
        onChange={(e) => this.setState({
          templateName: e.target.value
        })}
        required />
      <Select label={t('template_visibility')} value={selectedVisibility}
        options={visibilityOptions}
        onChange={(v) => this.setState({ isPublic: v.value === 'true' })}
        isClearable={false} />
      {errors}

      {this.state.saving ? <Loading message={t('saving_ellip')} raw /> : null}
    </div>;
  }

  renderModalBodyCompleted () {
    return <Trans i18nKey={'templ_saved_as'}><strong>{this.state.templateName}</strong></Trans>;
  }

  canClose () {
    return !this.state.saving;
  }

  renderModal () {
    const { t } = this.props;
    let errors = this.renderErrors();
    let confirmDisabled = (!!errors || this.state.saving) && (!this.state.errorSaving);

    return <EscapableModal
      status={'success'}
      show={this.state.modalOpen}
      title={<span><Trans>save_as</Trans></span>}
      onRequestHide={() => this.onClose()}
      footer={this.state.completed
        ? <DefaultButton onClick={(e) => this.forward()} title={t('got_it')} faIcon={'fa-check-circle'} />
        : <div>
          <CancelButton
            disabled={!this.canClose()}
            onClick={(e) => this.onClose()} />
          <ConfirmButton
            disabled={confirmDisabled}
            onClick={confirmDisabled ? null : (e) => this.onConfirm()} />
        </div>}>

      {this.state.completed
        ? this.renderModalBodyCompleted()
        : this.renderModalBodyDefault(errors)}

    </EscapableModal>;
  }

  render () {
    const { t } = this.props;

    const shouldRender = getDesignerProfile(this.props.designerProfile).features.saveAs.enabled;
    if (!shouldRender) {
      return null;
    }

    return <div>
      {this.renderModal()}
      <ActionsBarButton
        onClick={() => {
          this.setState({ modalOpen: true });
        }}
        disabledTooltip={this.props.disableMessage || t('action-bar.button_save_as_tooltip_when_insufficient_permissions')}
        title={t('action-bar.button_save_as')}
        faIcon={'fa-floppy-o'} />
    </div>;
  }
}

SaveAsTemplateButton.propTypes = {
  accessToken: PropTypes.string,
  boardExporterPromise: PropTypes.any,
  templateObject: PropTypes.any,
  edie: PropTypes.any
};

const mapStateToProps = state => {
  return {
    providedOriginalPayload: state.payload.providedOriginalPayload,
    accessToken: state.authReducer.accessToken,
    templateProperties: state.appReducer.templateProperties,
    templateObject: state.appReducer.templateObject,
    designerProfile: state.designerProfiles.active,
    edie: state.edie
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    templatePropertyChanged: (name, value) => {
      dispatch(appActions.templatePropertyChanged(name, value));
    },
    setIsTemplateModified: (name, value) => {
      dispatch(appActions.setIsTemplateModified(name, value));
    },
    renameLoadedTemplate: (templateId, templateName, isPublic) => {
      dispatch(appActions.renameLoadedTemplate(templateId, templateName, isPublic));
    },
    templateContentUpdated: (newContent) => {
      dispatch(appActions.templateObjectChanged(newContent));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SaveAsTemplateButton));
