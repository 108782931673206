import contentTypeParser from 'content-type';
import Handlebars from 'handlebars';
import { TemplateType, TemplateTypeValues } from '../constants';
import { Tag } from '../scenes/Designer/designers/models/common';
import xemailDesignerConfig from '../scenes/Designer/designers/xemail/config';
import xdocDesignerConfig from '../scenes/Designer/designers/xdoc/config';
import xcsvDesignerConfig from '../scenes/Designer/designers/xcsv/config';
import rawDesignerConfig from '../scenes/Designer/designers/raw/config';

const eol = require('eol');
const detectNewline = require('detect-newline');

const ColorUtils = {
  rgb2hex: (rgb) => {
    if (rgb && rgb.includes('rgb')) {
      let match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      if (match) {
        return '#' + ColorUtils.hex(match[1]) + ColorUtils.hex(match[2]) + ColorUtils.hex(match[3]);
      }
      return rgb;
    } else {
      return rgb;
    }
  },

  hex: (x) => {
    let hexDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    return isNaN(x) ? '00' : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
  }
};

const LineEndings = {
  getLineEnding: (text) => {
    if (text) {
      return detectNewline(text);
    }
    return null;
  },

  supportedLineEndings: () => {
    return [
      { value: '\n', label: 'Unix (LF)' },
      { value: '\r\n', label: 'Windows (CRLF)' }
    ];
  },

  convertLineEndingTo: (text, lineEnding) => {
    if (text) {
      switch (lineEnding) {
        case '\n':
          return eol.lf(text);
        case '\r\n':
          return eol.crlf(text);
        default:
          return text;
      }
    }
    return text;
  }
};

const convertContentTypeToName = (contentType) => {
  const content = contentTypeParser.parse(contentType);

  switch (content.type) {
    case 'text/x-handlebars-template':
    case 'text/handlebars':
      return 'handlebars';
    case 'application/vnd.cimpress.edie+json':
      return 'EDIE';
    default:
      return 'unknown';
  }
};

const designersConfiguration = {
  [TemplateType.XEMAIL]: xemailDesignerConfig,
  [TemplateType.XDOC]: xdocDesignerConfig,
  [TemplateType.XCSV]: xcsvDesignerConfig,
  [TemplateType.RAW]: rawDesignerConfig
};

const CsvFieldContentType = {
  DATE: 'date',
  NUMBER: 'number',
  TEXT: 'text'
};

const getMaterializationContentType = (templateType: TemplateTypeValues, contentType: string) => {
  let content;

  switch (templateType) {
    /* @ts-ignore */
    case TemplateType.RAW:
      content = contentTypeParser.parse(contentType);
      if (((content.parameters || {}).postprocessors || '').indexOf('mjml') !== -1) {
        return 'text/mjml+html';
      }
    // falls through
    case TemplateType.XCSV:
      content = contentTypeParser.parse(contentType);
      if (((content.parameters || {}).processors || '').indexOf('xlsx') !== -1) {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      return 'text/plain';
    default:
      content = contentTypeParser.parse(contentType);
      if (((content.parameters || {}).processors || '').indexOf('htmlpdf') !== -1) {
        return 'application/pdf';
      }
      return 'text/html';
  }
};

const checkIsBinaryResponse = (materializationContentType: string) => {
  switch (materializationContentType){
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/pdf':
      return true;
    default:
      return false;  
  }
};

const getTemplateIdFromUrl = (templateUrl: string): string => {
  let templateRegex = /\/templates\/([\w:-]+)/;
  let hostRegex = /stereotype\..*\.cimpress.io/;
  let protocolRegex = /http[s]?:/;
  let parsedUrl = new URL(templateUrl);

  if (parsedUrl.protocol) {
    if (!parsedUrl.protocol.match(protocolRegex)) {
      throw new Error('Only http or https endpoints are supported');
    }
    if (!parsedUrl.host.match(hostRegex)) {
      throw new Error(`Unsupported service encountered in template url: ${parsedUrl.host}`);
    }
    let pathMatch = parsedUrl.pathname.match(templateRegex);
    if (!pathMatch) {
      throw new Error(`Unsupported resource encountered in template url for ${parsedUrl.host}: ${parsedUrl.pathname}`);
    } else {
      return pathMatch[1];
    }
  }
  throw new Error('Badly formatted template url');
};

const addOrRemoveTagKeys = (tags: Tag[], key: string, value: string) => {
  const index = tags.findIndex(tag => tag.key === key);
  return index === -1
    ? [...tags, { key, value }]
    : tags.filter(tag => !(tag.key === key && value ? tag.value === value : true));
};


enum PropertyType {
  COLOR = 'color'
}


// Function to parse handlebar expression.
const parseHandlebarsExpression = (propertyType: PropertyType, evalString = '', payload = {}, defaultValue = '') => {
  try {
    let expression =  Handlebars.compile(evalString)(payload);
    switch (propertyType) {
      case PropertyType.COLOR: 
        return isColor(expression) ? expression : defaultValue;  
      default:
        return defaultValue;
    }
  } catch (error) {
    return defaultValue;
  }
};

const isColor = (strColor) => {
  var s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

// valueof similar to keyof
export type ValueOf<T> = T[keyof T];

export {
  convertContentTypeToName,
  getMaterializationContentType,
  getTemplateIdFromUrl,
  addOrRemoveTagKeys,
  checkIsBinaryResponse,
  ColorUtils,
  LineEndings,
  designersConfiguration,
  CsvFieldContentType,
  parseHandlebarsExpression,
  PropertyType
};
