import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';

import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import classNames from 'classnames';
import { DropTargetProps } from '../../../models/XBlockProps';

const ACCEPTED_BLOCK_TYPES = [
  EDIE_BLOCK_TYPE.ROW,
  EDIE_BLOCK_TYPE.LOOP,
];

class XCsvLoopDropTarget extends Component<DropTargetProps> {

  render () {
    let target = <div className={classNames([
      'xcsv-horizontal-drop-target',
      { 'xcsv-dragging-can-drop-and-over': this.props.isDragging && this.props.canDrop && this.props.isOver },
      { 'xcsv-dragging-can-drop': this.props.isDragging && this.props.canDrop && !this.props.isOver && !this.props.disabled }
    ])}>
        &nbsp;
    </div>;

    return this.props.disabled ? target : this.props.connectDropTarget(target);
  }
}

const columnTarget = {
  drop (props, monitor, _component) {
    props.onDrop({ id: props.containerId }, { type: monitor.getItemType(), metadata: monitor.getItem().resourceTypeMetadata, id: monitor.getItem().itemId  }, props.position);
  },
  canDrop(props, monitor) {

    // It is allowed to drop a 'ROW' only if all the parents are either 'main' or 'loop' elements.
    if (!props.isTopLevelNode) {
      return ACCEPTED_BLOCK_TYPES.filter(a => a !== EDIE_BLOCK_TYPE.ROW).indexOf(monitor.getItemType()) !== -1;
    }

    return ACCEPTED_BLOCK_TYPES.indexOf(monitor.getItemType()) !== -1;
  }
};

function collect (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    isDragging: ACCEPTED_BLOCK_TYPES.indexOf(monitor.getItemType()) !== -1
  };
}

export default DropTarget(ACCEPTED_BLOCK_TYPES, columnTarget, collect)(XCsvLoopDropTarget);
