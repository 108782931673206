import React, { Component } from 'react';

import { Radio, RadioGroup, TextField } from '@cimpress/react-components';
import { CsvFieldContentType } from '../../../../../../utils/helpers';
import { XBlockPropertiesState } from '../../../models/XBlockState';
import { DEBOUNCE_TIME_INTERVAL } from '../../../../../../constants';
import debounce from 'debounce';

interface XCsvCellPropertiesProps {
  id: string;
  t: Function;
  properties: any;
  templateMetadata: any;
  onUpdateBlockMetaData: Function;
}

class XCsvCellProperties extends Component<XCsvCellPropertiesProps, XBlockPropertiesState> {
  updateProperties:Function;

  constructor(props: XCsvCellPropertiesProps) {
    super(props);
    this.state = {
      updates: props.properties.metadata
    };
    this.updateProperties = debounce(this.confirm, DEBOUNCE_TIME_INTERVAL);
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
    if (JSON.stringify(prevProps.properties.metadata) !== JSON.stringify(this.props.properties.metadata)) {
      this.setState({
        updates: this.props.properties.metadata
      });
    }
    if (JSON.stringify(prevState.updates) !== JSON.stringify(this.state.updates)){
      this.updateProperties();
    }
  }

  confirm() {
    this.props.onUpdateBlockMetaData({ id: this.props.id }, {
      ...this.state.updates,
      dateFormat: this.state.updates.contentType === CsvFieldContentType.DATE ? this.state.updates.dateFormat || undefined : undefined
    });
  }

  render() {
    const { t } = this.props;
    const props = this.state.updates || this.props.properties.metadata || { contentType: 'text' };

    return <React.Fragment>
      <TextField
        name='Header'
        label={t('blocks.cell_header')}
        helpText={!this.props.templateMetadata.showHeaders && props.header && t('blocks.cell_header_help_note')}
        value={props.header}
        onChange={(e) => this.setState({ updates: { ...this.state.updates, header: e.target.value } })}
      />

      <h5>{t('blocks.content_type')}</h5>
      <RadioGroup
        className='linkTypeRadioGroup'
        onChange={(e, contentTypeSelected) => this.setState({ updates: { ...this.state.updates, contentType: contentTypeSelected } })}
        inline
        valueSelected={props.contentType}
        name='ContentType'>
        <Radio label={t('blocks.date')} value={CsvFieldContentType.DATE} />
        <Radio label={t('blocks.number')} value={CsvFieldContentType.NUMBER} />
        <Radio label={t('blocks.text')} value={CsvFieldContentType.TEXT} />
      </RadioGroup>

      {props.contentType === CsvFieldContentType.DATE
        && <>
          <br />
          <TextField
            name='DateFormat'
            label={t('blocks.date_format')}
            helpText={t('blocks.date_format_help_text')}
            value={props.dateFormat}
            onChange={(e) => {
              this.setState({ updates: { ...this.state.updates, dateFormat: e.target.value } });
            }}
          />
        </>
      }
    </React.Fragment>;
  }
}

export default XCsvCellProperties;
