import React, { Component } from 'react';
import { ColorUtils } from '../../../../../../utils/helpers';
import TextFieldColorPicker from '../../../../../generic/TextFieldColorPicker';
import { Select, TextField } from '@cimpress/react-components';
import CssPaddingSelector from '../../../../../generic/CssPaddingSelector';
import {
  convertToOptions,
  SupportedLineHeightsOptions,
  SupportedLineStylesOptions
} from '../../../../../../utils/options';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { XBlockPropertiesState } from '../../../models/XBlockState';
import { DEBOUNCE_TIME_INTERVAL } from '../../../../../../constants';
import debounce from 'debounce';

class XVerticalSpacerProperties extends Component<XBlockPropertiesProps, XBlockPropertiesState> {
  updateProperties:Function;

  constructor(props) {
    super(props);
    this.state = {
      updates: props.properties
    };
    this.updateProperties = debounce(this.confirm,DEBOUNCE_TIME_INTERVAL);
  }

  componentDidUpdate(prevProps,prevState){
    if (JSON.stringify(prevProps.properties) !== JSON.stringify(this.props.properties)) {
      this.setState({
        updates: this.props.properties
      });
    }
    if (JSON.stringify(prevState.updates) !== JSON.stringify(this.state.updates) && prevProps.id === this.props.id){
      this.updateProperties();
    }
    if (prevProps.id !== this.props.id){
      this.props.onUpdateItem({ id: prevProps.id }, this.state.updates);
    }
  }

  confirm() {
    this.props.onUpdateItem({ id: this.props.id }, this.state.updates);
  }

  render() {
    const { t } = this.props;
    const props = this.state.updates || this.props.properties || {};

    return <React.Fragment>
      <TextFieldColorPicker
        name='lineColor'
        label={t('blocks.line_color')}
        helpText={t('blocks.line_color_help_text')}
        color={ColorUtils.rgb2hex(props.lineColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { lineColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { lineColor: hexColor }) });
        }} />
      <TextFieldColorPicker
        name='backgroundColor'
        label={t('blocks.background_color')}
        helpText={t('blocks.background_color_help_text')}
        color={ColorUtils.rgb2hex(props.backgroundColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: hexColor }) });
        }}
      />
      <Select
        name='height'
        label={t('blocks.height')}
        helpText={t('blocks.height_help_text')}
        value={convertToOptions(props.lineHeight, SupportedLineHeightsOptions)}
        options={SupportedLineHeightsOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { lineHeight: e ? e.value : null }) })}
        isClearable={true}
      />
      <Select
        name='lineStyle'
        label={t('blocks.line_style')}
        helpText={t('blocks.line_style_help_text')}
        value={convertToOptions(props.lineStyle, SupportedLineStylesOptions)}
        options={SupportedLineStylesOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { lineStyle: e ? e.value : null }) })}
        isClearable={true}
      />

      <CssPaddingSelector
        t={t}
        label={t('blocks.padding')}
        helpText={t('blocks.padding_help_text')}
        padding={props.padding}
        onChange={(value) => this.setState({ updates: Object.assign({}, this.state.updates, { padding: value }) })}
      />

      <TextField
        name='class'
        label={t('blocks.class')}
        helpText={t('blocks.class_help_text')}
        value={props.class}
        onChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { class: e.target.value }) });
        }} />

    </React.Fragment>;
  }
}

export default XVerticalSpacerProperties;
