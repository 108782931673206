import { PayloadActions } from '../storeActions';
import { PayloadActionTypes, PayloadState } from './types';

const defaultState: PayloadState = {
  providedExpandedPayload: {},
  providedOriginalPayload: {},
  headers: {}
};

const payload = (state = defaultState, action: PayloadActionTypes): PayloadState => {
  switch (action.type) {
    case PayloadActions.SET_EXPANDED_PAYLOAD: {
      return Object.assign({}, state, {
        providedExpandedPayload: action.payload
      });
    }
    case PayloadActions.SET_ORIGINAL_PAYLOAD: {
      return Object.assign({}, state, {
        providedOriginalPayload: action.payload
      });
    }
    case PayloadActions.SET_CUSTOM_STEREOTYPE_HEADERS: {
      return Object.assign({}, state, {
        headers: action.headers
      });
    }
    default:
      return state;
  }
};

export default payload;
