import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@cimpress/react-components';
import * as packageInfo from '../../package.json';
import i18n from '../utils/i18n';
import SettingsModal from '@cimpress-technology/react-platform-settings';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as settingsActions from '../store/settings/actions';
import { connect } from 'react-redux';
import { RawEditorSettings } from '../constants';
import cloneDeep from 'lodash/cloneDeep';
import { convertToOptions } from '../utils/options';

class CustomAppSettings extends Component {
  static propTypes = {
    onSettingsUpdated: PropTypes.func,
  }

  renderRawEditorOptions() {

    const modes = RawEditorSettings.supportedModes.map(k => ({ value: k, label: k }));
    const selectedMode = convertToOptions(this.props.settings.rawEditor.mode || 'handlebars', modes);

    const themes = RawEditorSettings.supportedThemes.map(k => ({ value: k, label: k }));
    const selectedTheme = convertToOptions(this.props.settings.rawEditor.theme || 'monokai', themes);

    const fontSizes = RawEditorSettings.supportedFontSizes.map(k => ({ value: k, label: k }));
    const selectedFontSize = convertToOptions(this.props.settings.rawEditor.fontSize || 12, fontSizes);

    return <Fragment>
      <div className='form-group' >
        <Select
          label={this.props.t('toolkit.raw_editor_mode_label')}
          value={selectedMode}
          options={modes}
          onChange={(v) => this.props.onSettingsUpdated('rawEditor', 'mode', v.value)}
          isClearable={false}
        />
      </div>
      <div className='form-group' >
        <Select
          label={this.props.t('toolkit.raw_editor_theme_label')}
          value={selectedTheme}
          options={themes}
          onChange={(v) => this.props.onSettingsUpdated('rawEditor', 'theme', v.value)}
          isClearable={false}
        />
      </div>
      <div className='form-group' >
        <Select
          label={this.props.t('toolkit.raw_editor_font_size_label')}
          value={selectedFontSize}
          options={fontSizes}
          onChange={(v) => this.props.onSettingsUpdated('rawEditor', 'fontSize', v.value)}
          isClearable={false}
        />
      </div>
    </Fragment>;
  }

  render() {

    // TODO: When more settings appear -> use tabs

    // const tabs = [
    //   {
    //     name: 'Raw editor',
    //     block: this.renderRawEditorOptions(),
    //     href: '#'
    //   },
    // ]
    //
    // return <TabCard tabs={tabs} selectedIndex={0} />

    return <Fragment>
      {this.renderRawEditorOptions()}
      <div>
        <span>Version: {packageInfo.version}</span>
      </div>
    </Fragment>;
  }
}

class AppSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: props.settings || {}
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.settings) !== JSON.stringify(prevProps.settings)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        settings: this.props.settings || {}
      });
    }
  }

  onAppSettingsUpdated(category, settingName, settingValue) {
    let settings = cloneDeep(this.state.settings);
    settings[category][settingName] = settingValue;

    this.setState({
      settings: settings
    });
  }

  render() {
    return <SettingsModal
      lang={this.props.i18n.language}
      authToken={this.props.accessToken || 'x'}
      // TODO: For now the setting component doesn't support BG and ES,
      // TODO: so excluding these for now not to generate errors on the console
      supportedLanguages={Object.keys(i18n.options.resources)}
      appSettingsTitle={'Template Designer'}
      appSettingsContent={
        <CustomAppSettings
          t={this.props.t}
          settings={this.state.settings}
          onSettingsUpdated={(c, k, v) => this.onAppSettingsUpdated(c, k, v)}
        />
      }
      canSave
      onSave={() => {
        this.props.putSettings(
          this.props.accessToken,
          Object.assign({},
            this.props.settings,
            this.state.settings));
        this.props.onSettingsUpdated();
      }}
    />;
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    putSettings: (accessToken, settings) => { dispatch(settingsActions.putSettings(accessToken, settings)); },
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSettings)));
