import React, { Component } from 'react';

import XCsvMainDropTarget from './XCsvMainDropTarget';
import { XBlockProps } from '../../../models/XBlockProps';
import { renderBlock } from '../../blockRenderer';

class XCsvMain extends Component<XBlockProps> {

  renderDropTarget(position) {
    return <XCsvMainDropTarget
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  render () {
    return <div className={'xcsv-main-container'} {...this.props.defaultHandlers}>
      {this.props.elements && this.props.elements.length > 0
        ? (this.props.elements || [])
          .map((element, index) =>
            <React.Fragment key={index}>
              {renderBlock(element, this.props.renderProps, true)}
              {this.renderDropTarget(index + 1)}
            </React.Fragment>)
        : <div style={{ width: '100%' }}>{this.props.t('blocks.drag_and_drop_here')}</div>}
    </div>;
  }
}

export default XCsvMain;
