import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import classNames from 'classnames';

let withTooltip = (tooltip, content) => {
  if (!tooltip) {
    return content;
  }
  return <Tooltip contents={tooltip}>{content}</Tooltip>;
};

let DefaultButton = (props) => {
  let baseButton = (
    <button
      autoFocus={!!props.autoFocus}
      className={classNames(
        'btn',
        `btn-${props.status}`,
        {
          'btn-sm' : props.size === 'sm',
          'disabled' : props.disabled
        })}
      onClick={props.disabled
        ? undefined
        : props.onClick}>
      {props.faIcon
        ? <i className={`fa ${props.faIcon}`} aria-hidden='true' />
        : null}
      {props.faIcon
        ? ' '
        : null}
      {props.title}
    </button>
  );

  if (props.disabled && props.disabledTooltip) {
    return withTooltip(props.disabledTooltip, baseButton);
  }

  if (props.tooltip) {
    return withTooltip(props.tooltip, baseButton);
  }

  return baseButton;
};

DefaultButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  size: PropTypes.string,
  onClick: PropTypes.func,
  faIcon: PropTypes.string,
  tooltip: PropTypes.string,
  autoFocus: PropTypes.bool
};

DefaultButton.defaultProps = {
  status: 'default'
};

export default DefaultButton;
