import React, { Component } from 'react';

import XLoopDropTarget from './XLoopDropTarget';

import XLoopHeader from './XLoopHeader';
import { XLoopProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';
import { renderBlock } from '../../blockRenderer';

class XLoop extends Component<XLoopProps, XBlockState> {

  constructor (props: XLoopProps) {
    super(props);
    this.state = {};
  }

  renderDropTarget(disabled, position) {
    return <XLoopDropTarget
      isTopLevelNode={this.props.isTopLevelNode}
      disabled={disabled}
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  renderBody() {
    const disableDropWithin = this.props.draggingItemId === this.props.id || this.props.renderProps.disableDrop;
    const itemProps = this.props.renderProps.flatStructure[this.props.id].properties || {};

    return <React.Fragment>
      {this.renderDropTarget(disableDropWithin, 0)}
      {(this.props.elements || []).map((element, index) => <React.Fragment key={index}>
        {renderBlock(
          element,
          Object.assign({}, this.props.renderProps, {
            disableDrop: disableDropWithin,
            loopPaths: (this.props.renderProps.loopPaths || []).concat(itemProps.loopPath)
          }),
          // Note: Loop elements do not change the 'top-level' property of elements
          this.props.isTopLevelNode
        )}
        {this.renderDropTarget(disableDropWithin, index + 1)}
      </React.Fragment>
      )}
    </React.Fragment>;
  }

  render () {
    const  defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';
    
    return <div
      className={`${defaultClass} xemail-loop-container`}
      {...this.props.defaultHandlers}>
      <XLoopHeader {...this.props} />
      {this.renderBody()}
    </div>;
  }
}

export default XLoop;
