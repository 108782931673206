import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Loading from './Loading';
import React, { Component } from 'react';

interface AuthenticatedProps extends WithTranslation {
  component: any;
  accessToken: string;
  t?: Function;
}

class Authenticated extends Component<AuthenticatedProps> {
  render() {
    if ( !this.props.accessToken ) {
      return <div className='container margin-top'>
        <div>
          <Loading message={this.props.t && this.props.t('authenticating_pls_wait')} />
        </div>
      </div>;
    }
    const Child = this.props.component;

    return <Child {...this.props}/>;
  }
}
const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Authenticated));
