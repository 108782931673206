import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from './DefaultButton';
import { useTranslation } from 'react-i18next';

let DeleteButton = (props) => {
  const { t } = useTranslation();

  return <DefaultButton
    status={'danger'}
    title={props.title || t('delete')}
    faIcon={'fa-trash'}
    onClick={props.onClick}
    tooltip={props.tooltip}
    disabled={props.disabled || false}
    disabledTooltip={props.disabledTooltip}
  />;
};

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string
};

export default DeleteButton;
