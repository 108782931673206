import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { draggableResource, XResource } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import Icon from '../XRow/icon_row.svg';

class XSubTemplateHtmlResource extends Component {
  render () {
    return draggableResource(this.props, <div>
      <XResource resourceName='Template' resourceIcon={Icon} />
    </div>);
  }
}

const source = {
  beginDrag () {
    return {
      resourceType: EDIE_BLOCK_TYPE.SUB_TEMPLATE
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.SUB_TEMPLATE, source, collect)(XSubTemplateHtmlResource);
