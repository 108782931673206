import React, { Component } from 'react';
import { FlexBox, TextField, Button, Tag, Tooltip } from '@cimpress/react-components';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { XBlockPropertiesState } from '../../../models/XBlockState';
import { TemplateSelect } from '@cimpress-technology/react-templates';
import { connect } from 'react-redux';
import i18n from '../../../../../../utils/i18n';
import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import { getTemplateIdFromUrl } from '../../../../../../utils/helpers';
import { DEBOUNCE_TIME_INTERVAL, TemplateType } from '../../../../../../constants';
import debounce from 'debounce';

interface XSubTemplateHtmlPropertiesProps extends XBlockPropertiesProps {
  accessToken: string;
}

interface XSubTemplateHtmlPropertiesState extends XBlockPropertiesState {
  newParameter: { key: string; value: string; };
}

const TEMPLATE_DESIGNER_API_URL = process.env.REACT_APP_TEMPLATE_DESIGNER_API_URL;

class XSubTemplateHtmlProperties extends Component<XSubTemplateHtmlPropertiesProps, XSubTemplateHtmlPropertiesState> {
  updateProperties: Function;

  constructor(props: XSubTemplateHtmlPropertiesProps) {
    super(props);
    this.state = {
      updates: props.properties,
      newParameter: { key: '', value: '' }
    };
    this.updateProperties = debounce(this.confirm, DEBOUNCE_TIME_INTERVAL);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.properties) !== JSON.stringify(this.props.properties)) {
      this.setState({
        updates: this.props.properties
      });
    }
    if (JSON.stringify(prevState.updates) !== JSON.stringify(this.state.updates) && prevProps.id === this.props.id) {
      this.updateProperties();
    }
    if (prevProps.id !== this.props.id) {
      this.props.onUpdateItem({ id: prevProps.id }, this.state.updates);
    }
  }

  getTemplateUrnFromUrl(templateUrl: string): string {
    return 'urn:stereotype:template:' + getTemplateIdFromUrl(templateUrl);
  }

  getTemplateUrlFromId(templateId: string): string {
    return `${TEMPLATE_DESIGNER_API_URL}/v1/templates/${templateId}`;
  }

  addParamToPartial() {
    this.setState(prevState => {
      return {
        updates: {
          ...prevState.updates,
          parameters: {
            ...prevState.updates.parameters,
            [prevState.newParameter.key]: prevState.newParameter.value
          }
        },
        newParameter: { key: '', value: '' }
      };
    });
  }

  removeParamFromPartial(key: string, value: string) {
    this.setState(prevState => {
      let params = { ...prevState.updates.parameters };
      Object.entries(params).forEach(([paramKey, paramValue]) => {
        if (key === paramKey && value === paramValue) { delete params[paramKey]; }
      });
      return {
        updates: {
          ...prevState.updates,
          parameters: params
        }
      };
    });
  }

  confirm() {
    this.setState({ newParameter: { key: '', value: '' } });
    this.props.onUpdateItem({ id: this.props.id }, this.state.updates);
  }

  render() {
    const { t } = this.props;
    const props = this.state.updates || this.props.properties || {};
    let templateId = (props.templateUrn || '').split(':').slice(-1)[0];
    let isDuplicateParamKey = this.state.newParameter.key in this.state.updates.parameters;

    return <React.Fragment>
      <TemplateSelect
        label={t('blocks.partial_template_select_label')}
        language={i18n.language}
        accessToken={this.props.accessToken}
        selectedTemplateUrl={this.getTemplateUrlFromId(templateId)}
        filterTemplateTypeBy={[TemplateType.RAW, TemplateType.XDOC, TemplateType.XEMAIL]}
        filterTemplateMetadataBy={[{ key: 'isPartialTemplate' }]}
        onChangeOption={(option) => this.setState({ updates: Object.assign({}, this.state.updates, { templateUrn: this.getTemplateUrnFromUrl(option.value), templateName: option.label }) })}
        helpText={<FlexBox middle marginX="xs"><span>{t('blocks.partial_template_select_help_text')}</span><Tooltip direction="right" contents={t('blocks.partial_template_select_help_tooltip')}><i className={'fa fa-2x fa-question-circle context-button-icon partial-template-context-icon'} /></Tooltip></FlexBox>}
      />

      <h5>{t('blocks.parameters')}</h5>

      <FlexBox middle marginX="m" paddingY="xs">
        <TextField
          name='ParameterKey'
          className='subtemplate-parameter-input'
          label={t('blocks.parameter_key')}
          value={this.state.newParameter.key}
          onChange={(e) => {
            this.setState({ newParameter: { ...this.state.newParameter, key: e.target.value } });
          }}
        />
        <TextField
          name='ParameterValue'
          className='subtemplate-parameter-input'
          label={t('blocks.parameter_value')}
          value={this.state.newParameter.value}
          onChange={(e) => {
            this.setState({ newParameter: { ...this.state.newParameter, value: e.target.value } });
          }}
        />
        <Button
          type="default"
          onClick={() => this.addParamToPartial()}
          className="add-button"
          style={{ border: 'none' }}
          disabled={!templateId || isDuplicateParamKey || !(this.state.newParameter.key && this.state.newParameter.value)}
        >
          <IconAddCircle size="2x" />
        </Button>
      </FlexBox>

      {isDuplicateParamKey && <div className='text-danger'>{t('blocks.parameter_error')}</div>}

      <div className="parameters-preview">
        {Object.keys(props.parameters || {}).map(paramKey => (
          <Tag key={paramKey} value={`${paramKey} = ${props.parameters[paramKey]}`} removable onRemoveClick={() => this.removeParamFromPartial(paramKey, props.parameters[paramKey])} />
        ))}
      </div>

    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
  };
};

export default connect(mapStateToProps)(XSubTemplateHtmlProperties);
