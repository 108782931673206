import Command from '@ckeditor/ckeditor5-core/src/command';
import { PLACEHOLDER_ELEMENT, PLACEHOLDER_ELEMENT_BLOCK } from './placeholder';
import { analyzePlaceholder, SUBTYPES } from '../../../../../../utils/placeholderHelpers';

export default class PlaceholderCommand extends Command {


  _isPlaceholder(element) {
    return element && (element.name === PLACEHOLDER_ELEMENT || element.name === PLACEHOLDER_ELEMENT_BLOCK);
  }

  refresh() {
    const model = this.editor.model;
    const doc = model.document;
    const element = doc.selection.getSelectedElement();

    if (this._isPlaceholder(element)) {
      this.value = element.getAttribute('content');
    } else {
      this.value = undefined;
    }
  }

  execute(newContent) {
    const editor = this.editor;
    const element = editor.model.document.selection.getSelectedElement();

    editor.model.change(writer => {
      let attrs = {};
      if (this._isPlaceholder(element)) {
        for (let attr of element.getAttributeKeys()) {
          attrs[attr] = element.getAttribute(attr);
        }
        writer.remove(element);
      }
      attrs.content = newContent;
      const analyzedPlaceholder = analyzePlaceholder(newContent || '');

      if (analyzedPlaceholder.subTypes.includes(SUBTYPES.OPEN) || analyzedPlaceholder.subTypes.includes(SUBTYPES.CLOSE) ) {
        attrs.sub = 'block';
        editor.model.insertContent(
          writer.createElement(PLACEHOLDER_ELEMENT_BLOCK, attrs),
          editor.model.document.selection);
      } else {
        attrs.sub = 'normal';
        editor.model.insertContent(
          writer.createElement(PLACEHOLDER_ELEMENT, attrs),
          editor.model.document.selection);
      }
    });
  }
}
