import React, { Component } from 'react';
import { TextField } from '@cimpress/react-components';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { XBlockPropertiesState } from '../../../models/XBlockState';
import { DEBOUNCE_TIME_INTERVAL } from '../../../../../../constants';
import debounce from 'debounce';

class XCsvLoopProperties extends Component<XBlockPropertiesProps, XBlockPropertiesState> {
  updateProperties: Function;
  constructor (props: XBlockPropertiesProps) {
    super(props);
    this.state = {
      updates: props.properties
    };
    this.updateProperties = debounce(this.confirm,DEBOUNCE_TIME_INTERVAL);
  }

  confirm () {
    this.props.onUpdateItem({ id: this.props.id }, this.state.updates);
  }

  componentDidUpdate(prevProps,prevState){
    if (JSON.stringify(prevState.updates) !== JSON.stringify(this.state.updates)){
      this.updateProperties();
    }
    if (prevProps.id !== this.props.id){
      this.props.onUpdateItem({ id: prevProps.id }, this.state.updates);
    }
  }  

  render() {
    const { t } = this.props;
    const props = this.state.updates || this.props.properties || {};

    let groupBegin = props.loopPath;
    let groupEnd = (props.loopPath || '').split(' ')[0];

    return <React.Fragment>

      <TextField
        name='loopPath'
        label={t('blocks.loopPath')}
        helpText={t('blocks.loopPath_help_text')}
        value={props.loopPath}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { loopPath: e.target.value }) } )}/>

      <br/>
      {t('blocks.repeater-sample-caption')}
      <div className={'xcsv-repeater-demo'}>
        <div className='xcsv-repeater-demo-group-begin'>{'{{#'}{groupBegin}{'}}'}</div>
        <div className='xcsv-repeater-demo-placeholder'>&nbsp;</div>
        <div className='xcsv-repeater-demo-group-begin'>{'{{/'}{groupEnd}{'}}'}</div>
      </div>

    </React.Fragment>;
  }
}

export default XCsvLoopProperties;
