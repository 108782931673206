import React, { Component } from 'react';

import XCsvLoopDropTarget from './XCsvLoopDropTarget';

import XCsvLoopHeader from './XCsvLoopHeader';
import { XLoopProps } from '../../../models/XBlockProps';
import { renderBlock } from '../../blockRenderer';

class XCsvLoop extends Component<XLoopProps> {
  constructor (props: XLoopProps) {
    super(props);
    this.state = {};
  }

  renderDropTarget(disabled, position) {
    return <XCsvLoopDropTarget
      isTopLevelNode={this.props.isTopLevelNode}
      disabled={disabled}
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  renderBody() {
    const disableDropWithin = this.props.draggingItemId === this.props.id || this.props.renderProps.disableDrop;
    const itemProps = this.props.renderProps.flatStructure[this.props.id].properties || {};

    return <React.Fragment>
      {this.renderDropTarget(disableDropWithin, 0)}
      {(this.props.elements || []).map((element, index) => <React.Fragment key={index}>
        {renderBlock(
          element,
          Object.assign({}, this.props.renderProps, {
            disableDrop: disableDropWithin,
            loopPaths: (this.props.renderProps.loopPaths || []).concat(itemProps.loopPath)
          }),
          // Note: Loop elements do not change the 'top-level' property of elements
          this.props.isTopLevelNode
        )}
        {this.renderDropTarget(disableDropWithin, index + 1)}
      </React.Fragment>
      )}
    </React.Fragment>;
  }

  render () {
    return <div
      className={'xcsv-block-default-container xcsv-loop-container'}
      {...this.props.defaultHandlers}>
      <XCsvLoopHeader {...this.props} />
      {this.renderBody()}
    </div>;
  }
}

export default XCsvLoop;
