import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';
import { Portal } from 'react-portal';

import './ColorPicker.css';

export default class ColorPicker extends Component {
  constructor (props) {
    super(props);
    this.state = { show: false, textColor: this.props.color };

    this.presetColors = [
      // Reds
      '#D24A35', // Error
      '#F0563A', // Persimmon.base
      '#D63625', // Persimmon.dark
      '#BC2111', // Persimmon.darkest

      // Greys
      '#333943', // Coal
      '#4f5d75', // Granite
      '#697582', // Shale
      '#C4CDD6', // Alloy
      '#E1E6EB', // Platinum
      '#FFFFFF', // White

      // Blues
      '#0088A9', // Ocean
      '#006F8A', // Teal
      '#41B5DC', // Info
      '#A9D6E5', // Sky

      // Others
      '#35AD96', // Success.base
      '#22917E', // Success.dark
      '#127769', // Success.darkest
      '#FFA23F' // Warning
    ];
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ textColor: props.color });
  }

  renderTrigger (content) {
    return <span
      className={this.props.coverClassName}
      style={{ backgroundColor: this.props.disabled ? 'rgba(239,243,245,.5)' : this.props.color  }}
      onClick={(e) => {
        if (this.props.disabled) {
          return;
        }
        // This is a little funny - make sure we don't do anything on click within the picker
        // otherwise the picker box "moves" as we change x/y ;)
        if (!this.picker || !this.picker.contains(e.target)) {
          this.setState({ show: true, x: e.clientX, y: e.clientY });
        }
      }}>
      {content}
    </span>;
  }

  renderOutsideClickOverlay () {
    if (!this.props.withOutsideClickOverlay) {
      return null;
    }

    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    };

    return <div style={cover} onClick={(e) => {
      e.stopPropagation();
      this.setState({ show: false }, () => {
        this.props.onCancelled && this.props.onCancelled();
      });
    }} />;
  }

  renderPicker () {
    const popover = {
      position: 'absolute',
      zIndex: '2000',
      left: this.state.x - 200,
      top: this.state.y
    };

    return <div>
      <div style={popover}>
        {this.renderOutsideClickOverlay()}
        <div ref={el => (this.picker = el)}>
          <GithubPicker
            triangle={'hide'}
            color={this.props.disabled ? 'rgba(239,243,245,.5)' : this.props.color}
            colors={this.presetColors}
            onChange={(color, e) => {
              if (this.props.disabled) {
                return;
              }
              e.stopPropagation();
            }}
            onChangeComplete={(color) => {
              if (this.props.disabled) {
                return;
              }
              this.setState({ show: false }, () => this.props.onChange(color.hex));
            }} />
        </div>
      </div>
    </div>;
  }

  render () {
    if (this.props.showPickerOnly) {
      return this.renderPicker();
    }

    if (!this.state.show) {
      return this.renderTrigger(null);
    }

    return this.renderTrigger(<Portal>{this.renderPicker()}</Portal>);
  }
}

ColorPicker.propTypes = {
  coverClassName: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onChange: PropTypes.func,
  onCancelled: PropTypes.func,
  showTextField: PropTypes.bool,
  withOutsideClickOverlay: PropTypes.bool
};

ColorPicker.defaultProps = {
  showTextField: false,
  showPickerOnly: false,
  withOutsideClickOverlay: true
};
