import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, TextField } from '@cimpress/react-components';
import clone from 'lodash/clone';

const PaddingType = {
  SAME: 'same',
  DIFF: 'diff'
};

// TODO: replace with some package
const parsePadding = (padding) => {
  const parts = (padding || '').split(' ');
  if (parts.length === 0) {
    return {
      top: null,
      right: null,
      bottom: null,
      left: null
    };
  }
  if (parts.length === 1) {
    return {
      top: parts[0],
      right: parts[0],
      bottom: parts[0],
      left: parts[0]
    };
  }
  if (parts.length === 2) {
    return {
      top: parts[0],
      right: parts[1],
      bottom: parts[0],
      left: parts[1]
    };
  }
  if (parts.length === 3) {
    return {
      top: parts[0],
      right: parts[1],
      bottom: parts[2],
      left: parts[1]
    };
  }
  if (parts.length === 4) {
    return {
      top: parts[0],
      right: parts[1],
      bottom: parts[2],
      left: parts[3]
    };
  }
};

const joinPadding = (pad) => {
  let padding = '';
  if (pad.top) {
    padding += pad.top + ' ';
  }
  if (pad.right) {
    padding += pad.right + ' ';
  }
  if (pad.bottom) {
    padding += pad.bottom + ' ';
  }
  if (pad.left) {
    padding += pad.left + ' ';
  }

  padding = padding.trim();

  return padding ? padding : null;
};

export default class CssPaddingSelector extends Component {

  constructor(props) {
    super(props);
    const pad = parsePadding(props.padding);
    this.state = {
      pad: pad,
      paddingType: pad.top === pad.left && pad.left === pad.right && pad.right === pad.bottom ? PaddingType.SAME : PaddingType.DIFF
    };
  }

  componentDidUpdate(prevProps){
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const pad = parsePadding(this.props.padding);
      this.setState({
        pad: pad,
        paddingType: pad.top === pad.left && pad.left === pad.right && pad.right === pad.bottom ? PaddingType.SAME : PaddingType.DIFF
      });
    }
  }

  onChange(e, key) {
    let pad =  clone(this.state.pad);
    pad[key] = e.target.value;
    if (this.state.paddingType === PaddingType.SAME) {
      pad.left = pad.top;
      pad.right = pad.top;
      pad.bottom = pad.top;
    }

    this.setState({ pad: pad }, () => this.props.onChange(joinPadding(pad)));
  }

  render () {
    const { t } = this.props;
    const samePadding = this.state.paddingType === PaddingType.SAME;

    return <div className={'css-padding-selector-container'}>
      <RadioGroup inline name={'type'} valueSelected={this.state.paddingType} onChange={(e,v) => {
        let pad = clone(this.state.pad);
        if ( v === PaddingType.SAME) {
          pad.left = pad.top;
          pad.right = pad.top;
          pad.bottom = pad.top;
        }
        this.setState({ 'paddingType': v, pad: pad });
      }}>
        <Radio label={t('blocks.same_padding')} value={PaddingType.SAME}/>
        <Radio label={t('blocks.separate_padding')} value={PaddingType.DIFF}/>
      </RadioGroup>
      <div style={{ display: 'flex' }}>
        <div>
          <TextField label={t('blocks.padding_top')} value={this.state.pad.top} onChange={(e) => this.onChange(e,'top')} />
        </div>
        <div>&nbsp;</div>
        <div>
          <TextField disabled={samePadding} label={t('blocks.padding_right')}  value={this.state.pad.right} onChange={(e) => this.onChange(e,'right')} />
        </div>
        <div>&nbsp;</div>
        <div>
          <TextField disabled={samePadding} label={t('blocks.padding_bottom')}  value={this.state.pad.bottom} onChange={(e) => this.onChange(e,'bottom')} />
        </div>
        <div>&nbsp;</div>
        <div>
          <TextField disabled={samePadding} label={t('blocks.padding_left')} value={this.state.pad.left} onChange={(e) => this.onChange(e,'left')} />
        </div>
      </div>
      {this.props.helpText}
    </div>;
  }
}

CssPaddingSelector.propTypes = {
  padding: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
};
