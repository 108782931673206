import { getTemplate } from '../../api/stereotype.api';
import { TemplateType } from '../../constants';
import TemplateIncompatibleError from '../../utils/errors/TemplateIncompatibleError';
import NotLoggedInError from '../../utils/errors/NotLoggedInError';
import * as edieActions from '../edie/actions';
import { AppActions } from '../storeActions';
import { TemplateProperties } from './types';

export const initializeTemplate = (
  properties: {
    templateProperties: TemplateProperties;
    templateObject: any;
  },
  templateType: string,
  templateId: string | undefined
) => {
  return {
    type: AppActions.INIT_TEMPLATE,
    properties,
    templateType,
    templateId
  };
};

export const renameLoadedTemplate = (
  templateId: string,
  templateName: string,
  isPublic: boolean
) => {
  return {
    type: AppActions.RENAME_LOADED_TEMPLATE,
    templateId,
    templateName,
    isPublic
  };
};

export const setLoading = (loading: boolean, loadingMessage: string) => {
  return {
    type: AppActions.SET_LOADING,
    loading,
    loadingMessage
  };
};

export const setError = (error: any) => {
  return {
    type: AppActions.SET_ERROR,
    loading: false,
    error
  };
};

export const templatePropertyChanged = (name: string, value: any) => {
  return {
    type: AppActions.TEMPLATE_PROPERTY_CHANGED,
    propertyName: name,
    propertyValue: value
  };
};

export const templateObjectChanged = (content: any) => {
  return {
    type: AppActions.TEMPLATE_OBJECT_CHANGED,
    content
  };
};

export const resetAppReducer = () => {
  return {
    type: AppActions.RESET_APP_REDUCER,
  };
};

export const createTemplate = (accessToken, templateType, contentType) => {
  return (dispatch) => {
    _loadTemplate(dispatch, getNewTemplate(accessToken, templateType, contentType));
  };
};

export const loadTemplate = (accessToken, templateId) => {
  return (dispatch) => {
    _loadTemplate(dispatch, getTemplate(accessToken, templateId), templateId);
  };
};

export const setIsTemplateModified = (isModified) => {
  return {
    type: AppActions.TEMPLATE_SET_IS_MODIFIED,
    isModified
  };
};

const getNewTemplate = (accessToken, templateType, contentType) => {
  const templateCompatibleTypes = Object.keys(TemplateType).map(t => TemplateType[t]);

  if (!templateCompatibleTypes.includes(templateType)) {
    return Promise.reject(new TemplateIncompatibleError(
      `<span>Incompatible 'creating' parameter! The value must be one of the following: ${templateCompatibleTypes.join(', ')}</span>`));
  }

  return Promise.resolve({
    templateProperties: {
      isPublic: false,
      templateType: templateType,
      contentType: contentType || 'text/handlebars',
      baseContentType: contentType || 'text/handlebars',
    }
  });
};

const _loadTemplate = (dispatch, getTemplatePromise, templateId = undefined) => {
  dispatch(setLoading(true, 'Loading the template'));
  getTemplatePromise
    .then(res => {
      dispatch(edieActions.initStructure(res.templateObject));
      dispatch(initializeTemplate(res, res.templateProperties.templateType, templateId));
    })
    .catch(err => {
      if (err.status === 404) {
        dispatch(setError({
          languageKey: 'errors.template_do_not_exist'
        }));
      } else if (err.status === 403) {
        dispatch(setError({
          languageKey: 'errors.no_access_to_this_template'
        }));
      } else if (err instanceof TemplateIncompatibleError) {
        dispatch(setError({
          alternativeMessage: err.message,
          languageKey: 'errors.template_not_compatible'
        }));
      } else if (err instanceof NotLoggedInError || err.status === 401) {
        dispatch(setError({
          languageKey: 'errors.not_logged_in',
        }));
      } else {
        dispatch(setError({
          languageKey: 'errors.failed_to_retrieve_template',
        }));
      }
    });
};
