import React, { Component } from 'react';

import './XCsvCell.scss';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { draggableResource, FullWidthItem } from '../../commonComponents';
import classNames from 'classnames';
import { XCsvCellItemProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';

class XCsvCellHeader extends Component<XCsvCellItemProps, XBlockState> {

  render() {
    const { t } = this.props;
    const itemProps = this.props.renderProps.flatStructure[this.props.id].properties;
    const colHeaderStyle = {  };
    let className = classNames(
      'xcsv-block-default-header',
      'xcsv-cell-header'
    );

    return draggableResource( this.props, <div className={className} style={colHeaderStyle}>
      <FullWidthItem >&nbsp;<>{t('blocks.cell_header_help')}</>:&nbsp;<strong>{this.props.renderProps.meta.showHeaders && (itemProps.metadata.header || t('blocks.no_cell_header'))}</strong></FullWidthItem>
    </div>);
  }
}
const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.COLUMN,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}

export default DragSource(EDIE_BLOCK_TYPE.COLUMN, source, collect)(XCsvCellHeader);
