import React, { Component } from 'react';
import { Select, TextField } from '@cimpress/react-components';
import { connect } from 'react-redux';
import * as edieActions from '../../../../../../store/edie/actions';
import * as appActions from '../../../../../../store/app/actions';
import { ColorUtils } from '../../../../../../utils/helpers';
import TextFieldColorPicker from '../../../../../generic/TextFieldColorPicker';
import { convertToOptions, EmailWidthsOptions, EmailStylesOptions } from '../../../../../../utils/options';
import { EdieState } from '../../../../../../store/edie/types';
import { AdvancedBackgroundProperties } from './AdvancedBackgroundProperties';
import IconCog from '@cimpress-technology/react-streamline-icons/lib/IconCog';
import { info } from '@cimpress/react-components/lib/colors';

interface XMainPropertiesProps {
  edie: EdieState;
  t: Function;
  onMainPropertyUpdate: Function;
}

class XMainProperties extends Component<XMainPropertiesProps, any> {
  constructor (props) {
    super(props);
    this.state = {
      showPropertiesModal: false
    };
  }

  render () {
    const { t } = this.props;
    const mainProps = ((this.props.edie.flatStructure || {}).main || {}).properties || {};

    return (
      <div className='propertyBar'>
        <Select
          label={t('email_width')}
          value={convertToOptions(mainProps.emailWidth, EmailWidthsOptions)}
          options={EmailWidthsOptions}
          onChange={(v) => {
            if (v) {
              this.props.onMainPropertyUpdate({ emailWidth: v.value });
            }
          }}
          isClearable={false}
        />
        <TextFieldColorPicker
          disabled={mainProps.emailWidth === '100%'}
          name='emailBackgroundColor'
          label={t('email_background_color')}
          color={ColorUtils.rgb2hex(mainProps.emailBackgroundColor)}
          onTextChange={(e) => {
            this.props.onMainPropertyUpdate({ emailBackgroundColor: e.target.value });
          }}
          onColorChange={(hexColor) => {
            this.props.onMainPropertyUpdate({ emailBackgroundColor: hexColor });
          }} />
        <TextFieldColorPicker
          name='backgroundColor'
          label={t('background_color')}
          color={ColorUtils.rgb2hex(mainProps.backgroundColor)}
          onTextChange={(e) => {
            this.props.onMainPropertyUpdate({ backgroundColor: e.target.value });
          }}
          onColorChange={(hexColor) => {
            this.props.onMainPropertyUpdate({ backgroundColor: hexColor });
          }} />
        <Select
          label={t('email_style')}
          value={convertToOptions(mainProps.additionalMjHeadContent || '', EmailStylesOptions)}
          options={EmailStylesOptions}
          onChange={(v) => {
            if (v) {
              this.props.onMainPropertyUpdate({ additionalMjHeadContent: v.value });
            }
          }}
          isClearable={false}
        />
        <TextField
          name='advancedProperties'
          label={t('blocks.advanced_properties')}
          readOnly={true}
          onChange={()=>{}} // eslint-disable-line no-empty-function
          rightAddon={
            <button className='btn btn-default' onClick={() => this.setState({ showPropertiesModal: true })}>
              <IconCog color={info.base}/>
            </button>
          } />
        {this.state.showPropertiesModal && <AdvancedBackgroundProperties
          t={this.props.t}
          backgroundProperties={mainProps}
          updateItem={this.props.onMainPropertyUpdate}
          show={this.state.showPropertiesModal}
          onClose={() => this.setState({ showPropertiesModal: false })}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    edie: state.edie,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMainPropertyUpdate: (data) => {
      dispatch(edieActions.updateItem({ id: 'main' }, data));
      dispatch(appActions.setIsTemplateModified(true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XMainProperties);
