export default class NotLoggedInError {
  message: string;
  _data: any;

  constructor (message, data) {
    this.message = message;
    this._data = data;
  }

  get data () {
    return this._data;
  }
}
