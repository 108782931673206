import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@cimpress/react-components';
import CancelButton from '../../generic/buttons/CancelButton';
import EscapableModal from './EscapableModal';
import ConfirmButton from '../../generic/buttons/ConfirmButton';
import { isPlaceholderValid } from '../../../utils/placeholderHelpers';
import PlaceholdersEditor from './payload/PlaceholdersEditor';

class EditPlaceholderModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newContent: props.placeholder
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.placeholder !== prevProps.placeholder && this.state.newContent !== this.props.placeholder) {
      this.setState({ newContent: this.props.placeholder });
    }
  }

  render() {

    if (!this.props.modalOpen) {
      return null;
    }
    const updating = !!this.props.placeholder;
    const disabled = !isPlaceholderValid(this.state.newContent);
    const { t } = this.props;

    return <EscapableModal
      status={'info'}
      style={{ width: '80%' }}
      show={this.props.modalOpen}
      title={t(updating ? 'update_placeholder' : 'insert_placeholder')}
      onRequestHide={this.props.onClose}
      footer={<div>
        <CancelButton onClick={this.props.onClose} />
        &nbsp;
        <ConfirmButton
          disabled={disabled}
          disabledTooltip={t('invalid_placeholder')}
          title={updating ? t('update') : t('insert')}
          faIcon={updating ? 'fa-pencil' : 'fa-plus'}
          onClick={(e) => this.props.onConfirm(this.state.newContent)} />
      </div>}>

      <TextField
        value={this.state.newContent}
        label={t('ckeditor_plugins.placeholder_label')}
        helpText={t('ckeditor_plugins.placeholder_help_text')}
        onChange={(e) => this.setState({ newContent: e.target.value })} />

      <h5>{t('select_placeholder_from_the_tree')}</h5>
      <PlaceholdersEditor
        t={t}
        basePath={(this.props.parentLoopPaths || []).join('.0.') + '.0'}
        onClick={(jsonPath) => {
          const jsonPathWithEscapedIndex = jsonPath.replace(/\.(\d+)$/, '.[$1]'); // escape index if nothing follows it: https://stackoverflow.com/a/13704081
          this.setState({ newContent: '{{' + jsonPathWithEscapedIndex + '}}' });
        }}
      />

    </EscapableModal>;
  }
}

EditPlaceholderModal.propTypes = {
  placeholder: PropTypes.string,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  parentLoopPaths: PropTypes.array,
};

export default EditPlaceholderModal;
