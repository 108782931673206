import { Profile } from '../store/auth/types';
import * as authActions from '../store/auth/actions';
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import jwt from 'jsonwebtoken';

const CLIENT_ID = process.env.REACT_APP_APPLICATION_CLIENT_ID;

export default class Auth {
  public readonly ssoAuth;
  private profile!: Profile;
  private readonly dispatch;
  private accessToken = '';

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.ssoAuth = new centralizedAuth({
      clientID: CLIENT_ID,
      redirectRoute: '/',
      logoutRedirectUri: window.location.origin
    });
  }

  get userId() {
    const content = jwt.decode(this.accessToken);
    return content ? content.sub : '';
  }

  async fastSafeTokenAccess() {
    if (this.accessToken) {
      return this.accessToken;
    }

    try {
      await this.login();
      return this.accessToken;
    } catch (e) {
      return null;
    }
  }

  get isLoggedIn() {
    return !!this.ssoAuth.isLoggedIn();
  }

  get getProfile() {
    return this.profile;
  }

  async login() {
    await this.ssoAuth.ensureAuthentication({
      nextUri: window.location.pathname + window.location.search
    });

    this.ssoAuth.on('tokenExpired', async () => {
      // This is invoked each time the current token in localStorage becomes expired.
      // This will also be immediately invoked if the token in localStorage is already expired. (configurable via emitInitialTokenExpired)
      await this.login();
    });

    this.profile = this.ssoAuth.getProfile();
    // HACK for FS's wrapper
    if (this.profile) {
      // @ts-ignore
      this.profile.user_id = this.profile.sub;
    }
    this.accessToken = this.ssoAuth.getAccessToken();
    this.dispatch(authActions.onRefreshedProfile(this.profile));
    this.dispatch(authActions.onRefreshedToken(this.accessToken));    
  }

  async logout() {
    await this.ssoAuth.logout();
  }
}
