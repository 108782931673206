import { EdieActions } from '../storeActions';

export const initStructure = (data) => {
  return {
    type: EdieActions.INIT_STRUCTURE,
    data
  };
};

export const selectItem = (itemId) => {
  return {
    type: EdieActions.SELECT_ITEM,
    itemId
  };
};

export const addItem = (parent, item, position) => {
  return {
    type: EdieActions.ADD_ITEM,
    parent,
    item,
    position
  };
};

export const elseConditionAddItem = (parent, item, position) => {
  return {
    type: EdieActions.ELSE_CONDITION_ADD_ITEM,
    parent,
    item,
    position
  };
};

export const moveItem = (newParent, existingItem, position) => {
  return {
    type: EdieActions.MOVE_ITEM,
    newParent,
    existingItem,
    position
  };
};

export const removeItem = (item) => {
  return {
    type: EdieActions.REMOVE_ITEM,
    item
  };
};

export const cloneItem = (parent, item) => {
  return {
    type: EdieActions.CLONE_ITEM,
    parent,
    item
  };
};

export const updateItem = (item, data) => {
  return {
    type: EdieActions.UPDATE_ITEM,
    item,
    data
  };
};

export const addNItem = (parent, item) => {
  return {
    type: EdieActions.ADD_N_ITEMS,
    parent,
    item
  };
};

export const setMimeHeader = (header: string, headerValue) => {
  return {
    type: EdieActions.SET_MIME_HEADER,
    header,
    headerValue
  };
};

export const setPlainTextVersion = (content) => {
  return {
    type: EdieActions.SET_PLAIN_TEXT_VERSION,
    content
  };
};

export const setPlainTextAutoGenerationFlag = (flag) => {
  return {
    type: EdieActions.SET_PLAIN_TEXT_AUTO_GENERATION_FLAG,
    flag
  };
};

export const xCsvUpdateMetaData = (xCsvMetaData) => {
  return {
    type: EdieActions.XCSV_UPDATE_METADATA,
    xCsvMetaData,
  };
};

export const xCsvUpdateBlockMetaData = (item, data) => {
  return {
    type: EdieActions.XCSV_UPDATE_BLOCK_METADATA,
    item,
    data
  };
};

export const xCsvAddItem = (parent, item, position) => {
  return {
    type: EdieActions.XCSV_ADD_ITEM,
    parent,
    item,
    position
  };
};

export const resetEdieReducer = () => {
  return {
    type: EdieActions.RESET_EDIE_REDUCER,
  };
};
