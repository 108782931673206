import React from 'react';
import { DropTargetProps } from '../../../models/XBlockProps';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames';

const ACCEPTED_BLOCK_TYPES = [
  EDIE_BLOCK_TYPE.TEXT,
  EDIE_BLOCK_TYPE.ROW,
  EDIE_BLOCK_TYPE.BUTTON,
  EDIE_BLOCK_TYPE.LOOP,
  EDIE_BLOCK_TYPE.CONDITION,
  EDIE_BLOCK_TYPE.VSPACER,
  EDIE_BLOCK_TYPE.IMAGE,
  EDIE_BLOCK_TYPE.RAW,
  EDIE_BLOCK_TYPE.MJML,
  EDIE_BLOCK_TYPE.SUB_TEMPLATE
];

const XConditionDropTarget: React.FC<DropTargetProps> = props => {
  let target = <div className={classNames([
    'xemail-horizontal-drop-target',
    { 'xemail-dragging-can-drop-and-over': props.isDragging && props.canDrop && props.isOver },
    { 'xemail-dragging-can-drop': props.isDragging && props.canDrop && !props.isOver && !props.disabled }
  ])}>
      &nbsp;
  </div>;
  return props.disabled ? target : props.connectDropTarget(target);
};

const columnTarget = {
  drop (props, monitor) {
    props.onDrop({ id: props.containerId }, { type: monitor.getItemType(), metadata: monitor.getItem().resourceTypeMetadata, id: monitor.getItem().itemId  }, props.position);
  }
};

function collect (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    isDragging: ACCEPTED_BLOCK_TYPES.indexOf(monitor.getItemType()) !== -1
  };
}

export default DropTarget(ACCEPTED_BLOCK_TYPES, columnTarget, collect)(XConditionDropTarget);