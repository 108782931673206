import React, { Component } from 'react';
import XMainProperties from './blocks/XMain/XMainProperties';
import TemplateProperties from '../../components/ToolkitPanel/TemplateProperties';
import * as edieActions from '../../../../store/edie/actions';
import * as appActions from '../../../../store/app/actions';
import { connect } from 'react-redux';

class XEmailPropertiesGroup extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return <React.Fragment>
      <XMainProperties t={t} />
      <TemplateProperties t={t} />
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    edie: state.edie,
    templateProperties: state.appReducer.templateProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMainPropertyUpdate: (data) => {
      dispatch(edieActions.updateItem({ id: 'main' }, data));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onMimeHeaderUpdate: (name, value) => {
      dispatch(edieActions.setMimeHeader(name, value));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onPlainTextAutoGenerationFlagUpdate: (flag) => {
      dispatch(edieActions.setPlainTextAutoGenerationFlag(flag));
      dispatch(appActions.setIsTemplateModified(true));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XEmailPropertiesGroup);
