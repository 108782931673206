import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { store } from './store/store';
import AppHeader from './scenes/AppHeader';
import HomePage from './scenes/Pages/HomePage';
import AppContainer from './scenes/AppContainer';
import './styles/index.scss';
import ErrorBoundary from './scenes/generic/ErrorBoundary';
import SamplesPage from './scenes/Pages/SamplesPage';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';
import { unregister } from '../src/registerServiceWorker';
import Authenticated from './scenes/generic/Authenticated';
import { FullStory, NewRelic } from '@cimpress-technology/react-reporting-redux';
import { setProfile } from './store/profiles/actions';
import { ProfileName } from './profiles/profiles';
import { getEventTracker, TrackedRoute } from '@cimpress-technology/react-mixpanel';
import ChangelogPage from './scenes/Pages/ChangelogPage';

// Use this to troubleshoot issues with redux when you think redux state is being directly mutated
// import freezeState from 'redux-freeze-state';

const urlSearchParams = new URLSearchParams(window.location.search);
const newRelicApplicationId = process.env.REACT_APP_NEW_RELIC_APPLICATION_ID;
const newRelicLicenseKey = process.env.REACT_APP_NEW_RELIC_LICENSE_KEY;

const params = Object.fromEntries(urlSearchParams.entries());
switch (params.profile) {
  case 'embedded':
    store.dispatch(setProfile(ProfileName.Embedded));
    break;
}

const eventTracker = getEventTracker('Template Designer');


ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <FullStory fsOptions={{ org: '97CT5', allowLocalhost: false, debug: false }} selector={state => state.authReducer} blacklist={[]} />
      <NewRelic licenseKey={newRelicLicenseKey!} applicationID={newRelicApplicationId!} />
      <BrowserRouter>
        <div id='main-router'>
          <AppHeader />
          <ErrorBoundary>
            <Switch>
              {/* @ts-ignore */}
              <TrackedRoute exact path='/' trackEvent={eventTracker} title="Home" render={(props) => <Authenticated component={HomePage} {...props} />} />
              {/* @ts-ignore */}
              <TrackedRoute exact path='/samples' trackEvent={eventTracker} title="Samples" render={(props) => <Authenticated component={SamplesPage} {...props} />} />
              {/* @ts-ignore */}
              <TrackedRoute exact path='/releases' trackEvent={eventTracker} title="Version history" render={(props) => <Authenticated component={ChangelogPage} {...props} />} />
              {/* @ts-ignore */}
              <TrackedRoute exact path='/samples/:sampleType' trackEvent={eventTracker} title="Samples Example" render={(props) => <Authenticated component={SamplesPage} {...props} />} />
              {/* @ts-ignore */}
              <TrackedRoute exact path='/designer' trackEvent={eventTracker} title="Designer" render={(props) => <Authenticated component={AppContainer} {...props} />} />
              {/* @ts-ignore */}
              <TrackedRoute exact path='/designer/:templateId' trackEvent={eventTracker} title="Design Template" render={(props) => <Authenticated component={AppContainer} {...props} />} />
              <Redirect from='*' to='/' />
            </Switch>
          </ErrorBoundary>
        </div>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
  ,
  document.getElementById('root')
);

unregister();
