import React, { Component } from 'react';
import './SampleTemplates.scss';
import LeftRightBlock from '../../generic/LeftRightBlock';
import samplesConfig from './config.json';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';

import sampleTwoColumns from './images/sampleTwoColumns.svg';
import sampleHtml from './images/sampleHtml.svg';
import sampleCsv from './images/sampleCsv.svg';
import sampleHandlebars from './images/sampleHandlebars.svg';
import sampleHandlebarsMjml from './images/sampleHandlebarsMjml.svg';

interface SampleTemplatesProps extends RouteComponentProps<any>, WithTranslation {
  type: string;
  renderSampleOverride?: Function;
  email: string;
}

interface SampleTemplatesState {
  redirectTemplate: boolean;
  redirectTemplateId: string;
}

class SampleTemplates extends Component<SampleTemplatesProps, SampleTemplatesState> {
  images: any;

  constructor (props: SampleTemplatesProps) {
    super(props);
    this.state = {
      redirectTemplate: false,
      redirectTemplateId: ''
    };

    this.images = {
      'sampleTwoColumns.svg': sampleTwoColumns,
      'sampleHtml.svg': sampleHtml,
      'sampleCsv.svg': sampleCsv,
      'sampleHandlebars.svg': sampleHandlebars,
      'sampleHandlebarsMjml.svg': sampleHandlebarsMjml,
    };
  }

  renderTemplateLink (config, content) {
    let payload = '';
    if (config.payload) {
      payload = 'payload=' + encodeURI(JSON.stringify(Object.assign({}, config.payload, config.type === 'email' ? { to:  this.props.email } : null)));
    }
    return <Link to={config.url ? `${config.url}&${payload}` : `/designer/${config.templateId}?${payload}`} style={{ cursor: 'pointer' }}>
      {content}
    </Link>;
  }

  renderImage (config) {
    return <img alt='' src={this.images[config.image]} width={150} />;
  }

  renderSample (config, renderLink, renderImage) {
    let left = renderLink(config, <div className={'templateSample'}>
      {renderImage(config)}
    </div>);

    let right = <div className={'templateDescription'}>
      <h5><Trans i18nKey={config.title} /></h5>
      <div><Trans i18nKey={config.description} /></div>
      <br />
      {this.renderTemplateLink(config,
        <button className={'btn btn-default'}>
          <Trans i18nKey={'template-wizard.start'}/>
        </button>)}
    </div>;

    return <div className={'templateSampleBlock'}>
      <LeftRightBlock left={left} right={right} />
    </div>;
  }

  render () {
    const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';
    let config = isProduction ? samplesConfig.production : samplesConfig.integration;
 
    let items = config.filter(item => item.type === this.props.type).sort((a, b) => a.order - b.order);
    let renderFunction = this.props.renderSampleOverride || this.renderSample.bind(this);

    let rows: any[] = [];
    for (let i = 0; i < items.length; i += 2) {
      rows.push(
        <div key={i} className={'row'}>
          <div className={'col-md-6'}>
            {renderFunction(items[i], this.renderTemplateLink.bind(this), this.renderImage.bind(this))}
          </div>
          {i + 1 < items.length
            ? <div className={'col-md-6'}>
              {renderFunction(items[i + 1], this.renderTemplateLink.bind(this), this.renderImage.bind(this))}
            </div>
            : null}
        </div>
      );
    }

    return rows;
  }
}

export default withTranslation()(withRouter(SampleTemplates));
