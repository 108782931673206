import React, { Component } from 'react';
import TemplateProperties from '../../components/ToolkitPanel/TemplateProperties';
import RawProperties from './RawProperties';

export default class RawPropertiesGroup extends Component {
  render() {
    const { t } = this.props;
    return <React.Fragment>
      <RawProperties t={t}/>
      <TemplateProperties t={t} />
    </React.Fragment>;
  }
}
