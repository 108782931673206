/*
 * To anyone editing this file:
 * Please follow the convention of putting the namespace in the enums
 * Without this convention we might accidentally run into collisions
 */

export enum AuthActions {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_PROFILE = 'REFRESH_PROFILE',
  LOGIN_LOADING = 'LOGIN_LOADING',
  LOGOUT = 'LOGOUT'
}

export enum PayloadActions {
  SET_EXPANDED_PAYLOAD = 'SET_EXPANDED_PAYLOAD',
  SET_ORIGINAL_PAYLOAD = 'SET_ORIGINAL_PAYLOAD',
  SET_CUSTOM_STEREOTYPE_HEADERS = 'SET_CUSTOM_STEREOTYPE_HEADERS',
}

export enum SettingsActions {
  UPDATE_SINGLE_SETTING = 'UPDATE_SINGLE_SETTING',
  GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR',
  GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS',
  PUT_SETTINGS_REQUEST = 'PUT_SETTINGS_REQUEST',
  PUT_SETTINGS_ERROR = 'PUT_SETTINGS_ERROR',
  PUT_SETTINGS_SUCCESS = 'PUT_SETTINGS_SUCCESS',
}

export enum AppActions {
  INIT_TEMPLATE = 'INIT_TEMPLATE',
  RESET_APP_REDUCER = 'RESET_APP_REDUCER',
  RENAME_LOADED_TEMPLATE = 'RENAME_LOADED_TEMPLATE',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  TEMPLATE_PROPERTY_CHANGED = 'TEMPLATE_PROPERTY_CHANGED',
  TEMPLATE_OBJECT_CHANGED = 'TEMPLATE_OBJECT_CHANGED',
  TEMPLATE_SET_IS_MODIFIED = 'TEMPLATE_SET_IS_MODIFIED'
}

export enum EdieActions {
  INIT_STRUCTURE = 'X_INIT_STRUCTURE',
  RESET_EDIE_REDUCER = 'RESET_EDIE_REDUCER',
  ADD_ITEM = 'X_ADD_ITEM',
  SELECT_ITEM = 'X_SELECT_ITEM',
  REMOVE_ITEM = 'X_REMOVE_ITEM',
  UPDATE_ITEM = 'X_UPDATE_ITEM',
  MOVE_ITEM = 'X_MOVE_ITEM',
  CLONE_ITEM = 'X_CLONE_ITEM',
  SET_MIME_HEADER = 'X_SET_MIME_HEADER',
  SET_PLAIN_TEXT_VERSION = 'X_SET_PLAIN_TEXT_VERSION',
  SET_PLAIN_TEXT_AUTO_GENERATION_FLAG = 'X_SET_PLAIN_TEXT_AUTO_GENERATION_FLAG',

  // for adding n items in one go
  ADD_N_ITEMS = 'ADD_N_ITEMS',

  // for condition blocks 
  ELSE_CONDITION_ADD_ITEM = 'X_ELSE_CONDITION_ADD_ITEM',

  // for XCSV template type
  XCSV_ADD_ITEM = 'XCSV_ADD_ITEM',
  XCSV_UPDATE_METADATA = 'XCSV_UPDATE_METADATA',
  XCSV_UPDATE_BLOCK_METADATA = 'XCSV_UPDATE_BLOCK_METADATA'
}

export enum ProfilesActions {
  SET_PROFILE = 'SET_PROFILE'
}