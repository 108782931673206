
import { CustomizrClient } from 'cimpress-customizr';

const getResourceUrl = (templateId: string) => `${window.location.origin}/designer/${encodeURI(templateId)}`;

const loadPayload = (accessToken: string, templateId: string) => {

  if (!templateId) {
    return Promise.resolve(null);
  }

  const customizrClient = new CustomizrClient({
    resource: getResourceUrl(templateId),
    timeout: 5000
  });

  return customizrClient
    .getSettings(accessToken)
    .then(settings => {
      if (settings && settings.payloads && settings.payloads.length > 0) {
        let p0 = settings.payloads[0].payload || {};
        let jsonHeaders = {};
        let jsonPayload = {};
        if ( ''.constructor === p0.constructor) {
          jsonPayload = JSON.parse(p0);
        }
        let h0 = settings.payloads[0].headers || {};
        if ( ''.constructor === h0.constructor) {
          jsonHeaders = JSON.parse(h0);
        }
        return {
          payload: jsonPayload,
          headers: jsonHeaders
        };
      }
      return null;
    })
    .catch((e) => {
      return null;
    });
    
};

const savePayload = (accessToken: string, templateId: string, payload, headers) => {

  if (!templateId) {
    return Promise.resolve(null);
  }

  const customizrClient = new CustomizrClient({
    resource: getResourceUrl(templateId),
    timeout: 5000
  });

  let p = payload;
  if ({}.constructor === payload.constructor) {
    p = JSON.stringify(p);
  }

  let h = headers || {};
  if ({}.constructor === h.constructor) {
    h = JSON.stringify(h);
  }

  return customizrClient
    .putSettings(accessToken, {
      payloads: [{
        payload: p,
        headers: h === '{}' ? null  : h,
        updatedAt: Date.now()
      }]
    }).catch((e) => {
      return null;
    });
};

export {
  loadPayload,
  savePayload
};
