import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { draggableResource, XResource } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import Icon from './icon_image.svg';

class XImageResource extends Component {
  render () {
    return draggableResource(this.props, <div>
      <XResource resourceName={'Image'} resourceIcon={Icon} />
    </div>);
  }
}

const source = {
  beginDrag () {
    return {
      resourceType: EDIE_BLOCK_TYPE.IMAGE
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.IMAGE, source, collect)(XImageResource);
