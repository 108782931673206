
export const transformToXML = (item) => {
  const containerPath = item.containerPath || '';
  const itemValue = item.value;
  const labelName = item.path.split('.').slice(-1)[0];

  return containerPath
    .split('[0]')
    .filter(e => e)
    .reverse()
    .reduce((value, array) => {
      const cleanedArrayName = array && array.charAt(0) === '.' ? array.replace('.', '') : array;
      return `{{#${cleanedArrayName}}}\n`
        + `<${cleanedArrayName}>\n`
        + `\t${value.replace(/\n/g, '\n\t')}\n`
        + `</${cleanedArrayName}>\n`
        + `{{/${cleanedArrayName}}}`;
    }, `<${labelName}>${itemValue}</${labelName}>`);
};


export const transformToDefaultHandlebars = (item) => {
  const containerPath = item.containerPath || '';
  const itemValue = item.value;

  return containerPath
    .split('[0]')
    .filter(e => e)
    .reverse()
    .reduce((value, array) => {
      const cleanedArrayName = array && array.charAt(0) === '.' ? array.replace('.', '') : array;
      return `{{#${cleanedArrayName}}}${value}{{/${cleanedArrayName}}}`;
    }, itemValue);
};

