import React, { Component } from 'react';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';

import { draggableResource, FullWidthItem } from '../../commonComponents';
import { TerminalCommonProps } from '../../../models/XBlockProps';

interface XImageState {
  showErrorImage: boolean;
}

class XImage extends Component<TerminalCommonProps, XImageState> {
  constructor(props: TerminalCommonProps) {
    super(props);
    this.state = {
      showErrorImage: false
    };
  }

  render() {
    const itemProps = this.props.renderProps.item.properties;
    const { backgroundColor, borderSize, borderColor, width, src, alt, align } = itemProps;
    const blockMetadata = itemProps.metadata || {};

    const containerStyle = {
      backgroundColor,
      textAlign: align || 'center'
    };

    const imageStyle = {
      width: width ? (Number.isSafeInteger(width) ? `${width}px` : width) : '100%',
      border: `${borderSize || '0px'} solid ${borderColor || 'transparent'}`,
    };

    const  defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container'; 

    return <div
      className={`${defaultClass} xemail-image-container`}
      {...this.props.defaultHandlers}>
        
      {draggableResource(this.props,
        <div className={`xemail-block-default-header ${this.props.isElementFocused ? 'xboard-block-highlight' : 'xemail-image-header '}`}>
          <FullWidthItem>{blockMetadata.uiType || 'image'}</FullWidthItem>
        </div>
      )}

      <div className={'xemail-image'} style={containerStyle}>
        <img
          className={this.state.showErrorImage ? 'img-not-available' : ''}
          alt={alt || ''}
          style={imageStyle}
          src={!this.state.showErrorImage ? src : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
          onError={() => { this.setState({ showErrorImage: true }); }} />
      </div>
    </div>;
  }
}

const source = {
  beginDrag(props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.IMAGE,
      itemId: props.id
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.IMAGE, source, collect)(XImage);
