import React, { Component } from 'react';

import XCsvRowDropTarget from './XCsvRowDropTarget';
import { XRowProps } from '../../../models/XBlockProps';
import { renderBlock } from '../../blockRenderer';

class XCsvRow extends Component<XRowProps> {

  renderDropTarget(disabled, position) {
    return <XCsvRowDropTarget
      disabled={disabled}
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  renderBody() {
    const disableDropWithin = this.props.draggingItemId === this.props.id || this.props.renderProps.disableDrop;

    return <>
      {this.renderDropTarget(disableDropWithin, 0)}
      {this.props.elements && this.props.elements.length > 0
        ? (this.props.elements || []).map((element, index) => <React.Fragment key={index}>
          {renderBlock(element,
            Object.assign({},
              this.props.renderProps,
              { disableDrop: disableDropWithin }),
            // Children of a row are not top-level nodes
            false)}
          {this.renderDropTarget(disableDropWithin, index + 1)}
        </React.Fragment>)
        : <div style={{ width: '100%', textAlign: 'center' }}>{this.props.t('blocks.drag_and_drop_here')}</div>}
    </>;
  }

  render () {

    return <div
      className='xcsv-block-default-container xcsv-row-container'>
      <div className='xcsv-row-body' >
        {this.renderBody()}
      </div>
    </div>;
  }
}

export default XCsvRow;
