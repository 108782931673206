import { combineReducers, applyMiddleware, createStore, AnyAction  } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import * as AuthActions from './auth/actions';

import appReducer from './app/reducers';
import authReducer from './auth/reducers';
import settingsReducer from './settings/reducers';
import edie from './edie/reducers';
import payload from './payload/reducers';
import designerProfiles from './profiles/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  settingsReducer,
  edie,
  payload,
  designerProfiles,
});

export type AppState = ReturnType<typeof rootReducer>;

function configureStore() {
  
  const middleWareEnhancer = applyMiddleware(thunkMiddleware);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  /**
   * Bootstrapping store objects
   * store.dispatch is not compatible with redux-thunk, so we need to do this cast
   */
  (store.dispatch as ThunkDispatch<AppState, void, AnyAction>)(AuthActions.initializeAuth());

  return store;
}

export const store = configureStore();
