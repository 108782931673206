import XCsvBoard from './XCsvBoard';
import XCsvResourcesGroup from './XCsvResourcesGroup';
import TemplateRenderer from '../../../../utils/TemplateRenderer';
import { convertFlatToEdieStructure } from '../../../../store/helpers/edieInternalStateHelpers';
import cloneDeep from 'lodash/cloneDeep';
import { BlockProperties } from '../xemail/BlockProperties';

const config = {
  board: XCsvBoard,
  resources: XCsvResourcesGroup,
  properties: BlockProperties,

  exportTemplate: (props) => {
    let edieJson = cloneDeep(props.edie);

    edieJson.structure = convertFlatToEdieStructure(
      props.edie,
      edieJson.flatStructure
    );

    delete edieJson.flatStructure;
    delete edieJson.currentlySelectedElementId;

    return Promise.resolve(JSON.stringify(edieJson));
  },

  templateObjectAdaptor: (templateObject) => {
    return JSON.parse(templateObject);
  },

  createTemplateRendererFunction: (templateContentType, materializationContentType) => {
    return new TemplateRenderer(templateContentType, materializationContentType);
  }
};

export default config;
