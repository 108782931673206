import { Checkbox, Select, TextField } from '@cimpress/react-components';
import React, { useState } from 'react';
import { ColorUtils } from '../../../../../../utils/helpers';
import { convertToOptions, SupportedBorderSizesOptions } from '../../../../../../utils/options';
import CancelButton from '../../../../../generic/buttons/CancelButton';
import ConfirmButton from '../../../../../generic/buttons/ConfirmButton';
import CssPaddingSelector from '../../../../../generic/CssPaddingSelector';
import TextFieldColorPicker from '../../../../../generic/TextFieldColorPicker';
import { ImageUploader, initialImageState, UploadedFile } from '../../../../../Pages/ImageUploader';
import { TwoColumns } from '../../commonComponents';
import EscapableModal from '../../../../components/EscapableModal';
import { getFile } from '@cimpress-technology/react-platform-uploader'; 
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';

interface Properties{
    padding : string;
    borderSize: string,
    borderColor: string,
    backgroundUrl: string;
    backgroundRepeat: string;
    backgroundPositionX:string;
    backgroundPositionY: string;
}

interface BackgroundProps{  
  show: boolean;
  t: Function;
  backgroundProperties:Properties;
  updateItem: Function;
  onClose: Function;
}

export const AdvancedBackgroundProperties : React.FC<BackgroundProps> = props => {
  const [ updates , setUpdates ] = useState( props.backgroundProperties);
  const [ uploadedFile, setUploadedFile ] = useState<UploadedFile>(initialImageState);
  const { accessToken } = useSelector((state:AppState)=> state.authReducer );
  const t  = props.t;

  const convertBase64 = async (uploadId: string) => {
    // @ts-ignore
    const file: any = await getFile(uploadId, 'default', accessToken);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error: any) => {
        reject(error);
      };
    });
  };

  const confirm = async() => {
    if (uploadedFile.id){
      let dataUri = await convertBase64(uploadedFile.id);
      props.updateItem({ ...updates, backgroundUrl: dataUri });
      //@ts-ignore
      setUpdates(state=>({ ...state, backgroundUrl: dataUri }));
    }  
    else { 
      props.updateItem(updates);
    }
    props.onClose();
  };

  const setImageRepeat = () =>{
    const value = updates.backgroundRepeat === 'repeat' ? 'no-repeat' : 'repeat';
    setUpdates(state => ({ ...state, backgroundRepeat: value }));
  };

  const renderModal  = (content) => {
    return <EscapableModal
      show={props.show}
      onRequestHide={props.onClose}
      title={t('blocks.main_properties')}
      closeButton
      footer={
        <div>
          <CancelButton onClick={() => props.onClose()} />
          <ConfirmButton title={t('confirm')} faIcon={'fa-check'} onClick={() => confirm()} />
        </div>
      }>
      {content}
    </EscapableModal>;
  };
    
  return renderModal(
    <React.Fragment>

      <CssPaddingSelector
        t={t}
        label={t('blocks.padding')}
        helpText={t('blocks.padding_help_text')}
        padding={updates.padding}
        onChange={(value) => setUpdates(state => ({ ...state, padding: value  })) }
      />
    
      <TwoColumns
        left={<>
          <Select
            name='borderSize'
            label={t('blocks.border_size')}
            helpText={t('blocks.border_size_help_text')}
            value={convertToOptions(updates.borderSize, SupportedBorderSizesOptions)}
            options={SupportedBorderSizesOptions}
            onChange={(e) => setUpdates(state => ({ ...state, borderSize: e ? e.value : '' }))}
            isClearable={true}
          />
        </>}
        right={<TextFieldColorPicker
          name='borderColor'
          label={t('blocks.border_color')}
          helpText={t('blocks.border_color_help_text')}
          color={ColorUtils.rgb2hex(updates.borderColor)}
          onTextChange={(e) => setUpdates(state =>({ ...state, borderColor: e.target.value }))}
          onColorChange={(hexColor) => {
            setUpdates(state =>({ ...state, borderColor: hexColor }));
          }} />}
      />
      <TwoColumns
        left={<TextField
          name='backgroundPositionX'
          label={t('blocks.background_position_x')}
          value={updates.backgroundPositionX}
          onChange={(e) => setUpdates(state => ({ ...state, backgroundPositionX : e.target.value }))}
        />}
        right={<TextField
          name='backgroundPositionY'
          label={t('blocks.background_position_y')}
          value={updates.backgroundPositionY}
          onChange={(e) => setUpdates(state => ({ ...state, backgroundPositionY : e.target.value }))}
        />}
      />
      <ImageUploader
        selectedImage = {uploadedFile}
        onChange={(file) => setUploadedFile(file) }
        savedImage={updates.backgroundUrl}
        onRemovingImage={() => setUpdates(state => ({ ...state, fileName:'' , backgroundUrl: '' }))
        } />
      <Checkbox
        label={t('blocks.repeat_image')}
        checked={updates.backgroundRepeat === 'repeat'}
        onChange={setImageRepeat}
      />
    </React.Fragment>
  );

}; 