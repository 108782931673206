import React, { Component } from 'react';

import { FullWidthItem } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { XBlockProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';

class XLoopHeader extends Component<XBlockProps, XBlockState> {

  constructor (props: XBlockProps) {
    super(props);
    this.state = {
      showPropertiesModal: false
    };
  }

  render() {
    const item = this.props.renderProps.flatStructure[this.props.id];
    const itemProps = item.properties;

    return this.props.connectDragSource && this.props.connectDragSource(
      <div className={`xemail-block-default-header xemail-loop-header ${this.props.isElementFocused ? 'xboard-block-highlight ' : ''}`}>
        <FullWidthItem>
          <span className={'highlight'}>For each</span>
          {' '}
          <strong>{itemProps.loopPath}</strong>
        </FullWidthItem>
      </div>
    );
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.LOOP,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}

export default DragSource(EDIE_BLOCK_TYPE.LOOP, source, collect)(XLoopHeader);
