import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authActions from '../store/auth/actions';
import { getSettings } from '../store/settings/actions';
import { Tooltip } from '@cimpress/react-components';
import { Header } from '@cimpress-technology/react-platform-header/lib/Header';
import { withTranslation, Trans } from 'react-i18next';
import { LoudspeakerEnvironment, LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';
import { MixpanelDevProjectId, MixpanelIntegration, MixpanelProductionProjectId } from '@cimpress-technology/react-mixpanel';

import { getPreferredMcpLanguages } from 'cimpress-customizr';
import i18n from '../utils/i18n';
import AppSettings from './AppSettings';
import { CommentsDrawerLink } from '@cimpress-technology/react-comments';
import { getDesignerProfile } from '../profiles/profiles';

class AppHeader extends Component {
  componentDidMount() {
    this.props.actions.login();
    if (this.props.accessToken) {
      this.props.getSettings(this.props.accessToken);
      this._fetchDefaultLanguageAndSetIt(this.props.accessToken);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accessToken !== prevProps.accessToken) {
      this.props.getSettings(this.props.accessToken);
      this._fetchDefaultLanguageAndSetIt(this.props.accessToken);
    }
  }

  _fetchDefaultLanguageAndSetIt(accessToken) {
    getPreferredMcpLanguages(accessToken)
      .then(mcpLanguages => {
        const availableLangs = Object.keys(i18n.options.resources);
        const lang = mcpLanguages.filter(lng => availableLangs.includes(lng.iso639_1))[0];
        if (lang) {
          this.locale = lang.iso639_1; // two-letter code
          i18n.changeLanguage(this.locale);
        } else {
          // eslint-disable-next-line no-console
          console.log(`Failed to parse language from Customizr settings: '${mcpLanguages}'`);
        }
      })
      .catch(() => {
        // not empty :)
      });
  }

  render() {
    let { t } = this.props;
    const templateUrl = ((this.props.links || {}).self || {}).href;

    let appTitle = <>
      <Link to={'/'} style={{ textDecoration: 'none' }}>
        <span>
          Template Designer
        </span>
      </Link>
    </>;

    let templateDefined = this.props.templateId && (this.props.location.pathname === `/designer/${this.props.templateId}`);

    const appLinksContent = [
      templateDefined
        ? <Tooltip contents={`${t('template_id')}: ${this.props.templateId}`}>
          <div className={'active-template-name'}>{this.props.templateName || this.props.templateId}</div>
        </Tooltip>
        : undefined,
      templateDefined
        ? <CommentsDrawerLink
          locale={i18n.language}
          resourceUri={templateUrl}
          newestFirst
          editComments
          accessToken={this.props.accessToken} />
        : undefined,
      templateDefined
        ? <div className={'nav-links-separator'}>|</div>
        : undefined,
      <Link to={'/'}><Trans i18nKey={'navigation.templates'} /></Link>,
      <Link
        to={'/releases'}
        rel="noreferrer noopener"
        target={'_blank'}>
        <Trans i18nKey={'navigation.whats_new'} />
      </Link>,
      <a
        href={'https://trdlnk.cimpress.io/contactUs'}
        rel="noreferrer noopener"
        target={'_blank'}><Trans i18nKey={'navigation.contact_us'} /></a>,
      <AppSettings
        accessToken={this.props.accessToken}
        onSettingsUpdated={() => {
          this._fetchDefaultLanguageAndSetIt(this.props.accessToken);
        }}
      />
    ];
    const appLinks = appLinksContent.filter(f => f).map((a, index) => ({
      id: 'appLinksId' + index,
      type: 'default',
      content: a
    }));

    const uiProfile = getDesignerProfile(this.props.designerProfile);
    const hasHeader = uiProfile.header.enabled;

    return <React.Fragment>
      {hasHeader
        ? <Header
          appTitle={appTitle}
          appId={'EmailDesigner'}
          profile={this.props.profile || {}}
          notifications={this.props.accessToken ? [(
            <LoudspeakerIcon
              key={1}
              accessToken={this.props.accessToken}
              channelIds={['email-designer']}
              title={t('loudspeaker.whats_new_in_template_designer')}
              language="en"
              environment={LoudspeakerEnvironment.Production}
            />
          )] : []}
          isLoggedIn={!!this.props.accessToken}
          accessToken={this.props.accessToken}
          onLogInClicked={this.props.login}
          onLogOutClicked={this.props.logout}
          responsive={true}
          useBranding={true}
          appLinks={appLinks} />
        : null }
      <MixpanelIntegration
        accessToken={this.props.accessToken}
        userProfile={this.props.profile}
        applicationName={'Template Designer'} 
        mixpanelProjectId={window.location.hostname.indexOf('staging') < 0 ? MixpanelProductionProjectId : MixpanelDevProjectId}       
      />
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    profile: state.authReducer.profile,
    designerProfile: state.designerProfiles.active,
    accessToken: state.authReducer.accessToken,
    templateId: state.appReducer.templateProperties.templateId,
    templateName: state.appReducer.templateProperties.templateName,
    links: state.appReducer.templateProperties.links
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(authActions, dispatch),
    login: () => { dispatch(authActions.login()); },
    getSettings: (accessToken) => { dispatch(getSettings(accessToken)); },
    logout: () => { dispatch(authActions.logout()); },
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader)));
