import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
// import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
// import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import Placeholder, { PLACEHOLDER_BUTTON } from '../../plugins/ckeditorPlaceholderPlugin/placeholder';
// import DynamicImage, { DYNAMIC_IMAGE_BUTTON } from '../../plugins/ckeditorDynamicImage/dynamicImage';

import { DataURIUploadAdapterPlugin } from '../../plugins/ckeditorImageUploader/dataUrlImageUploader';

import debounce from 'debounce';
import EditPlaceholderModal from '../../../../components/EditPlaceholderModal';
import EditDynamicImageModal from '../../../../components/EditDynamicImageModal';
import { FullWidthItem } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { XTextProps } from '../../../models/XBlockProps';
import { parseHandlebarsExpression, PropertyType } from '../../../../../../utils/helpers';

function generateCimpressMarkersAndPens() {

  let penColors = [
    '#F0563A',
    '#FFA23F',
    '#4f5d75',
    '#C4CDD6',
    '#0088A9',
    '#22917E',
    '#FFFFFF'
  ];

  return penColors.map(color => {
    return {
      color: color,
      title: '',
      class: 'edie-color-p-' + color.substring(1),
      model: 'edieColorP' + color.substring(1),
      type: 'pen'
    };
  });
}

interface XTextState {
  modalOpen: boolean;
  modalDynamicImageOpen: boolean;
  callback: any;
  editingPlaceholder: any;
  editingDynamicImage: any;
  editor?: boolean;
}

class XText extends Component<XTextProps, XTextState> {
  toolbar: null;
  onUpdateItemDebounced: any;
  editor: any;

  constructor(props: XTextProps) {
    super(props);
    this.state = {
      modalOpen: false,
      modalDynamicImageOpen: false,
      callback: null,
      editingPlaceholder: null,
      editingDynamicImage: null,
    };
    this.toolbar = null;
    this.onUpdateItemDebounced = debounce(this.props.renderProps.onUpdateItem, 267);
  }

  onClose = () => {
    this.setState({
      modalOpen: false,
      modalDynamicImageOpen: false,
      callback: null,
      editingPlaceholder: null,
      editingDynamicImage: null,
    });
  }

  onConfirm = (a, b, c) => {
    const callback = this.state.callback;
    this.setState({
      modalOpen: false,
      modalDynamicImageOpen: false,
      callback: null,
      editingPlaceholder: null,
      editingDynamicImage: null,
    }, () => {
      callback(a, b, c);
    });
  }

  renderIfOpen(open, cmp) {
    return open ? cmp : null;
  }

  renderEditor(itemProps) {
    let self = this;

    return <CKEditor
      key={'ck-' + this.props.id}
      editor={DecoupledEditor}
      data={this.props.renderProps.item.properties.content || ''}
      config={{
        plugins: [
          Essentials,
          Alignment,
          FontSize,
          FontFamily,
          //FontColor,
          //FontBackgroundColor,
          Highlight,
          UploadAdapter,
          Autoformat,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          Code,
          BlockQuote,
          CKFinder,
          Paragraph,
          Image,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Link,
          List,
          PasteFromOffice,
          Placeholder,
          RemoveFormat,
          //DynamicImage
        ],
        extraPlugins: [
          DataURIUploadAdapterPlugin,
        ],
        toolbar: {
          items: [
            'fontsize',
            'fontfamily',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'highlight',
            // 'fontColor',
            // 'fontBackgroundColor',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'link',
            'imageUpload',
            '|',
            'removeFormat',            
            '|',
            PLACEHOLDER_BUTTON,
            // DYNAMIC_IMAGE_BUTTON
          ]
        },
        highlight: {
          options: generateCimpressMarkersAndPens()
        },
        image: {
          styles: [
            'inline',
            'side',
            'alignLeft',
            'alignCenter',
            'alignRight'
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:side',
            '|',
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageTextAlternative'
          ]
        },
        customOnPlaceholderEdit: true,
        customOnDynamicImageEdit: true
      }}
      onReady={editor => {
        if (!editor) {
          return;
        }
        self.toolbar = editor.ui.view.toolbar.element;
        self.editor = editor;

        editor.editing.view.document.on('dragover', (evt, data) => {
          // Do not remove these lines otherwise the drop event wont be fired. Read and understand HTML5 DnD API...
          evt.stop();
          data.preventDefault();
          data.stopPropagation();
          // End
        });

        editor.on('onPlaceholderEdit', (evt, placeholder, callback) => {
          self.setState({
            callback,
            editingPlaceholder: placeholder,
            modalOpen: true,
          });
          evt.stop();
        });

        editor.on('onDynamicImageEdit', (evt, dynamicImage, callback) => {
          self.setState({
            callback,
            editingDynamicImage: dynamicImage,
            modalDynamicImageOpen: true
          });
          evt.stop();
        });

        if (self.props.toolbarSpotRef && self.props.toolbarSpotRef.current) {
          while (self.props.toolbarSpotRef.current.hasChildNodes()) {
            self.props.toolbarSpotRef.current.removeChild(self.props.toolbarSpotRef.current.lastChild);
          }
          self.props.toolbarSpotRef.current.appendChild(self.toolbar);
          self.props.toolbarSpotRef.current.setAttribute('disabled', true);
        }
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        if (itemProps.content !== data) {
          self.onUpdateItemDebounced({ id: self.props.id }, { content: data });
        }
      }}
      onBlur={(_editor) => {
        if (self.props.toolbarSpotRef && self.props.toolbarSpotRef.current) {
          self.props.toolbarSpotRef.current.setAttribute('disabled', true);
        }
        self.setState({ editor: false });
      }}
      onFocus={(_editor) => {
        if (self.props.toolbarSpotRef && self.props.toolbarSpotRef.current) {
          while (self.props.toolbarSpotRef.current.hasChildNodes()) {
            self.props.toolbarSpotRef.current.removeChild(self.props.toolbarSpotRef.current.lastChild);
          }
          self.props.toolbarSpotRef.current.appendChild(self.toolbar);
          self.props.toolbarSpotRef.current.removeAttribute('disabled');
        }
      }}
    />;
  }

  renderHeader() {
    return this.props.connectDragSource(
      <div className={`xemail-block-default-header  ${this.props.isElementFocused ? 'xboard-block-highlight ' : 'xemail-text-header'}`}>
        <FullWidthItem>text</FullWidthItem>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const itemProps = this.props.renderProps.item.properties;

    let editor = this.renderEditor(itemProps);

    const  defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';

    return <React.Fragment>

      {this.renderIfOpen(this.state.modalOpen,
        <EditPlaceholderModal
          t={t}
          modalOpen={this.state.modalOpen}
          placeholder={this.state.editingPlaceholder}
          parentLoopPaths={this.props.renderProps.loopPaths || []}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
        />)}

      {this.renderIfOpen(this.state.modalDynamicImageOpen,
        <EditDynamicImageModal
          t={t}
          modalOpen={this.state.modalDynamicImageOpen}
          image={this.state.editingDynamicImage}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
        />)}
      {this.props.connectDragPreview(
        <div className={`${defaultClass} xemail-text-container`} style={{
          backgroundColor: parseHandlebarsExpression(PropertyType.COLOR, itemProps.backgroundColor, this.props.renderProps.providedOriginalPayload)
        }} {...this.props.defaultHandlers}>
          {this.renderHeader()}
          {editor}
        </div>, {
          anchorX: 0,
          anchorY: 0,
          offsetX: 0,
          offsetY: 0
        }
      )}
    </React.Fragment>;
  }
}

const source = {
  beginDrag(props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.TEXT,
      itemId: props.id
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.TEXT, source, collect)(XText);
