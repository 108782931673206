import React from 'react';

import RawBoard from './RawBoard';
import RawPropertiesGroup from './RawPropertiesGroup';
import ActionsBar from '../../components/actionBar/ActionsBar';
import TemplateRenderer from '../../../../utils/TemplateRenderer';

class EmptyClass extends React.Component {
  render() {
    return null;
  }
}

const config = {
  board: RawBoard,
  resources: EmptyClass,
  properties:RawPropertiesGroup,
  actionBar: ActionsBar,

  exportTemplate: (props) => {
    return Promise.resolve(props.templateObject);
  },

  templateObjectAdaptor: (templateObject) => {
    return templateObject;
  },

  createTemplateRendererFunction: (templateContentType, materializationContentType) => {
    return new TemplateRenderer(templateContentType, materializationContentType);
  }
};

export default config;
