import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CoamEnabledActionsBarButton from './base/CoamEnabledActionsBarButton';
import CancelButton from '../../../generic/buttons/CancelButton';
import { StereotypeClient } from '@cimpress-technology/stereotype-client';
import { connect } from 'react-redux';
import * as appActions from '../../../../store/app/actions';
import Loading from '../../../generic/Loading';
import DeleteButton from '../../../generic/buttons/DeleteButton';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import samplesConfig from './../../../Pages/Samples/config';
import EscapableModal from '../EscapableModal';
import { Trans } from 'react-i18next';
import { getDesignerProfile } from '../../../../profiles/profiles';

const BASE_URL =  process.env.REACT_APP_TEMPLATE_DESIGNER_API_URL;
class DeleteTemplateButton extends Component {

  constructor (props) {
    super(props);
    this.state = {
      modalOpen: false,
      completed: false
    };
  }

  forward () {
    this.onClose();
    this.props.history.push({
      pathname: '/',
    });
  }

  deleteTemplate () {
    this.setState({
      deleting: true
    }, () => {
      const client = new StereotypeClient(`Bearer ${this.props.accessToken}`, { baseUrl: BASE_URL });
      client.deleteTemplateById(this.props.templateProperties.templateId)
        .then(() => {
          this.props.setIsTemplateModified(false);
          this.setState({
            completed: true,
            deleting: false
          });
        })
        .catch(e => {
          this.setState({
            errorDeleting: e.response.data,
            deleting: false
          });
        });
    });
  }

  onClose () {
    this.setState({
      modalOpen: false,
      deleting: false,
      completed: false,
      errorDeleting: undefined
    });
  }

  onConfirm () {
    this.deleteTemplate();
  }

  renderErrors () {
    const { t } = this.props;
    let errors = [];

    if (this.state.errorDeleting) {
      errors.push(this.state.errorDeleting.message ? this.state.errorDeleting.message : t('unknown_save_err'));
    }

    if (errors.length === 0) {
      return null;
    }

    return errors.map((e, i) => {
      return <div key={i} className={'text-danger'}>&bull;&nbsp;{e}</div>;
    });
  }

  renderModal () {
    const { t } = this.props;
    let errors = this.renderErrors();
    let confirmDisabled = !!errors || this.state.deleting;

    return <EscapableModal
      status={'danger'}
      show={this.state.modalOpen}
      title={t('delete_template')}
      onRequestHide={() => this.onClose()}
      footer={this.state.completed
        ? <DefaultButton onClick={() => this.forward()} title={t('got_it')} faIcon={'fa-check-circle'} />
        : <div>
          <CancelButton onClick={(e) => this.onClose()} />
          <DeleteButton
            disabled={confirmDisabled}
            onClick={confirmDisabled ? null : (e) => this.onConfirm()} />
        </div>}>

      {this.state.completed
        ? <Trans i18nKey={'templ_deleted'}><strong>{this.props.templateProperties.templateName}</strong></Trans>
        : <Trans i18nKey={'confirm_delete'}><strong>{this.props.templateProperties.templateName}</strong></Trans>}
      <br />
      {this.state.deleting ? <Loading message={t('deleting_ellip')} raw /> : null}

      {errors}

    </EscapableModal>;
  }

  render () {
    const shouldRender = getDesignerProfile(this.props.designerProfile).features.deleteTemplate.enabled;
    if (!shouldRender) {
      return null;
    }
    
    const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';
    let config = isProduction ? samplesConfig.production : samplesConfig.integration;

    // Hack - DO NOT DELETE SAMPLE TEMPLATES
    const forceDisabled = this.props.templateProperties.templateId
      && config.find(t => t.templateId === this.props.templateProperties.templateId);

    const { t } = this.props;
    return <div>
      {this.renderModal()}
      <CoamEnabledActionsBarButton
        t={t}
        operation={'delete'}
        onClick={() => {
          this.setState({ modalOpen: true });
        }}
        disabled={forceDisabled}
        disabledTooltip={t('cannot_delete_not_created')}
        title={t('action-bar.button_delete')}
        className={'actionButtonDelete'}
        faIcon={'fa-trash'} />
    </div>;
  }
}

DeleteTemplateButton.propTypes = {
  accessToken: PropTypes.string
};

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    templateProperties: state.appReducer.templateProperties,
    designerProfile: state.designerProfiles.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsTemplateModified: (name, value) => {
      dispatch(appActions.setIsTemplateModified(name, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteTemplateButton));
