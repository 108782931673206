import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@cimpress/react-components';
import CancelButton from '../../generic/buttons/CancelButton';
import EscapableModal from './EscapableModal';
import ConfirmButton from '../../generic/buttons/ConfirmButton';

class EditAttachmentModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newContent: (props.attachment || {}).content,
      newContentType: (props.attachment || {}).contentType,
      newFilename: (props.attachment || {}).filename,
      newEncoding: (props.attachment || {}).encoding
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.attachment !== prevProps.attachment
      && this.props.attachment
      && ((this.state.newContent !== this.props.attachment.content)
        || (this.state.newContentType !== this.props.attachment.contenttype)
        || (this.state.newFilename !== this.props.attachment.filename)
        || (this.state.newEncoding !== this.props.attachment.encoding))) {
      this.setState({
        newContent: this.props.attachment.content,
        newContentType: this.props.attachment.contentType,
        newFilename: this.props.attachment.filename,
        newEncoding: this.props.attachment.encoding
      });
    }
  }

  render() {

    if (!this.props.modalOpen) {
      return null;
    }

    const disabled = false; // TODO: ?
    const { t } = this.props;

    return <EscapableModal
      status={'info'}
      style={{ width: '80%' }}
      show={this.props.modalOpen}
      title={t('attachments.modal-title')}
      onRequestHide={this.props.onClose}
      footer={<div>
        <CancelButton onClick={this.props.onClose} />
        &nbsp;
        <ConfirmButton
          disabled={disabled}
          disabledTooltip={t('invalid_placeholder')}
          title={t('update')}
          autoFocus
          onClick={() => this.props.onConfirm({
            content: this.state.newContent,
            filename: this.state.newFilename,
            contentType: this.state.newContentType,
            encoding: this.state.newEncoding,
          })} />
      </div>}>

      <TextField
        value={this.state.newFilename}
        label={t('attachments.edit-filename-label')}
        helpText={t('attachments.edit-filename-help-text')}
        onChange={(e) => this.setState({ newFilename: e.target.value })} />

      <TextField
        value={this.state.newContent}
        label={t('attachments.edit-content-label')}
        helpText={t('attachments.edit-content-help-text')}
        onChange={(e) => this.setState({ newContent: e.target.value })} />

      <TextField
        value={this.state.newContentType}
        label={t('attachments.edit-content-type-label')}
        helpText={t('attachments.edit-content-type-help-text')}
        onChange={(e) => this.setState({ newContentType: e.target.value })} />

      <TextField
        value={this.state.newEncoding}
        label={t('attachments.edit-encoding-label')}
        helpText={t('attachments.edit-encoding-help-text')}
        onChange={(e) => this.setState({ newEncoding: e.target.value })} />


    </EscapableModal>;
  }
}

EditAttachmentModal.propTypes = {
  attachment: PropTypes.object,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default EditAttachmentModal;
