import React from 'react';
import { Card } from '@cimpress/react-components';
// @ts-ignore
import { RenderedChangelog } from './Changelog';

const ChangelogPage: React.FC<void> = () => {
  return <div className='container margin-top'>
    <Card className={'text-card'}>
      {RenderedChangelog}
    </Card>
  </div>;
};

export default ChangelogPage;
