import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from './DefaultButton';
import { useTranslation } from 'react-i18next';

let CancelButton = (props) => {
  const { t } = useTranslation();

  return <DefaultButton
    title={props.title || t('cancel')}
    faIcon={'fa-times'}
    onClick={props.onClick}
    tooltip={props.tooltip}
    disabled={props.disabled}
    disabledTooltip={props.disabledTooltip}
    autoFocus={props.autoFocus}
  />;
};

CancelButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  autoFocus: PropTypes.bool,
  status: PropTypes.string
};

export default CancelButton;
