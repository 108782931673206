import cloneDeep from 'lodash/cloneDeep';
import { ProfileName } from '../../profiles/profiles';
import { ProfilesActions } from '../storeActions';
import { ProfilesActionTypes, ProfilesState } from './types';

const defaultSettings: ProfilesState = {
  active: ProfileName.Default
};

const profilesReducer = (state = defaultSettings, action: ProfilesActionTypes): ProfilesState => {
  switch (action.type) {
    case ProfilesActions.SET_PROFILE:
      const updatedState = cloneDeep(state);
      updatedState.active = action.name;
      return Object.assign({}, state, updatedState);
    default:
      return state;
  }
};

export default profilesReducer;
