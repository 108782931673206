import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getStatus } from '../../../../api/puremail.api';
import EmailStatusLegend from '../emailing/EmailStatusLegend';
import CoamEnabledActionsBarButton from './base/CoamEnabledActionsBarButton';
import SendEmailModal from '../emailing/SendEmailModal';
import EmailStatusDetailsModal from '../emailing/EmailStatusDetailsModal';
import { validateEmails } from '../emailing/Emailing';

const POLLING_INTERVAL = 2000;
const NOTIFICATION_SHOWING_TIME = 2000;

class SendEmailButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      detailsOpen: false,
      pendingEmails: []
    };
  }

  componentDidMount () {
    this.mount = true;
    this.initializePoller();
  }

  initializePoller () {
    if (this.props.accessToken) {
      this.pollPendingEmails();
    }
  }

  pollPendingEmails () {
    clearInterval(this.poller);
    this.poller = setInterval(() => {
      let pendingEmails = this.state.pendingEmails.slice();
      const promises = this.getCheckStatusPromises(pendingEmails, 'RUNNING');

      if (this.props.accessToken && promises.length) {
        Promise.all(promises)
          .then(statuses => {
            let pendingEmailsToDiscard = [];
            statuses
              .forEach(s => {
                if (s.status !== 'RUNNING') {
                  const i = pendingEmails.findIndex(p => p.requestId === s.requestId);
                  if (i !== -1) {
                    pendingEmails[i] = Object.assign({}, pendingEmails[i], s);
                    if (s.status === 'SENT') {
                      pendingEmailsToDiscard.push(s.requestId);
                    }
                  }
                }
              });

            if (this.mount) {
              this.setState({ pendingEmails });
              this.discardPendingEmails(pendingEmailsToDiscard, NOTIFICATION_SHOWING_TIME);
            }
          });
      }
    }, POLLING_INTERVAL);
  }

  getCheckStatusPromises (pendingEmails, status) {
    return pendingEmails
      .reduce((ps, p) => {
        return p.status === status ? ps.concat([getStatus(this.props.accessToken, p.requestId)]) : ps;
      }, []);
  }

  /**
   * Removes the pendingEmails from the status and customizer in the delay specified.
   * @param {*} pendingEmailsToDiscard
   * @param {*} delay
   */
  discardPendingEmails (pendingEmailsToDiscard, delay) {
    if (pendingEmailsToDiscard.length) {
      this.discardTimer = setTimeout(() => {
        if (this.mount) {
          this.setState({ pendingEmails: this.state.pendingEmails.filter(p => !pendingEmailsToDiscard.includes(p.requestId)) });
        }
      }, delay);
    }
  }

  componentWillUnmount () {
    clearInterval(this.poller);
    clearTimeout(this.discardTimer);
    this.mount = false;
    this.puremail = null;
  }

  verifyRecipients () {
    const { t } = this.props;
    let errors = [];
    if (!this.props.mimeHeaders.to) {
      errors.push(t('need_a_recipient'));
    }
    if (this.props.mimeHeaders.to && !validateEmails(this.props.mimeHeaders.to)) {
      errors.push(t('invalid_to'));
    }
    if (this.props.mimeHeaders.cc && !validateEmails(this.props.mimeHeaders.cc)) {
      errors.push(t('invalid_cc'));
    }
    if (this.props.mimeHeaders.bcc && !validateEmails(this.props.mimeHeaders.bcc)) {
      errors.push(t('invalid_bcc'));
    }
    return errors;
  }

  openModal () {
    this.setState({ open: true });
  }

  render () {
    const { t } = this.props;
    let buttonDisabled = !this.props.templateProperties.templateId;
    let disabledMessage = t('action-bar.button_send_mail_tooltip_save_required');
    let errors = this.verifyRecipients();
    if (errors.length > 0) {
      buttonDisabled = true;
      disabledMessage = disabledMessage + '; ' + errors.join('; ');
    }

    return <React.Fragment>

      <CoamEnabledActionsBarButton
        t={t}
        operation={'update'}
        disabled={buttonDisabled}
        disableMessage={disabledMessage}
        title={t('action-bar.button_send_mail')}
        faIcon={'fa-paper-plane'}
        onClick={buttonDisabled ? null : () => this.openModal()} />

      <EmailStatusDetailsModal
        t={t}
        open={this.state.detailsOpen}
        onClose={() => this.setState({ detailsOpen: false })}
        pendingEmails={this.state.pendingEmails || []}
        onClear={(requestId, delay) => {

          // null -> all; x -> single; [x,y] -> [x,y]
          let toDiscard = !requestId
            ? this.state.pendingEmails.map(x => x.requestId)
            : Array.isArray(requestId)
              ? requestId
              : [requestId];

          this.discardPendingEmails(toDiscard, delay || 0);
        }} />

      <SendEmailModal
        t={t}
        providedOriginalPayload={this.props.providedOriginalPayload}
        providedExpandedPayload={this.props.providedExpandedPayload}
        templateProperties={this.props.templateProperties}
        accessToken={this.props.accessToken}
        mimeHeaders={this.props.mimeHeaders}
        templateId={this.props.templateProperties.templateId}
        open={this.state.open && !this.state.detailsOpen}
        onSent={(data) => {
          this.setState({ pendingEmails: this.state.pendingEmails.concat(data) });
        }}
        pendingEmails={this.state.pendingEmails}
        onClose={() => this.setState({ open: false })} />

      {!this.state.open && !this.state.detailsOpen
        ? <EmailStatusLegend
          t={t}
          pendingEmails={this.state.pendingEmails}
          onDetailsClick={() => this.setState({ detailsOpen: true })} />
        : null}
    </React.Fragment>;
  }
}

SendEmailButton.propTypes = {};

const mapStateToProps = state => {
  return {
    providedOriginalPayload: state.payload.providedOriginalPayload,
    providedExpandedPayload: state.payload.providedExpandedPayload,
    templateProperties: state.appReducer.templateProperties,
    accessToken: state.authReducer.accessToken,
    mimeHeaders: state.edie.mimeHeaders
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailButton);
