import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@cimpress/react-components';

class ActionsBarButton extends Component {
  renderDisabledOverlay (className) {
    return <div style={{ display: 'flex' }}>
      <span
        className='disabled'
        style={{ display: 'flex', position: 'relative' }}>
        <div className={className}>
          {this.props.customContent}
          <span className='actionDescription'>{this.props.title}</span>
        </div>
      </span>
      <span style={{ position: 'absolute', height: '100%', minWidth: '90px', zIndex: '10000' }}>{' '}</span>
    </div>;
  }

  render () {
    let clickHandler = this.props.onClick;
    let className = 'actionButton';
    if (this.props.disabled) {
      clickHandler = null;
      className += ' disabled';
    }
    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    let button;
    if (this.props.customContent) {
      button = this.props.disabled
        ? this.renderDisabledOverlay(className)
        : <div className={className} onClick={clickHandler}>
          {this.props.customContent}
          <span className='actionDescription'>{this.props.title}</span>
        </div>;
    } else {
      button = <div className={className} onClick={clickHandler}>
        <i className={`fa ${this.props.faIcon} actionIcon`} />
        <span className='actionDescription'>{this.props.title}</span>
      </div>;
    }

    if (this.props.disabled && this.props.disabledTooltip) {
      return <Tooltip contents={this.props.disabledTooltip} style={{ display: 'flex' }}>
        {button}
      </Tooltip>;
    }

    if (this.props.tooltip) {
      return <Tooltip contents={this.props.tooltip} style={{ display: 'flex' }}>
        {button}
      </Tooltip>;
    }

    return button;
  }
}

ActionsBarButton.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  faIcon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  className: PropTypes.string,
  customContent: PropTypes.any
};

export default ActionsBarButton;
