import { PayloadActions } from '../storeActions';

export const setExpandedPayload = (payload: any) => {
  return {
    type: PayloadActions.SET_EXPANDED_PAYLOAD,
    payload
  };
};

export const setOriginalPayload = (payload: any) => {
  return {
    type: PayloadActions.SET_ORIGINAL_PAYLOAD,
    payload
  };
};

export const setCustomStereotypeHeaders = (headers: any) => {
  return {
    type: PayloadActions.SET_CUSTOM_STEREOTYPE_HEADERS,
    headers
  };
};
