import React from 'react';
import { Card } from '@cimpress/react-components';
import { BlockCloneButton, BlockDeleteButton } from './commonComponents';
import { renderBlockSettings } from './blockRenderer';

import ActionsBar from '../../components/actionBar/ActionsBar';

export const BlockProperties = (props) => {
  const { renderProps, edie, templateProperties, config } = props;
  const id = edie.currentlySelectedElementId || 'main';
  let item = renderProps.flatStructure[id];

  const capitalizeFirstLetter = (blockType) => {
    return blockType.charAt(0).toUpperCase() + blockType.slice(1);
  };

  return <div className='xsettings'>
    <ActionsBar
      templateType={templateProperties.templateType}
      createTemplateRenderer={config.createTemplateRendererFunction}
      boardExporterPromise={config.exportTemplate} 
    />
    <div className='properties-header'>
      <div><strong>{capitalizeFirstLetter(item.type)}  {renderProps.t('properties')}</strong></div>
      <div style={{ flexGrow: 1 }}/>
      { id !== 'main' ? <div>
        <BlockCloneButton onClick={() => renderProps.onCloneItem({ id: item.parentId }, {
          id: id,
          type: item.type,
          properties: item.properties
        })} />
        <BlockDeleteButton haveChildren={item.children && item.children.length > 0} onClick={() => renderProps.onRemoveItem({ id: id })}/>
      </div> : '' }
    </div>
    <Card variant="layout" className='properties-card'>
      { renderBlockSettings(id, renderProps)}
    </Card>
  </div>;
};
