import { TextField, Toggle } from '@cimpress/react-components';
import React from 'react';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';

const XConditionProperties:React.FC<XBlockPropertiesProps> = props => {

  const elseChildBlock  = props.renderProps.flatStructure[props.renderProps.flatStructure[props.id].children[1]];
  const hasElseBlock = elseChildBlock ? true : false;

  const { t } = props;
  const properties = props.properties || {};

  let groupBegin = properties.condition;
  let groupEnd = (properties.condition || '').split(' ')[0];

  const onClickToggle = () =>{
    if (!hasElseBlock){
      props.renderProps.onElseConditionAddItem(props, { type: EDIE_BLOCK_TYPE.ELSE_CONDITION }, 1);
    }
    else {
      props.renderProps.onRemoveItem(elseChildBlock);
    }
  };

  return <React.Fragment>
    <TextField
      name='condition'
      label={t('blocks.condition')}
      helpText={t('blocks.condition_help_text')}
      value={properties.condition}
      onChange={(e) => props.onUpdateItem({ id: props.id }, { ...props.properties, condition: e.target.value })} />

    <br />
    {t('blocks.condition-sample-caption')}
    <div className={'condition-demo'}>
      <div className='condition-demo-group-begin'>{'{{#If '}{groupBegin}{'}}'}</div>
      <div className='condition-demo-placeholder'>&nbsp;</div>
      <div className='condition-demo-group-begin'>{'{{/'}{groupEnd}{'}}'}</div>
    </div>
    <br />
    <Toggle on ={hasElseBlock} onText={'Yes'}  offText = {'No'} onClick={onClickToggle} size='sm'/>
    {t('blocks.add_else_block')}
  </React.Fragment>;
};

export default XConditionProperties;
