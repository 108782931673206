import React, { Component } from 'react';
import ActionsBarButton from './base/ActionsBarButton';
import { getDesignerProfile } from '../../../../profiles/profiles';
import { connect } from 'react-redux';

class WhatsNewButton extends Component {
  render () {
    const shouldRender = !getDesignerProfile(this.props.designerProfile).header.enabled;
    if (!shouldRender) {
      return null;
    }

    const { t } = this.props;

    return <div>
      <a href="/releases" rel="noreferrer noopener" target={'_blank'}>
        <ActionsBarButton disabledTooltip={''} title={t('navigation.whats_new')} faIcon={'fa-info'} />
      </a>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    designerProfile: state.designerProfiles.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNewButton);
