import React, { Component } from 'react';

import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { draggableResource, FullWidthItem } from '../../commonComponents';
import classNames from 'classnames';
import { XBlockProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';

class XSubTemplateHeader extends Component<XBlockProps, XBlockState> {
  render() {
    let className = classNames(
      'xemail-block-default-header',
      'xemail-subtemplate-header',
      `${this.props.isElementFocused ? 'xboard-block-highlight' : ''}`
    );

    return draggableResource( this.props, <div className={className}>
      <FullWidthItem>&nbsp;template</FullWidthItem>
    </div>);
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.SUB_TEMPLATE,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}

export default DragSource(EDIE_BLOCK_TYPE.SUB_TEMPLATE, source, collect)(XSubTemplateHeader);
