class DataURIUploadAdapter {
  constructor (loader) {
    // The FileLoader instance to use during the upload. It sounds scary but do not
    // worry — the loader will be passed into the adapter later on in this guide.
    this.loader = loader;
  }

  // Starts the upload process.
  upload () {
    return new Promise((resolve, reject) => {

      let reader = new FileReader();
      reader.onload = function() {
        resolve({
          default: reader.result
        });
      };

      reader.onerror = function () {
        reject('cannot upload');
      };

      this.loader.file.then(f => reader.readAsDataURL( f ));
    });
  }

  // Aborts the upload process.
  abort () {
    // nothing to abort
  }
}


function DataURIUploadAdapterPlugin( editor ) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {

    return new DataURIUploadAdapter( loader );
  };
}

export {
  DataURIUploadAdapter,
  DataURIUploadAdapterPlugin
};
