import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import Split from 'react-split';

import { connect } from 'react-redux';

import { renderBlock, renderBlockSettings } from './blockRenderer';
import config from './config';

import './styles/XCsvBoard.scss';
import * as edieActions from '../../../../store/edie/actions';
import * as appActions from '../../../../store/app/actions';
import { BlockProperties } from './BlockProperties';
import { withTranslation } from 'react-i18next';
import { ProfileName } from '../../../../profiles/profiles';

export interface XCsvBoardProps {
  edie: any;
  designerProfile: ProfileName;
  templateObject: any;
  templateProperties: any;
  t: Function;
  initializeEdie: (templateObject: any) => any;
  resetEdieReducer: () => void;
  resetAppReducer: () => void;
  xCsvUpdateBlockMetaData: (item, data) => any;
  XCsvOnAddOrMoveItem: (parent: any, item: any, position: any) => void;
  onSelectItem: (itemId: any) => void;
  onUpdateItem: (item: any, data: any) => void;
  onRemoveItem: (item: any) => void;
}

export interface XCsvBoardState {
  textContent: any
}

class XCsvBoard extends Component<XCsvBoardProps, XCsvBoardState> {

  toolbarSpotRef: any;

  constructor(props: XCsvBoardProps) {
    super(props);
    this.toolbarSpotRef = React.createRef();
    this.state = {
      textContent: this.props.edie.plainText
    };
  }

  componentWillUnmount() {
    this.props.resetAppReducer();
    this.props.resetEdieReducer();
  }

  render() {

    if (!this.props.edie) {
      this.props.initializeEdie(this.props.templateObject);
      return null;
    }

    let renderProps = {
      t: this.props.t,
      onAddOrMoveItem: this.props.XCsvOnAddOrMoveItem,
      onSelectItem: this.props.onSelectItem,
      onRemoveItem: this.props.onRemoveItem,
      onUpdateBlockMetaData: this.props.xCsvUpdateBlockMetaData,
      onUpdateItem: this.props.onUpdateItem,
      toolbarSpotRef: this.toolbarSpotRef,
      flatStructure: this.props.edie.flatStructure,
      meta: this.props.edie.meta
    };

    return <div style={{ height: '100%' }}>
      <StickyContainer className='boardWrapper' style={{ display: 'flex' }}>
        <Split direction="horizontal" className="board-split" sizes={[100, 0]} gutterSize={8}>
          <div style={{ flexGrow: 1 }}>
            <Sticky relative topOffset={0} bottomOffset={10}>
              {(props) => {
                let style: React.CSSProperties = { zIndex: 1000 };
                if (!props.isSticky) {
                  style.position = 'relative';
                }
                return <div style={Object.assign({}, props.style, style)}>
                  <div ref={this.toolbarSpotRef}>
                    {/*render toolbar */}
                  </div>
                </div>;
              }}
            </Sticky>
            <div className='xCsvBoard' style={{ overflowY: 'scroll' }}>
              <div className='xcsv' onClick={() => renderProps.onSelectItem('main')}>
                {renderBlock(
                  'main',
                  renderProps
                )}
              </div>
            </div>
          </div>
          <BlockProperties 
            renderProps ={renderProps} 
            edie={this.props.edie} 
            templateProperties ={this.props.templateProperties}
            renderBlockSettings={renderBlockSettings} 
            config ={config}/>
        </Split>
      </StickyContainer>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    edie: state.edie,
    templateObject: state.appReducer.templateObject,
    templateProperties: state.appReducer.templateProperties,
    // profile
    designerProfile: state.designerProfiles.active,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    initializeEdie: (templateObject) => {
      dispatch(edieActions.initStructure(templateObject));
    },
    xCsvUpdateBlockMetaData: (item, data) => {
      dispatch(edieActions.xCsvUpdateBlockMetaData(item, data));
      dispatch(appActions.setIsTemplateModified(true));
    },
    XCsvOnAddOrMoveItem: (parent, item, position) => {
      if (item.id) {
        dispatch(edieActions.moveItem(parent, item, position));
      } else {
        dispatch(edieActions.xCsvAddItem(parent, item, position));
      }
      dispatch(appActions.setIsTemplateModified(true));
    },
    onSelectItem: (itemId) => {
      dispatch(edieActions.selectItem(itemId));
    },
    onUpdateItem: (item, data) => {
      dispatch(edieActions.updateItem(item, data));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onRemoveItem: (item) => {
      dispatch(edieActions.removeItem(item));
      dispatch(edieActions.selectItem('main'));
      dispatch(appActions.setIsTemplateModified(true));
    },
    resetAppReducer: () => {
      dispatch(appActions.resetAppReducer());
    },
    resetEdieReducer: () => {
      dispatch(edieActions.resetEdieReducer());
    },
  };
};

export default withTranslation()<any>(connect(mapStateToProps, mapDispatchToProps)(XCsvBoard));
