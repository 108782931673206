import React, { Component } from 'react';
import TemplateProperties from '../../components/ToolkitPanel/TemplateProperties';
import * as edieActions from '../../../../store/edie/actions';
import * as appActions from '../../../../store/app/actions';
import { connect } from 'react-redux';
import { Select } from '@cimpress/react-components';
import LeftRightBlock from '../../../generic/LeftRightBlock';
import { Checkbox, Tooltip } from '@cimpress/react-components';

class XCsvPropertiesGroup extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const meta = this.props.edie.meta || {
      xCsvSeparator: ',',
      showHeaders: true
    };

    const options = [
      { value: ',', label: ',' },
      { value: ';', label: ';' }
    ];

    return <React.Fragment>
      <TemplateProperties t={t} />
      <Select
        label={t('toolkit.separator')}
        value={options.find(o => o.value === meta.xCsvSeparator) || options[0]}
        options={options}
        clearable={false}
        onChange={(e) => this.props.xCsvUpdateMetaData({ xCsvSeparator: e.value })}
        tether
      />
      <LeftRightBlock
        left={<Checkbox
          label={t('toolkit.showHeaders')}
          checked={meta.showHeaders}
          onChange={() => this.props.xCsvUpdateMetaData( { showHeaders: !meta.showHeaders })} />}
        right={<Tooltip
          contents={t('toolkit.showHeadersHelp')}
          direction={'right'}>
          <i className={'fa fa-2x fa-question-circle context-button-icon'} />
        </Tooltip>} />
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    edie: state.edie,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    xCsvUpdateMetaData: (xCsvMetaData) => {
      dispatch(edieActions.xCsvUpdateMetaData(xCsvMetaData));
      dispatch(appActions.setIsTemplateModified(true));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XCsvPropertiesGroup);
