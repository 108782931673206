import React from 'react';
import { XConditionProps } from '../../../models/XBlockProps';
import { renderBlock } from '../../blockRenderer';
import XConditionDropTarget from './XConditionDropTarget';
import XConditionHeader from './XConditionHeader';


const XCondition: React.FC<XConditionProps> = props => {

  const { t } = props; 
  const renderIfDropTarget = (disabled, position) => {
    return <XConditionDropTarget
      isTopLevelNode={props.isTopLevelNode}
      disabled={disabled}
      containerId={props.elements[0]}
      onDrop={props.renderProps.onAddOrMoveItem}
      position={position} />;
  };

  const renderElseDropTarget = (disabled, position) => {
    return <XConditionDropTarget
      isTopLevelNode={props.isTopLevelNode}
      disabled={disabled}
      containerId={props.elements[1]}
      onDrop={props.renderProps.onAddOrMoveItem}
      position={position} />;
  };

  const renderBody = () =>{
    const disableDropWithin = props.renderProps.disableDrop;
    const itemProps = props.renderProps.flatStructure[props.id].properties || {};
    const ifElements = props.renderProps.flatStructure[props.elements[0]].children;
    const elseElements = props.renderProps.flatStructure[props.elements?.[1]]?.children;

    const renderElseBlock = () => {
      if (elseElements !== undefined){
        return (<>
          <hr style={{ marginBottom: '3px', marginTop: '3px' }}/>
          <span className={'highlight'} style={{ fontSize : '10px' }}>{t('blocks.else')}</span>
          {renderElseDropTarget(disableDropWithin, 0)}
          {(elseElements).map((element, index) => <React.Fragment key={index}>
            {renderBlock(
              element,
              Object.assign({}, props.renderProps, {
                disableDrop: false,
                condition: (props.renderProps.condition || []).concat(itemProps.condition)
              }),
              props.isTopLevelNode
            )}
            {renderElseDropTarget(disableDropWithin, index + 1)}
          </React.Fragment>
          )}
        </>);
      }     
    };

    return <React.Fragment>
      {renderIfDropTarget(disableDropWithin, 0)}
      {(ifElements || []).map((element, index) => <React.Fragment key={index}>
        {renderBlock(
          element,
          Object.assign({}, props.renderProps, {
            disableDrop: false,
            condition: (props.renderProps.condition || []).concat(itemProps.condition)
          }),
          props.isTopLevelNode
        )}
        {renderIfDropTarget(disableDropWithin, index + 1)}
      </React.Fragment>
      )}      
      {renderElseBlock()}
    </React.Fragment>;
  };

  const  defaultClass = props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';
  
  return <div
    className={`${defaultClass} xemail-condition-container`}
    {...props.defaultHandlers}>
    <XConditionHeader {...props} />
    {renderBody()}
  </div>;
}; 

export default XCondition;