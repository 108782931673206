import TemplateRenderer from '../../../../utils/TemplateRenderer';
import contentTypeParser from 'content-type';
import { edie2hbsmjml } from '@cimpress-technology/edie-processors';
import { downloadablePreviewContentType } from '../../../../constants';
// TODO:
// TODO: Change this to use local version of @cimpress-technology/edie-processors
// TODO: This way a template can be previewed without the need to update Stereotype service
// TODO: Keep in mind that sending emails will still call prod PureMail which calls prod Stereotype so
// TODO: the preview will likely be different than the email in this case.
// TODO:
const USE_LOCAL_EDIE_PROCESSORS_FOR_PREVIEW = false;

export default class XEmailTemplateRenderer extends TemplateRenderer {

  preRender (template) {
    if ( USE_LOCAL_EDIE_PROCESSORS_FOR_PREVIEW ) {
      let mjmkl = edie2hbsmjml(JSON.parse(template));
      return mjmkl;
    }

    return template;
  }

  preProcessContentType () {
    if (downloadablePreviewContentType.some(ct => this.templateContentType.includes(ct))){
      return this.templateContentType;
    }
    let parsed = contentTypeParser.parse(this.templateContentType);
    let ct = {
      type: parsed.type,
      parameters: {
        processors: 'edie,handlebars,mjml'
      }
    };

    // TODO: This is only for local development
    if ( USE_LOCAL_EDIE_PROCESSORS_FOR_PREVIEW ) {
      ct = {
        type: 'text/handlebars',
        parameters: {
          postProcessors: 'mjml'
        }
      };
    }

    return contentTypeParser.format(ct);
  }
}
