import React from 'react';
import PropTypes from 'prop-types';

import { shapes } from '@cimpress/react-components';
import * as Colors from '@cimpress/react-components/lib/colors';
import { withTranslation, Trans } from 'react-i18next';

const { Robot } = shapes;

class ErrorInfo extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  renderDetails (e) {
    if (typeof e === 'string') {
      return e;
    }

    const { t } = this.props;
    if (e.message) {
      return <div className='trdlnk-pointer' onClick={() => this.setState({ showStack: !this.state.showStack })}>
        <Trans>message</Trans>: {e.message}
        {e.status
          ? <span>{' '}({t('status')}: {e.status})</span>
          : null}
        {this.props.minimal
          ? null
          : <br />}
        {this.state.showStack
          ? <pre><code>{e.stack}</code></pre>
          : null}
      </div>;
    }

    return JSON.stringify(e);
  }

  renderHeader () {
    const { t } = this.props;
    const title = this.props.title || t('err_info_title');
    if (this.props.minimal) {
      return <h5 className={'text-danger'}><i className={'fa fa-exclamation-triangle'} />&nbsp;{title}</h5>;
    }

    return <h3 className={'text-danger'}>{title}</h3>;
  }

  renderDetailsBlock () {
    if (!this.props.details) {
      return null;
    }

    if (this.props.minimal) {
      return <div>{this.renderDetails(this.props.details)}</div>;
    }

    return <div>
      <br />
      <div style={{ color: Colors.shale }}>
        <h5>{this.props.t('errors.additional_information')}</h5>
        <div>{this.renderDetails(this.props.details)}</div>
      </div>
    </div>;
  }

  renderErrorOnly () {
    const { t } = this.props;
    const message = this.props.message || t('err_info_message');
    return <div>
      {this.renderHeader()}
      <div>{message}</div>
      <div><Trans i18nKey={'reach_out'}><a href='https://trdlnk.cimpress.io/contactUs' rel="noreferrer noopener" target={'_blank'}>__not__used__</a></Trans></div>
      <br />
      {this.props.onRetry
        ? (<div>
          <button className={'btn btn-sm btn-outline-secondary'} onClick={() => this.props.onRetry()}>
            <i className='fa fa-refresh' aria-hidden='true' />&nbsp;<Trans>retry</Trans>
          </button>
        </div>)
        : null}
      <br />
      {this.renderDetailsBlock()}
    </div>;
  }

  render () {

    if (this.props.minimal) {
      return this.renderErrorOnly();
    }

    return (
      <div className={'card'}>
        <div className={'card-block'}>
          <div className={'row'}>
            <div className={'col-md-9'}>
              {this.renderErrorOnly()}
            </div>
            <div className={'col-md-3'}>
              <Robot status='warning' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ErrorInfo.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  minimal: PropTypes.bool,
  details: PropTypes.object,
  onRetry: PropTypes.func
};

export default withTranslation()(ErrorInfo);
