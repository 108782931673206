import React from 'react';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';

import XText from './blocks/XText/XText';
import XRow from './blocks/XRow/XRow';
import XColumn from './blocks/XColumn/XColumn';
import XMain from './blocks/XMain/XMain';
import XButton from './blocks/XButton/XButton';
import XLoop from './blocks/XLoop/XLoop';
import XVerticalSpacer from './blocks/XVerticalSpacer/XVerticalSpacer';
import XImage from './blocks/XImage/XImage';
import XRawHtml from './blocks/XRawHtml/XRawHtml';
import XRawMjml from './blocks/XRawMjml/XRawMjml';
import XSubTemplate from './blocks/XSubTemplate/XSubTemplate';
import XTextProperties from './blocks/XText/XTextProperties';
import XButtonProperties from './blocks/XButton/XButtonProperties';
import XColumnProperties from './blocks/XColumn/XColumnProperties';
import XLoopProperties from './blocks/XLoop/XLoopProperties';
import XConditionProperties from './blocks/XCondition/XConditionProperties';
import XRowProperties from './blocks/XRow/XRowProperties';
import XVerticalSpacerProperties from './blocks/XVerticalSpacer/XVerticalSpacerProperties';
import XImageProperties from './blocks/XImage/XImageProperties';
import XRawHtmlProperties from './blocks/XRawHtml/XRawHtmlProperties';
import XRawMjmlProperties from './blocks/XRawMjml/XRawMjmlProperties';
import XSubTemplateProperties from './blocks/XSubTemplate/XSubTemplateProperties';
import XEmailPropertiesGroup from './XEmailPropertiesGroup';
import XCondition from './blocks/XCondition/XCondition';


const isElementFocused = (currentlySelectedElementId, itemId) => {
  if (currentlySelectedElementId === itemId){
    return true;
  }
  return false;
};

const renderBlockSettings = (itemId, renderProps) => {
  if (!itemId || !renderProps.flatStructure[itemId]) {
    return <p>{renderProps.t('blocks.click_to_display_and_modify')}</p>;
  }

  let itemProperties = (renderProps.flatStructure[itemId] || {});

  let commonProps = {
    t: renderProps.t,
    id: itemId,
    renderProps,
    onUpdateItem: renderProps.onUpdateItem,
    isElementFocused: isElementFocused(renderProps.currentlySelectedElementId, itemId),
    properties: itemProperties.properties
  };

  const getPropsForConditionBlock = () => {
    const firstChild = renderProps.flatStructure[renderProps.flatStructure[itemId].children[0]];
    if (!firstChild){
      const parentId = renderProps.flatStructure[itemId].parentId;
      itemProperties = renderProps.flatStructure[parentId];
      return {
        t: renderProps.t,
        id: parentId,
        renderProps,
        onUpdateItem: renderProps.onUpdateItem,
        isElementFocused: isElementFocused(renderProps.currentlySelectedElementId, itemId),
        properties: itemProperties.properties
      };
    }
    return commonProps;
  };

  switch (itemProperties.type) {
    case EDIE_BLOCK_TYPE.TEXT:
      return <XTextProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.BUTTON:
      return <XButtonProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.COLUMN:
      return <XColumnProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.LOOP:
      return <XLoopProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.CONDITION:
      return <XConditionProperties {...getPropsForConditionBlock()} />;

    case EDIE_BLOCK_TYPE.ROW:
      return <XRowProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.VSPACER:
      return <XVerticalSpacerProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.IMAGE:
      return <XImageProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.RAW:
      return <XRawHtmlProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.MJML:
      return <XRawMjmlProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.SUB_TEMPLATE:
      return <XSubTemplateProperties {...commonProps} />;

    default:
      return <XEmailPropertiesGroup t={renderProps.t} />;
  }
};

const renderBlock = (itemId, renderProps, isTopLevelNode) => {
  if (!itemId) {
    return <div>no item</div>;
  }

  let itemProperties = itemId === 'main'
    ? { type: EDIE_BLOCK_TYPE.MAIN }
    : (renderProps.flatStructure[itemId] || {});

  let defaultHandlers = {
    onClick: (e: Event) => {
      e.stopPropagation();
      renderProps.onSelectItem(itemId);
    }
  };

  let commonProps = {
    t: renderProps.t,
    id: itemId,
    elements: renderProps.flatStructure[itemId].children,
    isTopLevelNode,
    renderProps,
    isElementFocused: isElementFocused(renderProps.currentlySelectedElementId, itemId),
    defaultHandlers
  };

  let terminalBlockProps = {
    ...commonProps,
    renderProps: {
      ...renderProps,
      item: renderProps.flatStructure[itemId],
      flatStructure: undefined,
      toolbarSpotRef: undefined,
      currentlySelectedElementId: undefined
    },
    elements: undefined,
    renderBlock: undefined,
  };

  switch (itemProperties.type) {
    case EDIE_BLOCK_TYPE.MAIN:
      return <XMain {...commonProps} id={'main'} isTopLevelNode />;

    case EDIE_BLOCK_TYPE.TEXT:
      return <XText {...terminalBlockProps} toolbarSpotRef={renderProps.toolbarSpotRef} />;

    case EDIE_BLOCK_TYPE.BUTTON:
      return <XButton {...terminalBlockProps} />;

    case EDIE_BLOCK_TYPE.COLUMN:
      return <XColumn {...commonProps} />;

    case EDIE_BLOCK_TYPE.LOOP:
      return <XLoop {...commonProps} />;

    case EDIE_BLOCK_TYPE.ROW:
      return <XRow {...commonProps} />;

    case EDIE_BLOCK_TYPE.VSPACER:
      return <XVerticalSpacer {...terminalBlockProps} />;
        
    case EDIE_BLOCK_TYPE.CONDITION:
      return <XCondition {...commonProps} />;  

    case EDIE_BLOCK_TYPE.IMAGE:
      return <XImage {...terminalBlockProps} />;

    case EDIE_BLOCK_TYPE.RAW:
      return <XRawHtml {...terminalBlockProps} />;

    case EDIE_BLOCK_TYPE.MJML:
      return <XRawMjml {...terminalBlockProps} />;

    case EDIE_BLOCK_TYPE.SUB_TEMPLATE:
      return <XSubTemplate {...terminalBlockProps} />;

    default:
      return <div>Item of type {renderProps.flatStructure[itemId].type} cannot be rendered.</div>;
  }
};

export {
  renderBlock,
  renderBlockSettings
};
