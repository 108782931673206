import contentTypeParser from 'content-type';
import cloneDeep from 'lodash/cloneDeep';
import { LineEndings } from '../../utils/helpers';
import { AppActions } from '../storeActions';
import { AppActionTypes, AppReducerState } from './types';

const initialAppState: AppReducerState = {
  loading: false,
  error: null,
  loadingMessage: '',
  templateProperties: {
    isPublic: false
  },
  isTemplateModified: false,
};

const appReducer = (state = initialAppState, action: AppActionTypes): AppReducerState => {
  switch (action.type) {

    case AppActions.INIT_TEMPLATE:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        templateProperties: action.properties.templateProperties,
        templateObject: action.properties.templateObject,
        isTemplateModified: false,
      });

    case AppActions.RENAME_LOADED_TEMPLATE:
      return Object.assign({}, state, {
        templateProperties: Object.assign({}, state.templateProperties, {
          templateId: action.templateId,
          templateName: action.templateName,
          isPublic: action.isPublic,
          isTemplateModified: false,
        })
      });

    case AppActions.SET_LOADING:
      return Object.assign({}, initialAppState, {
        loading: true,
        loadingMessage: action.loadingMessage
      });

    case AppActions.SET_ERROR:
      return Object.assign({}, state, {
        error: action.error
      });

    case AppActions.TEMPLATE_PROPERTY_CHANGED:
      let newTemplateProperties = cloneDeep(state.templateProperties);
      const originalParsedContentType = contentTypeParser.parse(newTemplateProperties.contentType);

      switch (action.propertyName.toLowerCase()) {

        case 'contenttype':
          const newlyParsed = contentTypeParser.parse(action.propertyValue);
          originalParsedContentType.type = newlyParsed.type;
          originalParsedContentType.parameters = newlyParsed.parameters;
          newTemplateProperties.contentType = contentTypeParser.format(originalParsedContentType);
          break;

        case 'lineending':
          // Just change the content
          return Object.assign({}, state, {
            templateObject: LineEndings.convertLineEndingTo(state.templateObject, action.propertyValue)
          });

        default:
          newTemplateProperties[action.propertyName] = action.propertyValue;
          break;
      }
      return Object.assign({}, state, { templateProperties: newTemplateProperties });

    case AppActions.TEMPLATE_OBJECT_CHANGED:
      return Object.assign({}, state, { templateObject: action.content });

    case AppActions.TEMPLATE_SET_IS_MODIFIED:
      return Object.assign({}, state, { isTemplateModified: action.isModified  });

    case AppActions.RESET_APP_REDUCER:
      return initialAppState;
    default:
      return state;
  }
};

export default appReducer;
