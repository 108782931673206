import cloneDeep from 'lodash/cloneDeep';
import { SettingsActions } from '../storeActions';
import { SettingsActionTypes, SettingsState } from './types';

const defaultSettings: SettingsState = {
  settings: {
    rawEditor: {
      mode: 'handlebars',
      theme: 'monokai',
      fontSize: '12'
    },
  },
};

const settingsReducer = (state = defaultSettings, action: SettingsActionTypes): SettingsState => {
  switch (action.type) {
    case SettingsActions.UPDATE_SINGLE_SETTING:
      const updatedSettings = cloneDeep(state.settings);
      updatedSettings[action.category][action.settingName] = action.settingValue;
      return Object.assign({}, state, {
        settings: updatedSettings
      });
    case SettingsActions.GET_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        get: {
          isFetching: true,
          error: undefined,
          data: undefined,
        }
      });
    case SettingsActions.GET_SETTINGS_ERROR:
      return Object.assign({}, state, {
        get: {
          isFetching: false,
          error: action.error,
        }
      });
    case SettingsActions.GET_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        get: {
          isFetching: false,
          error: undefined,
          data: action.data
        },
        settings: Object.assign({}, defaultSettings.settings, action.data)
      });
    case SettingsActions.PUT_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        put: {
          isFetching: true,
          error: undefined,
          data: undefined,
        }
      });
    case SettingsActions.PUT_SETTINGS_ERROR:
      return Object.assign({}, state, {
        put: {
          isFetching: false,
          error: action.error,
        }
      });
    case SettingsActions.PUT_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        put: {
          isFetching: false,
          error: undefined,
          data: action.data
        },
        settings: Object.assign({}, defaultSettings.settings, action.data)
      });
    default:
      return state;
  }
};

export default settingsReducer;
