import React, { Component } from 'react';

import { draggableResource, FullWidthVerticalCenter } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { TerminalCommonProps } from '../../../models/XBlockProps';
import { XBlockStateWithContent } from '../../../models/XBlockState';

class XRawMjml extends Component<TerminalCommonProps, XBlockStateWithContent> {

  constructor (props: TerminalCommonProps) {
    super(props);
    let itemProps = this.props.renderProps.item.properties;
    this.state = {
      content: itemProps.content,
    };
  }

  renderHeader (item) {
    const itemProps = item.properties;
    return draggableResource( this.props,
      <div className={`xemail-block-default-header  ${this.props.isElementFocused ? 'xboard-block-highlight' : 'xemail-rawmjml-header'}`} style={{ backgroundColor: itemProps.backgroundColor || 'transparent' }}>
        <FullWidthVerticalCenter>
          <pre>
            <code>{itemProps.content || '<mj-text/>'}</code>
          </pre>
        </FullWidthVerticalCenter>
      </div>
    );
  }

  render () {
    const  defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';

    return <div
      className={`${defaultClass} xemail-rawmjml-container`}
      {...this.props.defaultHandlers}>
      {this.renderHeader(this.props.renderProps.item)}
    </div>;
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.MJML,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.RAW, source, collect)(XRawMjml);
