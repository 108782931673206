import React from 'react';
import { Card } from '@cimpress/react-components';
import { BlockDeleteButton } from './commonComponents';
import config from './config';
import { renderBlockSettings } from './blockRenderer';
import ActionBar from '../../components/actionBar/ActionsBar';

export const BlockProperties = (props) => {
  const { renderProps, edie, templateProperties } = props;
  const id = edie.currentlySelectedElementId || 'main';
  let item = renderProps.flatStructure[id];

  const capitalizeFirstLetter = (blockType) => {
    return blockType.charAt(0).toUpperCase() + blockType.slice(1);
  };

  return <div className='xsettings'>
    <ActionBar
      templateType={templateProperties.templateType}
      createTemplateRenderer={config.createTemplateRendererFunction}
      boardExporterPromise={config.exportTemplate}
    />
    <div className='properties-header'>
      <div><strong>{capitalizeFirstLetter(item.type)} { renderProps.t('properties')}</strong></div>
      <div style={{ flexGrow: 1 }}/>
      { item.type === 'column' ?  <div>
        <BlockDeleteButton onClick={() => renderProps.onRemoveItem({ id })}/>
      </div> : '' }
    </div>
    <Card variant="layout" className='properties-card'>
      { renderBlockSettings(id, renderProps)}
    </Card>
  </div>;
};
