import React, { Component } from 'react';
import { Select } from '@cimpress/react-components';

import { ColorUtils } from '../../../../../../utils/helpers';
import TextFieldColorPicker from '../../../../../generic/TextFieldColorPicker';
import CssPaddingSelector from '../../../../../generic/CssPaddingSelector';
import { convertToOptions, SupportedBorderSizesOptions } from '../../../../../../utils/options';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { XBlockPropertiesState } from '../../../models/XBlockState';
import { DEBOUNCE_TIME_INTERVAL } from '../../../../../../constants';
import debounce from 'debounce';

class XRowProperties extends Component<XBlockPropertiesProps, XBlockPropertiesState> {
  updateProperties:Function;

  constructor(props: XBlockPropertiesProps) {
    super(props);
    this.state = {
      updates: props.properties
    };
    this.updateProperties = debounce(this.confirm,DEBOUNCE_TIME_INTERVAL);
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
    if (JSON.stringify(prevProps.properties) !== JSON.stringify(this.props.properties)) {
      this.setState({
        updates: this.props.properties
      });
    }
    if (JSON.stringify(prevState.updates) !== JSON.stringify(this.state.updates) && prevProps.id === this.props.id){
      this.updateProperties();
    }
    if (prevProps.id !== this.props.id){
      this.props.onUpdateItem({ id: prevProps.id }, this.state.updates);
    }
  }

  confirm() {
    this.props.onUpdateItem({ id: this.props.id }, this.state.updates);
  }

  render() {
    const { t } = this.props;
    const props = this.state.updates || this.props.properties || {};

    return <React.Fragment>
      <CssPaddingSelector
        t={t}
        label={t('blocks.padding')}
        helpText={t('blocks.padding_help_text')}
        padding={props.padding}
        onChange={(value) => this.setState({ updates: Object.assign({}, this.state.updates, { padding: value }) })}
      />

      <TextFieldColorPicker
        name='backgroundColor'
        label={t('blocks.background_color')}
        helpText={t('blocks.background_color_help_text')}
        color={ColorUtils.rgb2hex(props.backgroundColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: hexColor }) });
        }} />
   
      <Select
        name='borderSize'
        label={t('blocks.border_size')}
        helpText={t('blocks.border_size_help_text')}
        value={convertToOptions(props.borderSize, SupportedBorderSizesOptions)}
        options={SupportedBorderSizesOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { borderSize: e ? e.value : null }) })}
        isClearable={true}
      />
      <TextFieldColorPicker
        name='borderColor'
        label={t('blocks.border_color')}
        helpText={t('blocks.border_color_help_text')}
        color={ColorUtils.rgb2hex(props.borderColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { borderColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { borderColor: hexColor }) });
        }}
      />
    </React.Fragment>;
  }
}

export default XRowProperties;
