import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getShortUrl } from '../../../api/cimlink.api';
import { Trans } from 'react-i18next';
import { colors, Tooltip } from '@cimpress/react-components';
import { IconEmailSend, IconCopy, IconAlertTriangle } from '@cimpress-technology/react-streamline-icons';

import './SharingOptions.css';
import CopyToClipboard from './CopyToClipboard';

class SharingOptions extends Component {
  constructor (props) {
    super(props);
    this.state = {
      templateDesignerUrlShortened: this.props.templateDesignerUrl
    };
  }

  componentDidMount () {
    this.mount = true;
  }

  UNSAFE_componentWillMount () {
    this.shortenLink();
  }

  componentWillUnmount () {
    this.mount = false;
  }

  shortenLink () {
    getShortUrl( this.props.accessToken, this.props.templateDesignerUrl)
      .then(shortUrl => {
        if (this.mount) {
          this.setState({
            templateDesignerUrlShortened: shortUrl
          });
        }
      });
  }

  render () {
    const { t } = this.props;
    return (<div>
      <h5>
        <Trans i18nKey={'access.share_heading'}/>
        &nbsp;
        <a
          className='ux-one-half-icon'
          title={t('send_email')}
          href={`mailto:?subject=${encodeURIComponent(
            t('see_this_template'))}&body=${encodeURIComponent(t('see_this_template_body') + '\n\n'
            + this.state.templateDesignerUrlShortened)}`}>
          <Tooltip contents={t('send_email')}>
            <IconEmailSend size={'lg'}/>
          </Tooltip>
        </a>
        &nbsp;
        &nbsp;
        <CopyToClipboard toCopy={this.state.templateDesignerUrlShortened} className='ux-one-half-icon'>
          <Tooltip contents={t('copy_link')}>
            <IconCopy size={'lg'} />
          </Tooltip>
        </CopyToClipboard>
      </h5>
      <span className={'ux-one-half-icon'}>
        <IconAlertTriangle color={colors.persimmon.base} />&nbsp;
      </span>
      <Trans i18nKey={'grant_permission_to_intended_users'} />
      <hr/>
    </div>);
  }
}

SharingOptions.propTypes = {
  templateDesignerUrl: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired
};

export default SharingOptions;
