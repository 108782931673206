import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JSONTree from 'react-json-tree';
import Highlight from 'react-highlighter';
import { TextField } from '@cimpress/react-components';
import { withTranslation } from 'react-i18next';

class JsonTree extends Component {
  constructor (props) {
    super(props);

    this.state = {
      search: '',
      filteredTree: this.props.providedExpandedPayload,
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.providedExpandedPayload !== this.props.providedExpandedPayload) {
      this.setState({
        filteredTree: this.props.providedExpandedPayload,
      });
    }
  }

  filterJsonTree() {
    if (this.state.search.length < 3) {
      this.setState({
        filteredTree: this.props.providedExpandedPayload,
      });
      return;
    }

    let f = this._filterJsonTree(this.props.providedExpandedPayload);
    this.setState({
      filteredTree: f,
    });
  }

  __shouldExpand(key, data, level) {
    let ks = key.slice().join('.');
    if (this.__mem[ks] !== undefined) {
      return this.__mem[ks];
    }

    if (key.length === 0 ) {
      return true;
    }

    if (this.state.search.length < 3) {
      return false;
    }

    if ((key[0].toString()).indexOf(this.state.search) !== -1) {
      this.__mem[ks] = true;
      return true;
    }

    if (!data) {
      return data;
    }

    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (this.__shouldExpand([i.toString()].concat(key), data[i], level + 1)) {
          this.__mem[ks] = true;
          return true;
        }
      }
      this.__mem[ks] = false;
      return false;
    }

    if (data.constructor === {}.constructor) {
      let x = Object.keys(data).some(ckey => this.__shouldExpand([ckey].concat(key), data[ckey], level + 1));
      this.__mem[ks] = x;
      return x;
    }

    return false;
  }

  _filterJsonTree(tree) {
    if (!tree) {
      return tree;
    }

    if (Array.isArray(tree)) {
      let subTree = tree
        .map( (item) => this._filterJsonTree(item))
        .filter(c => c);
      if (subTree.length > 0) {
        return subTree;
      }
      return null;
    }

    if (tree.constructor === {}.constructor) {

      let filteredTree = {};
      Object.keys(tree).forEach(key => {
        let subTree = this._filterJsonTree(tree[key]);
        if (subTree) {
          filteredTree[key] = subTree;
        } else {
          // If it matches the current key, then it's a match and we should preserve its children
          if (key.indexOf(this.state.search) !== -1) {
            filteredTree[key] = tree[key];
          }
        }
      });

      if (Object.keys(filteredTree).length > 0) {
        return filteredTree;
      }

      return null;
    }

    // regular value ?!
    if (tree.toString().indexOf(this.state.search) === -1) {
      return null;
    }

    return tree;
  }

  render () {
    this.__mem = {};
    const { t } = this.props;

    const theme = {
      scheme: '#Cupertino',
      author: '#Defman21',
      base00: '#ffffff', // White
      base01: '#c0c0c0',
      base02: '#c0c0c0',
      base03: '#808080',
      base04: '#808080',
      base05: '#404040',
      base06: '#404040',
      base07: '#5e5e5e', // Black
      base08: '#c41a15', // Red
      base09: '#eb8500', // Orange
      base0A: '#826b28', // Yellow
      base0B: '#007400', // Green
      base0C: '#318495', // Cyan
      base0D: '#333943',
      base0E: '#a90d91', // Purple
      base0F: '#826b28', // Brown
    };

    let icons = {
      'Array': <i className={'fa fa-list'} aria-hidden={true} />,
      'Object': <i className={'fa fa-folder-o'} aria-hidden={true} />,
      'String' : <i className={'fa fa-file-text-o'} aria-hidden={true} />,
      'Number' : <i className={'fa fa-file-text-o'} aria-hidden={true} />,
    };

    return <div>

      <TextField
        label={t('search')}
        value={this.state.search}
        onChange={(e)=>{
          this.setState({ search: e.target.value }, () => this.filterJsonTree());
        }} />

      <div className='ph-prison' style={{ paddingTop: '0px', maxHeight: '400px', overflow: 'scroll' }}>
        <div className={'json-tree-container'}>
          <JSONTree
            data={this.state.filteredTree}
            collectionLimit={5}
            shouldExpandNode={(keyName,data,level) => {
              let se = this.__shouldExpand(keyName, data, level);
              return !!se;
            }}
            theme={JSON.parse(JSON.stringify(theme))}
            invertTheme={false}
            getItemString={(type, data, itemType, itemString) => <span className={'rjt-item'}>{itemString}</span>}
            labelRenderer={(path, type, ...rest) => {
              return <span className={'rjt-key'} onClick={()=>{
                this.props.onClick(path.slice().reverse()
                  .join('.'));
              }}>{icons[type]} <Highlight search={this.state.search}>{Array.isArray(path) ? path[0] : path}</Highlight></span>;
            }}
            valueRenderer={jsonValue => <em className={'rjt-value'}>{jsonValue}</em>}
            hideRoot={true}
          />
        </div>
      </div>
    </div>;
  }
}

JsonTree.propTypes = {
  t: PropTypes.func,
  providedExpandedPayload: PropTypes.any,
  onClick: PropTypes.func
};

export default withTranslation()(JsonTree);
