import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccessDrawer from '../AccessDrawer';
import CoamEnabledActionsBarButton from './base/CoamEnabledActionsBarButton';
import { getDesignerProfile } from '../../../../profiles/profiles';

class AccessButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showDrawer: false
    };
  }

  render () {

    const shouldRender = getDesignerProfile(this.props.designerProfile).features.manageAccess.enabled;
    if (!shouldRender) {
      return null;
    }

    const { t } = this.props;
    const groupUrl = (((this.props.templateProperties || {}).links || {}).coamAdminGroup || {}).href;
    let disabled = !groupUrl || this.props.disabled;

    let button = <CoamEnabledActionsBarButton
      t={t}
      operation={'read'}
      onClick={disabled ? null : () => this.setState({ showDrawer: true })}
      disabledTooltip={t('action-bar.button_access_tooltip_no_coam_group')}
      title={t('action-bar.button_access')}
      faIcon={'fa fa-users'} />;

    if (disabled) {
      return button;
    }

    return <React.Fragment>
      <AccessDrawer
        t={t}
        groupUrl={groupUrl}
        templateId={this.props.templateProperties.templateId}
        show={this.state.showDrawer}
        onHide={() => this.setState({ showDrawer: false })} />
      {button}
    </React.Fragment>;
  }
}

AccessButton.propTypes = {
  disabled: PropTypes.bool
};

AccessButton.defaultProps = {
  disabled: false
};

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    templateProperties: state.appReducer.templateProperties,
    designerProfile: state.designerProfiles.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessButton);
