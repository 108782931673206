// @ts-ignore
import changelog from '../../CHANGELOG.md';
import ReactMarkdown from 'react-markdown';

export const currentVersion: string = (changelog as string).match(/\d+\.\d+\.\d+/)?.[0] || 'current';

const changelogToRender = (changelog as string)
  .replace(/Changelog/, 'Version history') // replace title of page
  .replace(/All notable changes to this project.*\n/g, '')
  .replace(/The format is based on.*\n/g, '')
  .replace(/and this project adheres to.*\n/g, '')
  .replace(/\[(\d+\.\d+\.\d+)\]/g, '$1'); // ensure that version numbers are not rendered as links

export const RenderedChangelog = <ReactMarkdown
  source={changelogToRender} />;