import { CustomizrClient } from 'cimpress-customizr';
import moment from 'moment-timezone';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../store';
import { SettingsActions } from '../storeActions';

const getSettingsRequest = () => {
  return {
    type: SettingsActions.GET_SETTINGS_REQUEST,
  };
};

const getSettingsFailure = (error: any) => {
  return {
    type: SettingsActions.GET_SETTINGS_ERROR,
    errorTimestamp: moment.utc().format(),
    error: error,
  };
};

const getSettingsSuccess = (data: any) => {
  return {
    type: SettingsActions.GET_SETTINGS_SUCCESS,
    timestamp: moment.utc().format(),
    data: data,
  };
};

const putSettingsRequest = () => {
  return {
    type: SettingsActions.PUT_SETTINGS_REQUEST,
  };
};

const putSettingsFailure = (error: any) => {
  return {
    type: SettingsActions.PUT_SETTINGS_ERROR,
    errorTimestamp: moment.utc().format(),
    error: error,
  };
};

const putSettingsSuccess = (data: any) => {
  return {
    type: SettingsActions.PUT_SETTINGS_SUCCESS,
    timestamp: moment.utc().format(),
    data: data,
  };
};

export const updateSingleSettingInMemory = (category: string, settingName: string, settingValue: string) => {
  return {
    type: SettingsActions.UPDATE_SINGLE_SETTING,
    category,
    settingName,
    settingValue
  };
};


export const updateSingleSetting = (category: string, settingName: string, settingValue: string): ThunkAction<void, AppState, void, AnyAction> => 
  (dispatch, getState) => {

    dispatch(updateSingleSettingInMemory(category, settingName, settingValue));

    const state = getState();

    dispatch(putSettings(state.authReducer.accessToken, state.settingsReducer.settings));
  };

export const getSettings = (accessToken: string): ThunkAction<void, AppState, void, AnyAction> => (dispatch) => {
  dispatch(getSettingsRequest());

  const client = new CustomizrClient({
    resource: 'https://templatedesigner.cimpress.io',
    timeout: 5000
  });

  client.getSettings(accessToken)
    .then((data) => {
      dispatch(getSettingsSuccess(data));
    })
    .catch(error => {
      dispatch(getSettingsFailure(error));
    });
};

export const putSettings = (accessToken: string, settings): ThunkAction<void, AppState, void, AnyAction> => (dispatch) => {
  dispatch(putSettingsRequest());

  const client = new CustomizrClient({
    resource: 'https://templatedesigner.cimpress.io',
    timeout: 5000
  });

  return client.putSettings(accessToken, settings)
    .then((data) => {
      dispatch(putSettingsSuccess(data));
    })
    .catch(error => {
      dispatch(putSettingsFailure(error));
    });
};
