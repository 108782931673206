import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './LeftRightBlock.css';

export default class LeftRightBlock extends Component {
  render () {
    return <div className={'left-right-block ' + (this.props.className || '')}>
      <div className={'left-side'} style={this.props.wrap ? { whiteSpace: 'normal' } : null}>
        {this.props.left}
      </div>
      <div className={'right-side'} style={this.props.wrap ? { whiteSpace: 'normal' } : null}>
        {this.props.right}
      </div>
    </div>;
  }
}

LeftRightBlock.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  className: PropTypes.string,
  wrap: PropTypes.bool
};
