import React, { Component } from 'react';

import { FullWidthItem } from '../../commonComponents';
import { XBlockProps } from '../../../models/XBlockProps';

class XCsvLoopHeader extends Component<XBlockProps> {
 
  render() {
    const { t } = this.props;
    const item = this.props.renderProps.flatStructure[this.props.id];
    const itemProps = item.properties;

    return <div className='xcsv-block-default-header xcsv-loop-header'>
      <FullWidthItem>
        <span className={'highlight'}>{t('xcsv.iterate_on')}</span>
        <br/>
        <strong>{itemProps.loopPath}</strong>
      </FullWidthItem>
    </div>;
  }
}

export default XCsvLoopHeader;
