import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TabCard, colors, Alert } from '@cimpress/react-components';
import SampleTemplates from './Samples/SampleTemplates';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TemplateType } from '../../constants';
import { Profile } from '../../store/auth/types';

interface SamplesPageProps extends RouteComponentProps<any>, WithTranslation {
  accessToken: string;
  profile: Profile;
  t: Function;
}

class SamplesPage extends Component<SamplesPageProps> {

  render () {
    const { t } = this.props;
    let defaultTabName = TemplateType.XEMAIL;
    let emailThatIsNotNullForSure = this.props.profile ? this.props.profile.email : '';
    let tabs = [
      {
        key: TemplateType.XEMAIL,
        name: t('template-wizard.email_templates'),
        block: <div>
          <p className='card-text' style={{ color: colors.slate }}>These templates can be sent by email. We will provide
          you a link where you can send the data to fill out the template.</p>
          {/* @ts-ignore */}
          <SampleTemplates email={emailThatIsNotNullForSure} type={TemplateType.XEMAIL} />
        </div>
      }, {
        key: TemplateType.XDOC,
        name: t('template-wizard.document_templates'),
        block: <div>
          {/* @ts-ignore */}
          <SampleTemplates email={emailThatIsNotNullForSure} type={TemplateType.XDOC} />
        </div>
      }, {
        key: TemplateType.XCSV,
        name: t('template-wizard.csv_templates'),
        block: <div>
          <p className='card-text' style={{ color: colors.slate }}>Create a CSV template in a very intuitive way.</p>
          {/* @ts-ignore */}
          <SampleTemplates email={emailThatIsNotNullForSure} type={TemplateType.XCSV} />
        </div>
      }, {
        key: TemplateType.RAW,
        name: t('template-wizard.raw_templates'),
        block: <div>
          {/* @ts-ignore */}
          <SampleTemplates email={emailThatIsNotNullForSure} type={TemplateType.RAW} />
        </div>
      }
    ];

    // @ts-ignore
    let tab = this.props.match && this.props.match.params ? this.props.match.params.sampleType || defaultTabName : defaultTabName;
    let selectedIndex = 1;
    tabs.forEach((t, i) => {
      if (t.key === tab) {
        selectedIndex = i;
      }
    });

    // @ts-ignore
    let forwardedBecauseOfNoTemplates = ((this.props.location || {}).search || '').startsWith('?noTemplates');

    return (
      <div className='container margin-top'>

        {forwardedBecauseOfNoTemplates
          ? <Alert
            type={'info'}
            dismissible={false}
            message={t('no_templates_available')} />
          : null}

        <div>
          <br />
          <TabCard tabs={tabs} selectedIndex={selectedIndex} onSelect={(e, index) => {
            // @ts-ignore
            this.props.history.push('/samples/' + tabs[index].key);
          }} />
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    profile: state.authReducer.profile
  };
};

const mapDispatchToProps = () => {
  return {};
};

/* @ts-ignore */
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SamplesPage)));
