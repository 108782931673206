import { StereotypeClient } from '@cimpress-technology/stereotype-client';
import contentTypeParser from 'content-type';
import { Base64 } from 'js-base64';

import { Stereotype, TemplateTypeValues } from '../constants';
import { designersConfiguration } from '../utils/helpers';

const BASE_URL =  process.env.REACT_APP_TEMPLATE_DESIGNER_API_URL;

const getTemplate = async (accessToken: string, templateId: string) => {

  let client = new StereotypeClient(`Bearer ${accessToken}`, { baseUrl: BASE_URL });

  let templateProperties = await client.getTemplateById(templateId, true);

  let c = contentTypeParser.parse(templateProperties.contentType);
  templateProperties['baseContentType'] = c.type;

  // Decode the content
  let templateObject = Base64.decode(templateProperties.templateBody);

  // But do not store it in template properties
  delete templateProperties.templateBody;

  let designerConf = designersConfiguration[templateProperties.templateType];

  return {
    templateObject: designerConf ? designerConf.templateObjectAdaptor(templateObject) : {}, 
    templateProperties: templateProperties
  };
};

const listTemplates = async (accessToken: string, desiredTemplateTypes: TemplateTypeValues[]) => {
  const stereotype = new StereotypeClient(`Bearer ${accessToken}`, { baseUrl: BASE_URL });
  const userTemplates = (await stereotype.listTemplates(true))
    .map(t => {
      // In-place edit.
      t.links.self.href = decodeURIComponent(t.links.self.href);
      return t;
    });

  // Get all tags to with 'desired' template type
  const templatesList = userTemplates
    .filter(t => desiredTemplateTypes.includes(t.templateType));

  return Promise.resolve(templatesList);
};

const getStereotypeClient = (accessToken: string, headers = {}, options = {}) => {
  const client = new StereotypeClient(`Bearer ${accessToken}`, { baseUrl: BASE_URL, timeout: Stereotype.defaultTimeout, ...options });
  headers['whitelist'] && client.setWhitelistHeader(headers['whitelist']);
  client.setBlacklistHeader(headers['blacklist'] || Stereotype.xCimpressRelBlacklist);
  client.setAcceptPreferenceHeader(headers['acceptPreference'] || Stereotype.acceptPreference);
  client.setCrawlerSoftErrors(headers['crawlerSoftErrors'] || Stereotype.crawlerSoftErrors);
  client.setMaximumCrawlDepthHeader(headers['maximumCrawlDepth'] ?? Stereotype.maximumCrawlDepth);
  client.setCurieHeader('https://relations.cimpress.io/pool/print-job;fen');
  return client;
};

const expandPayload = (accessToken, payload, headers) => {
  const client = getStereotypeClient(accessToken, headers);
  return client.expand(payload, false, headers['contentType']);
};

const previewDirect = (template, payload, accessToken, contentType, content, headers, isBinaryResponse ) => {
  const client = getStereotypeClient(accessToken, headers);
  client.setIsBinaryResponse(isBinaryResponse);
  return client.materializeDirect({
    contentType,
    content
  }, payload || {}, false, false, headers['contentType'] || 'application/json');
};

export {
  getTemplate,
  listTemplates,
  expandPayload,
  previewDirect
};
