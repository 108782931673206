import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EscapableModal from '../EscapableModal';
import JSONRawEditor from './JSONRawEditor';
import CancelButton from '../../../generic/buttons/CancelButton';
import ConfirmButton from '../../../generic/buttons/ConfirmButton';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import { Trans } from 'react-i18next';

import LeftRightBlock from '../../../generic/LeftRightBlock';
import { connect } from 'react-redux';
import * as payloadActions from '../../../../store/payload/actions';
import { expandPayload } from '../../../../api/stereotype.api';
import { savePayload } from '../../../../api/customizr.api';
import { Checkbox, TextField, FlexBox } from '@cimpress/react-components';
import { Context } from '../SideNav';

const TAB_SIZE = 2;

class PayloadModalButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      payload: props.providedOriginalPayload || {},
      headers: props.providedHeaders || {},
      payloadAsText: JSON.stringify(props.providedOriginalPayload || {}, null, TAB_SIZE),
      modalOpen: false,
      expanding: false,
      error: null,
      showingErrorDetails: false,
      showAdvancedProperties: false
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( (prevProps.providedOriginalPayload !== this.props.providedOriginalPayload)
      || (prevProps.providedHeaders !== this.props.providedHeaders) ) {
      this.setState({
        payload: this.props.providedOriginalPayload || {},
        headers: this.props.providedHeaders || {},
        payloadAsText: JSON.stringify(this.props.providedOriginalPayload || {}, null, TAB_SIZE)
      });
    }
  }

  closeModal () {
    this.setState({
      modalOpen: false,
      error: null,
      showingErrorDetails: false,
      expanding: false,
      payload: this.props.providedOriginalPayload || {},
      headers: this.props.providedHeaders || {},
      payloadAsText: JSON.stringify(this.props.providedOriginalPayload || {}, null, TAB_SIZE),
    });
  }

  isStringJSONValid (stringJSON) {
    try {
      JSON.parse(stringJSON);
      return true;
    } catch (e) {
      return false;
    }
  }

  onExpandPayload () {
    const unexpandedPayload = JSON.parse(this.state.payloadAsText);
    this.setState({ expanding: true, error: null, showingErrorDetails: false });
    expandPayload(this.props.accessToken, unexpandedPayload, this.state.headers)
      .then(expandedPayload => {
        if (this.state.modalOpen) {
          const payloadAsJSON = JSON.parse(expandedPayload);
          const payloadAsText = JSON.stringify(payloadAsJSON, null, TAB_SIZE);
          this.setState({ payload: payloadAsJSON, payloadAsText: payloadAsText, expanding: false });
        }
      })
      .catch(err => {
        if (this.state.modalOpen) {
          const error = err && err.response && err.response.body ? err.response.body : err;
          this.setState({
            expanding: false,
            error: JSON.stringify(error, null, TAB_SIZE),
            errorCaption: this.props.t('err_expanding_placeholders')
          });
        }
      });
  }

  renderErrors () {
    if (!this.state.error) {
      return null;
    }

    return <span className='expanding-error-message'>
      <i className='fa fa-exclamation-circle text-danger' />
      {this.state.errorCaption}
      &nbsp;
      {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => this.setState({ showingErrorDetails: !this.state.showingErrorDetails })}>
        {this.state.showingErrorDetails ? this.props.t('hide_details') : this.props.t('show_details')}
      </a>
    </span>;
  }

  renderErrorDetails () {
    if (!this.state.error || !this.state.showingErrorDetails) {
      return null;
    }

    return <pre className='highlight-block details-block'>{
      this.state.error.message
        ? this.state.error.message
        : this.state.error}</pre>;
  }

  renderModalFooter (isPayloadValid) {
    const expandingButton = this.state.expanding
      ? <DefaultButton title={this.props.t('expanding_ellip')} faIcon='fa fa-spinner fa-spin no-padding' />
      : <DefaultButton disabled={!isPayloadValid} onClick={this.onExpandPayload.bind(this)} title={this.props.t('expand_json')} faIcon={'fa fa-expand'} />;

    let left = <React.Fragment>
      {expandingButton}
      {this.renderErrors()}
    </React.Fragment>;

    let right = <React.Fragment>
      <CancelButton onClick={(e) => this.closeModal()} />
      <span>&nbsp;</span>
      <ConfirmButton
        autoFocus
        disabled={this.state.expanding || !isPayloadValid}
        onClick={(e) => {
          const payloadAsJSON = JSON.parse(this.state.payloadAsText);
          this.props.setProvidedOriginalPayload(payloadAsJSON);
          this.props.setCustomStereotypeHeaders(this.state.headers);
          savePayload(this.props.accessToken, this.props.templateProperties.templateId, payloadAsJSON, this.state.headers);
          this.closeModal();
        }} />
    </React.Fragment>;

    return <div>
      <LeftRightBlock left={left} right={right} />
      {this.renderErrorDetails()}
    </div>;
  }

  render () {
    const isPayloadValid = this.isStringJSONValid(this.state.payloadAsText);
    const explicitlyShowAdvancedProperties = this.state.headers.blacklist
      || this.state.headers.whitelist
      || this.state.headers.contentType
      || this.state.headers.acceptPreference
      || (this.state.headers.maximumCrawlDepth === 0 || this.state.headers.maximumCrawlDepth)
      || this.state.headers.crawlerSoftErrors;

    const title = <div>
      <h4><Trans>placeholder_and_sample_data_definitions</Trans></h4>
    </div>;

    const body = <>
      <JSONRawEditor
        payload={this.state.payloadAsText}
        onChange={(payload) => this.setState({ payloadAsText: payload })}
        disabled={this.state.expanding}
      />

      <Checkbox label={this.props.t('toolkit.advancedProperties')} disabled={explicitlyShowAdvancedProperties} checked={explicitlyShowAdvancedProperties || this.state.showAdvancedProperties} onChange={() => this.setState({ showAdvancedProperties: !this.state.showAdvancedProperties })} />

      {(explicitlyShowAdvancedProperties || this.state.showAdvancedProperties)
      && <>
        <TextField
          className={'headerBox'}
          label={this.props.t('toolkit.blacklistHeader')}
          value={this.state.headers['blacklist']}
          onChange={e => {
            const newH = JSON.parse(JSON.stringify(this.state.headers));
            newH['blacklist'] = e.target.value;
            this.setState({ headers: newH });
          }}
        />
        <TextField
          className={'headerBox'}
          label={this.props.t('toolkit.whitelistHeader')}
          value={this.state.headers['whitelist']}
          onChange={(e) => {
            const newH = JSON.parse(JSON.stringify(this.state.headers));
            newH['whitelist'] = e.target.value;
            this.setState({ headers: newH });
          }}
        />
        <FlexBox spaceBetween marginX='s' style={{ margin: '0px -15px' }}>
          <TextField
            style={{ flexGrow: 1, marginBottom: '5px' }}
            label={this.props.t('toolkit.requestContentType')}
            value={this.state.headers['contentType']}
            onChange={(e) => {
              const newH = JSON.parse(JSON.stringify(this.state.headers));
              newH['contentType'] = e.target.value;
              this.setState({ headers: newH });
            }}
          />
          <TextField
            style={{ flexGrow: 1, marginBottom: '5px' }}
            label={this.props.t('toolkit.acceptPreference')}
            value={this.state.headers['acceptPreference']}
            onChange={(e) => {
              const newH = JSON.parse(JSON.stringify(this.state.headers));
              newH['acceptPreference'] = e.target.value;
              this.setState({ headers: newH });
            }}
          />
        </FlexBox>
        <FlexBox spaceBetween marginX='s' style={{ margin: '0px -15px' }}>
          {/* TODO: Add some kinda help context for these textboxes or a link to docs */}
          <TextField
            type='number'
            style={{ flexGrow: 1, marginBottom: '5px' }}
            label={this.props.t('toolkit.maximumCrawlDepth')}
            value={this.state.headers['maximumCrawlDepth']}
            onChange={(e) => {
              const newH = JSON.parse(JSON.stringify(this.state.headers));
              newH['maximumCrawlDepth'] = e.target.value;
              this.setState({ headers: newH });
            }}
          />
          <TextField
            style={{ flexGrow: 1, marginBottom: '5px' }}
            label={this.props.t('toolkit.crawlerSoftErrors')}
            value={this.state.headers['crawlerSoftErrors']}
            onChange={(e) => {
              const newH = JSON.parse(JSON.stringify(this.state.headers));
              newH['crawlerSoftErrors'] = e.target.value;
              this.setState({ headers: newH });
            }}
          />
        </FlexBox>
      </>}
    </>;

    return (
      <div>
        <div className='editorModal'>
          <EscapableModal
            show={this.state.modalOpen}
            style={{ width: '90%' }}
            title={title}
            footer={this.renderModalFooter(isPayloadValid)}
            closeOnOutsideClick
            closeButton={false}
            onRequestHide={() => this.closeModal()}>
            {body}
          </EscapableModal>
        </div>
        <Context.Consumer>
          { value =>
            <div className='element-block sampleData-btn' onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
              { value && <span className='editData-title'><strong>{this.props.t('toolkit.edit_sample_data')}&nbsp;</strong></span>}
              <i className="fa fa-pencil payload-icon" />
            </div>
          }
        </Context.Consumer>
      </div>);
  }
}

PayloadModalButton.propTypes = {
  t: PropTypes.func,
  payload: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    accessToken: state.authReducer.accessToken,
    providedExpandedPayload: state.payload.providedExpandedPayload,
    providedOriginalPayload: state.payload.providedOriginalPayload,
    providedHeaders: state.payload.headers,
    templateType: state.appReducer.templateType,
    templateProperties: state.appReducer.templateProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProvidedExpandedPayload: providedExpandedPayload => dispatch(payloadActions.setExpandedPayload(providedExpandedPayload)),
    setProvidedOriginalPayload: providedOriginalPayload => dispatch(payloadActions.setOriginalPayload(providedOriginalPayload)),
    setCustomStereotypeHeaders: headers => dispatch(payloadActions.setCustomStereotypeHeaders(headers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayloadModalButton);
