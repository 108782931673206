import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionsBarButton from './ActionsBarButton';

class CoamEnabledActionsBarButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      disabled: false
    };
  }

  UNSAFE_componentWillMount () {
    this.mounted = true;
    this.shouldDisableComponent(this.props.templateProperties.templateId, this.props.operation, this.props.accessToken)
      .then(b => this.mounted ? this.setState({ disabled: b }) : null);
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.templateProperties.templateId !== this.props.templateProperties.templateId || nextProps.operation !== this.props.operation || nextProps.accessToken !== this.props.accessToken) {
      this.shouldDisableComponent(nextProps.templateProperties.templateId, nextProps.operation, nextProps.accessToken)
        .then(b => {
          return this.mounted ? this.setState({ disabled: b }) : null;
        });
    }
  }

  shouldDisableComponent (templateId, operation, accessToken) {
    let userId;
    try {
      userId = JSON.parse(atob(accessToken.split('.')[1])).sub;
    } catch (err) {
      //
    }

    if (this.props.disableCheck || !templateId || !operation || !accessToken || !userId) {
      return Promise.resolve(true);
    }
    return fetch(
      `https://api.cimpress.io/auth/access-management/v1/principals/${userId}/permissions/stereotype-templates/${templateId}/${operation}%3Atemplates`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        return !res.ok;
      })
      .catch(() => {
        return true;
      });
  }

  render () {
    const { t } = this.props;
    return <ActionsBarButton
      disabled={this.props.disabled || this.state.disabled || this.props.disableCheck}
      className={this.props.className}
      disabledTooltip={this.props.disableMessage || t('insufficient_permissions')}
      title={this.props.title}
      faIcon={this.props.faIcon}
      customContent={this.props.customContent}
      onClick={this.props.onClick} />;
  }
}

CoamEnabledActionsBarButton.propTypes = {
  operation: PropTypes.string,
  disabled: PropTypes.bool,
  disableCheck: PropTypes.bool,
  disableMessage: PropTypes.string,
  title: PropTypes.string,
  faIcon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  customContent: PropTypes.any
};

const mapStateToProps = state => {
  return {
    templateProperties: state.appReducer.templateProperties,
    accessToken: state.authReducer.accessToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CoamEnabledActionsBarButton);
