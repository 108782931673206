import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HandlebarsTextBox from 'handlebars-textbox';

export interface FromFieldType {
    name: string;
    address: string;
}

export interface FromFieldProps {
    from: FromFieldType;
    onChange: (from: FromFieldType) => {};
}

export interface ValueLabelPair {
  value: string;
  label: string;
}

const AllowedDomains: ValueLabelPair[] = [
  { value: '@noreply.cimpress.io', label: 'noreply.cimpress.io' },
  { value: '@trdlnk.cimpress.io', label: 'trdlnk.cimpress.io' },
];

const shouldBeInAdvancedMode = (address: string) => {
  const addr = address || '';
  if (!addr) { 
    return false;
  }

  if (addr.indexOf('@') < 0) {
    return true;
  }

  for (let i = 0; i < AllowedDomains.length; i++ ) {
    if (addr.endsWith(AllowedDomains[i].value)) {
      return false;
    }
  }
  return true;
};

export const FromField: React.FC<FromFieldProps> = ({ from, onChange }) => {
  
  const { t } = useTranslation();
  const [advancedMode, setAdvancedMode] = useState(false);
  
  useEffect(() => {
    if (from) {
      setAdvancedMode(shouldBeInAdvancedMode(from.address));
    }
  // eslint-disable-next-line
  }, [from.name, from.address]);
    
  const name = from.name || '';
  const address = from.address || 'robot@noreply.cimpress.io';
  const domain = address.split('@');

  return <div className='field'>
    <span className={'field-caption'}>{t('from')}:</span>
    <span className='field-input-group'>
      <span className={'field-from-1'}>
        <HandlebarsTextBox
          className='field-input from-name'
          placeholder={t('display_name')}
          value={name}
          onChange={(value) => {
            let f = Object.assign({}, from, { name: value });
            onChange(f);
          }}
        />
      </span>
      <span className='separator'>-</span>
      {advancedMode 
        ? <HandlebarsTextBox
          className='field-input from-name'
          placeholder={t('display_name')}
          value={address}
          onChange={(value) => {
            let f = Object.assign({}, from, { address: value });
            onChange(f);
          }}
        />
        : <>
          <span className={'field-from-2'}>
            <HandlebarsTextBox
              className='field-input'
              value={domain[0]}
              onChange={(value) => {
                if (!value.includes('@')) {
                  let f = Object.assign({}, from, { address: `${value}@${domain[1]}` });
                  onChange(f);
                }
              }} />
          </span>
          <span className='at'><i className='fa fa-at' /></span>
          <span className={'field-from-3'}>
            <select
              className='field-select'
              value={`@${domain[1]}`}
              onChange={(e) => onChange({ address: `${domain[0]}${e.target.value}`, name })}>
              {AllowedDomains.map((o) => {
                return <option key={`${o.label}_${o.value}`} value={o.value}>{o.label}</option>;
              })}
            </select>
          </span>
        </>
      }
      <span>&nbsp;</span>
      <input type='checkbox'
        checked={advancedMode}
        onChange={() => {
          if (advancedMode) {
            onChange(Object.assign({}, from, { address: `info${AllowedDomains[0].value}` }));
          }
          setAdvancedMode(!advancedMode);
        }}
      />
    </span>
  </div>;
    
};