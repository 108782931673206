import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';

class ErrorDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  renderDetails (e) {
    const { t } = this.props;

    if (typeof e === 'string') {
      return e;
    }

    if (e.message) {
      return <div className='trdlnk-pointer' onClick={() => this.setState({ showStack: !this.state.showStack })}>
        {t('errors.err_message')}: {e.message ||  t('unknown_save_err')}
        {e.status
          ? <span>{' '}(Status: {e.status})</span>
          : null}
        {e.response && e.response?.data ? <div><br />{t('errors.err_message_detailed')}: {e.response.data}</div> : null}
        {this.state.showStack
          ? <pre><code>{e.stack}</code></pre>
          : null}
      </div>;
    }

    return JSON.stringify(e);
  }

  renderDetailsBlock () {
    if (!this.props.error) {
      return null;
    }

    return <div>{this.renderDetails(this.props.error)}</div>;
  }

  renderContact () {
    return <div>Feel free to <Link to='/contactUs'>reach out to us</Link> so we can assist you promptly!</div>;
  }

  render () {
    const { t } = this.props;
    const title = this.props.title || t('err_info_title');
    return <div>
      <h5 className={'text-danger'}><i className={'fa fa-exclamation-triangle'} />&nbsp;{title}</h5>
      {this.props.message ? <div>{this.props.message}</div> : null}
      {this.props.showContactLine ? this.renderContact() : null}
      {this.props.onRetry
        ? (<div>
          <br />
          <button className={'btn btn-sm btn-outline-secondary'} onClick={() => this.props.onRetry()}>
            <i className='fa fa-refresh' aria-hidden='true' />&nbsp;<Trans>retry</Trans>
          </button>
          <br />
        </div>)
        : null}
      {this.renderDetailsBlock()}
    </div>;
  }

}

ErrorDetails.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.object,
  onRetry: PropTypes.func,
  showContactLine: PropTypes.bool
};

ErrorDetails.defaultProps = {
  showContactLine: false
};

export default withTranslation()(ErrorDetails);
