import { Modal } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import DefaultButton from '../scenes/generic/buttons/DefaultButton';

interface ModalComponentHandlerArgument {
    handleModalLeave: Function;
    handleModalCancel: Function;
    showModal: boolean;
}

interface NavigationBlockingComponentProps {
    modalComponentHandler: (modalComponentHandlerArgument: ModalComponentHandlerArgument) => React.ReactElement;
    shouldBlockNavigation: boolean;
}

// TODO: take component from integration-options-ui when migrating to React Router v6 and beyond
// it is already tweaked to work with that version
const NavigationBlockingComponent: React.FC<NavigationBlockingComponentProps> = ({
  modalComponentHandler,
  shouldBlockNavigation
}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>('');
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation) {
      setShowModal(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleModalCancel = (event: any = null) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setShowModal(false);
  };

  const handleModalLeave = (): any => {
    setConfirmedNavigation(true);
    setShowModal(false);
  };

  const onUnload = (e: any) => {
    setShowModal(false);
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  const setEventListeners = () => {
    window.addEventListener('beforeunload', onUnload);
  };

  const removeEventListeners = () => {
    window.removeEventListener('beforeunload', onUnload);
  };

  useEffect(() => {
    setEventListeners();
    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      history.push(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return <>
    <Prompt when message={handleBlockedNavigation} />
    {modalComponentHandler({ handleModalLeave, handleModalCancel, showModal })}
  </>;
};

const NavigationBlockingModal: React.FC<Pick<NavigationBlockingComponentProps, 'shouldBlockNavigation'>> = ({
  shouldBlockNavigation
}) => {
  const { t } = useTranslation();

  return <NavigationBlockingComponent
    shouldBlockNavigation={shouldBlockNavigation}
    modalComponentHandler={({ handleModalLeave, handleModalCancel, showModal }) => (
      <Modal
        show={showModal}
        onRequestHide={() => handleModalCancel()}
        title={t('navigationBlockingModal.title')}
        closeButton
        footer={
          <>
            <DefaultButton
              onClick={() => handleModalLeave()}
              title={t('navigationBlockingModal.leave')}/>
            <DefaultButton
              status={'primary'}
              onClick={() => handleModalCancel()}
              title={t('navigationBlockingModal.stay')}/>
          </>
        }>
        {t('navigationBlockingModal.message')}
      </Modal>
    )}/>;
};

export {
  NavigationBlockingComponent,
  NavigationBlockingModal
};
