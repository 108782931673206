import XEmailBoard from '../xemail/XEmailBoard';
import XEmailResourcesGroup from '../xemail/XEmailResourcesGroup';
import XEmailTemplateRenderer from '../xemail/XEmailTemplateRenderer';
import { convertFlatToEdieStructure } from '../../../../store/helpers/edieInternalStateHelpers';
import cloneDeep from 'lodash/cloneDeep';

const config = {
  board: XEmailBoard,
  resources: XEmailResourcesGroup,
  
  exportTemplate: (props) => {
    let edieJson = cloneDeep(props.edie);

    edieJson.structure = convertFlatToEdieStructure(
      props.edie,
      edieJson.flatStructure
    );

    delete edieJson.flatStructure;

    return Promise.resolve(JSON.stringify(edieJson));
  },

  templateObjectAdaptor: (templateObject) => {
    return JSON.parse(templateObject);
  },

  createTemplateRendererFunction: (templateContentType, materializationContentType) => {
    return new XEmailTemplateRenderer(templateContentType, materializationContentType);
  }
};

export default config;
