import cloneDeep from 'lodash/cloneDeep';
import { createEmptyBlock }  from '@cimpress-technology/edie-processors';

function dfs (item, parentId, fn) {
  // go through children
  (item.children || []).forEach((x) => dfs(x, item.id, fn));

  // go through current
  fn(parentId, item);
}

const cloneChildElement = (item, parentId, flat) => {
  if (!item) {return;}
  let newItem = createEmptyBlock(flat[item.id].type);
  newItem.parentId = parentId;
  newItem.properties = flat[item.id].properties;
  newItem.children = [];
  flat[newItem.id] = cloneDeep(newItem);

  (item.children || []).forEach((childId) => {
    let tempId  = cloneChildElement(flat[childId], newItem.id, flat);
    if (tempId) { flat[newItem.id].children.push(tempId); }
  });
  return newItem.id;
};

const addNItems = (flat,parent,item) => {
  for (let i = 0;i < item.noOfItems;i++){
    let newItem = createEmptyBlock(item.type);
    if (item.properties && item.properties[i]) {newItem.properties = item.properties[i];}
    inplaceItemAdd(flat,parent,newItem,i);
    if (item.child){
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      addNItems(flat,newItem,item.child);
    }

  }
};

function flattenStructure (structure) {
  const itemsList = {};
  const flattenItem = (parentId, item) => {
    const itemx = cloneDeep(item);
    itemx.children = (item.children || []).map(child => child.id);
    itemx.parentId = parentId;
    itemsList[itemx.id] = itemx;
  };
  dfs(structure, 'main', flattenItem);
  return itemsList;
}

function convertFlatToEdieStructure (structure, flat) {
  let newTree = cloneDeep(flat['main']);
  const addProperties = (node) => {
    if (!node || !node.children) {
      return null;
    }
    node.children.forEach((childId, index) => {
      node.children[index] = flat[childId];
      addProperties(flat[childId]);
    });
  };
  addProperties(newTree);
  return newTree;
}

/**
 * Add new item
 */
function inplaceItemAdd (flat, parent, itemToAdd, position) {
  flat[itemToAdd.id] = itemToAdd;
  flat[itemToAdd.id].parentId = parent.id;
  flat[parent.id].children.splice(position, 0, itemToAdd.id);
}

/**
 * Remove new item
 */
function inplaceItemRemove (flat, item) {
  let oldParentId = flat[item.id].parentId;
  flat[oldParentId].children = flat[oldParentId].children.filter(childId => childId !== item.id);
  (flat[item.id].children || []).forEach(childId => inplaceItemRemove(flat, flat[childId]));
  delete flat[item.id];
}

/**
 * Update item properties
 */
function inplaceItemUpdate (flat, item, data) {
  flat[item.id].properties = Object.assign({}, flat[item.id].properties, data);
}

/**
 * Move Item (parent1 -> parent2)
 */
function inplaceItemMove (flat, newParent, itemToMove, position) {
  let oldParentId = flat[itemToMove.id].parentId;
  // HACK: If moving to an index value more than the current, within the same parent 
  // then decrement the target position by 1
  let itemToMoveIndex = flat[oldParentId].children.indexOf(itemToMove.id);
  if (oldParentId === newParent.id && position > itemToMoveIndex) {
    position -= 1;
  }

  flat[oldParentId].children.splice(itemToMoveIndex, 1);
  flat[itemToMove.id].parentId = newParent.id;
  flat[newParent.id].children.splice(position, 0, itemToMove.id);
}

/**
 * Clone existing item
 */
const inplaceItemClone = (flat, parent, itemToClone): string => {
  let clonedItem = createEmptyBlock(itemToClone.type);
  clonedItem.properties = itemToClone.properties;
  clonedItem.parentId = parent.id;

  flat[clonedItem.id] = clonedItem;

  let childItems = flat[itemToClone.id].children;
  flat[clonedItem.id].children = (childItems || []).map(childItemId => cloneChildElement(flat[childItemId], clonedItem.id, flat));

  const position = flat[parent.id].children.findIndex(childId => childId === itemToClone.id);
  flat[parent.id].children.splice(position + 1, 0, clonedItem.id);

  return clonedItem.id;
};

export {
  inplaceItemAdd,
  inplaceItemRemove,
  inplaceItemMove,
  inplaceItemUpdate,
  inplaceItemClone,
  flattenStructure,
  convertFlatToEdieStructure,
  addNItems
};
