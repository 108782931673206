import React from 'react';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';

import XRow from './blocks/XCsvRow/XCsvRow';
import XMain from './blocks/XCsvMain/XCsvMain';
import XLoop from './blocks/XCsvLoop/XCsvLoop';
import XCsvCellItem from './blocks/XCsvCell/XCsvCellItem';
import XCsvCellProperties from './blocks/XCsvCell/XCsvCellProperties';
import XCsvLoopProperties from './blocks/XCsvLoop/XCsvLoopProperties';
import XCsvPropertiesGroup from './XCsvPropertiesGroup';

const renderBlockSettings = (itemId, renderProps) => {
  if (!itemId || !renderProps.flatStructure[itemId]) {
    return <p>{renderProps.t('blocks.click_to_display_and_modify')}</p>;
  }

  let itemProperties = (renderProps.flatStructure[itemId] || {});

  let commonProps = {
    t: renderProps.t,
    id: itemId,
    renderProps,
    templateMetadata: renderProps.meta,
    onUpdateItem: renderProps.onUpdateItem,
    onUpdateBlockMetaData: renderProps.onUpdateBlockMetaData,
    properties: itemProperties.properties
  };

  switch (itemProperties.type) {
    case EDIE_BLOCK_TYPE.COLUMN:
      return <XCsvCellProperties {...commonProps} />;

    case EDIE_BLOCK_TYPE.LOOP:
      return <XCsvLoopProperties {...commonProps} />;

    default:
      return <XCsvPropertiesGroup t={commonProps.t}/>;
  }
};

const renderBlock = (itemId, renderProps, isTopLevelNode = false) => {
  if (!itemId) {
    return <div>no item</div>;
  }

  let itemProperties = itemId === 'main'
    ? { type: EDIE_BLOCK_TYPE.MAIN }
    : (renderProps.flatStructure[itemId] || {});

  let defaultHandlers = {
    onClick: (e: Event) => {
      e.stopPropagation();
      renderProps.onSelectItem(itemId);
    }
  };

  let commonProps = {
    t: renderProps.t,
    id: itemId,
    elements: renderProps.flatStructure[itemId].children,
    isTopLevelNode,
    renderProps,
    renderBlock,
    defaultHandlers
  };

  switch (itemProperties.type) {
    case EDIE_BLOCK_TYPE.MAIN:
      return <XMain {...commonProps} id={'main'} isTopLevelNode />;

    case EDIE_BLOCK_TYPE.COLUMN:
      return <XCsvCellItem {...commonProps} />;

    case EDIE_BLOCK_TYPE.LOOP:
      return <XLoop {...commonProps} />;

    case EDIE_BLOCK_TYPE.ROW:
      return <XRow {...commonProps} />;

    default:
      return <div>Item of type {renderProps.flatStructure[itemId].type} cannot be rendered.</div>;
  }
};

export {
  renderBlock,
  renderBlockSettings
};
