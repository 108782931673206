import React, { Component } from 'react';
import { Select } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { updateSingleSetting } from '../../../../store/settings/actions';
import { Trans } from 'react-i18next';
import { RawEditorSettings } from '../../../../constants';
import { convertToOptions } from '../../../../utils/options';

class RawProperties extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {

    // TODO: It will likely be better to drop these and in favor of the settings dialog
    const modes = RawEditorSettings.supportedModes.map(k => ({ value: k, label: k }));
    const themes = RawEditorSettings.supportedThemes.map(k => ({ value: k, label: k }));
    const fontSizes = RawEditorSettings.supportedFontSizes.map(k => ({ value: k, label: k }));

    const selectedMode = convertToOptions(this.props.settings.rawEditor.mode || RawEditorSettings.defaults.mode, modes);
    const selectedTheme = convertToOptions(this.props.settings.rawEditor.theme || RawEditorSettings.defaults.theme, themes);
    const selectedFontSize = convertToOptions(this.props.settings.rawEditor.fontSize || RawEditorSettings.defaults.fontSize, fontSizes);

    return (
      <div className='propertyBar' style={{ cursor: 'not-allowed' }}>
        <h5 className={'propertyTitle'}><Trans>editor_options</Trans></h5>
        <Select
          label={this.props.t('toolkit.raw_editor_mode_label')}
          value={selectedMode}
          options={modes}
          onChange={(v) => this.props.onSettingChange('rawEditor', 'mode', v.value)}
          isClearable={false}
        />
        <Select
          label={this.props.t('toolkit.raw_editor_theme_label')}
          value={selectedTheme}
          options={themes}
          onChange={(v) => this.props.onSettingChange('rawEditor', 'theme', v.value)}
          isClearable={false}
        />
        <Select
          label={this.props.t('toolkit.raw_editor_font_size_label')}
          value={selectedFontSize}
          options={fontSizes}
          onChange={(v) => this.props.onSettingChange('rawEditor', 'fontSize', v.value)}
          isClearable={false}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settingsReducer.settings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSettingChange: (category, settingName, settingValue) => {
      dispatch(updateSingleSetting(category,settingName,settingValue));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RawProperties);
