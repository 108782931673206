import React, { Component } from 'react';
import XCsvCellItemResource from './blocks/XCsvCell/XCsvCellItemResource';

class XCsvResourcesGroup extends Component {
  render() {
    return <XCsvCellItemResource />;
  }
}

export default XCsvResourcesGroup;
