import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SendEmailButton from './SendEmailButton';
import SaveAsButton from './SaveAsTemplateButton';
import DeleteTemplateButton from './DeleteTemplateButton';
import SaveTemplateButton from './SaveTemplateButton';
import PreviewButton from './PreviewButton';
import { withTranslation } from 'react-i18next';
import { TemplateType } from '../../../../constants';

import './ActionsBar.scss';
import AccessButton from './AccessButton';
import WhatsNewButton from './WhatsNewButton';

class ActionsBar extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div className='templateActionsBar'>
          <PreviewButton
            t={t}
            createTemplateRenderer={this.props.createTemplateRenderer}
            boardExporterPromise={this.props.boardExporterPromise}
          />
          <SaveTemplateButton
            t={t}
            boardExporterPromise={this.props.boardExporterPromise} />
          <SaveAsButton
            t={t}
            boardExporterPromise={this.props.boardExporterPromise} />
          {this.props.templateType === TemplateType.XEMAIL
            && <SendEmailButton
              t={t} />}
          <AccessButton
            t={t} />
          <DeleteTemplateButton
            t={t} />
          <WhatsNewButton
            t={t} />
        </div>
      </>
    );
  }
}

ActionsBar.propTypes = {
  boardExporterPromise: PropTypes.func.isRequired,
};

export default withTranslation()(ActionsBar);
