import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@cimpress/react-components';
import CancelButton from '../../generic/buttons/CancelButton';
import EscapableModal from './EscapableModal';
import ConfirmButton from '../../generic/buttons/ConfirmButton';

class EditDynamicImageModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newSrc: props.image.src,
      newAlt: props.image.alt,
      newCondition: props.image.condition,
      newWidth: props.image.width || '100%',
      newHeight: props.image.height || null
    };
  }

  render() {
    const { t } = this.props;

    if (!this.props.modalOpen) {
      return null;
    }

    return <EscapableModal
      status={'info'}
      show={this.props.modalOpen}
      title={t('ckeditor_plugins.update_dynamic_image_modal_caption')}
      onRequestHide={this.props.onClose}
      footer={<div>
        <CancelButton onClick={this.props.onClose} />
        <ConfirmButton
          autoFocus
          onClick={(e) => this.props.onConfirm({
            src: this.state.newSrc,
            alt: this.state.newAlt,
            condition: this.state.newCondition,
            width: this.state.newWidth,
            height: this.state.newHeight
          })} />
      </div>}>
      <TextField
        label={t('ckeditor_plugins.dynamic_image_src')}
        helpText={t('ckeditor_plugins.dynamic_image_src_help')}
        value={this.state.newSrc}
        required
        onChange={(e) => this.setState({ newSrc: e.target.value })} />
      <TextField
        label={t('ckeditor_plugins.dynamic_image_alt')}
        helpText={t('ckeditor_plugins.dynamic_image_alt_help')}
        value={this.state.newAlt}
        onChange={(e) => this.setState({ newAlt: e.target.value })} />
      <TextField
        label={t('ckeditor_plugins.dynamic_image_condition')}
        helpText={t('ckeditor_plugins.dynamic_image_condition_help')}
        value={this.state.newCondition}
        onChange={(e) => this.setState({ newCondition: e.target.value })} />
      <TextField
        label={t('ckeditor_plugins.dynamic_image_width')}
        helpText={t('ckeditor_plugins.dynamic_image_width_help')}
        value={this.state.newWidth}
        onChange={(e) => this.setState({ newWidth: e.target.value })} />
      <TextField
        label={t('ckeditor_plugins.dynamic_image_height')}
        helpText={t('ckeditor_plugins.dynamic_image_height_help')}
        value={this.state.newHeight}
        onChange={(e) => this.setState({ newHeight: e.target.value })} />
    </EscapableModal>;
  }
}

EditDynamicImageModal.propTypes = {
  image: PropTypes.object,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default EditDynamicImageModal;
