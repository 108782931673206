import React, { Component } from 'react';

import XMainDropTarget from './XMainDropTarget';
import { XBlockProps } from '../../../models/XBlockProps';
import { renderBlock } from '../../blockRenderer';

class XMain extends Component<XBlockProps> {

  renderDropTarget(position) {
    return <XMainDropTarget
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  render () {
    let options = this.props.renderProps.flatStructure['main'].properties || {};
    let width = options.emailWidth || '100%';

    return <div style={{
      // Note: these 10px are just in case ;)
      padding: '10px',
      backgroundColor: width !== '100%' ? (options.emailBackgroundColor || '#fff') : '#ddd'
    }}  {...this.props.defaultHandlers} >
      <div className={'xemail-main-container'} style={{
        boxShadow: width !== '100%' ? 'none' : '0 0 5px 1px #888',
        backgroundColor: options.backgroundColor || '#fff',
        maxWidth: width
      }}>
        {this.renderDropTarget(0)}
        {this.props.elements && this.props.elements.length > 0
          ? (this.props.elements || [])
            .map((element, index) =>
              <React.Fragment key={index}>
                {renderBlock(element, this.props.renderProps, true)}
                {this.renderDropTarget(index + 1)}
              </React.Fragment>)
          : <div style={{ width: '100%' }}>{this.props.t('blocks.drag_and_drop_here')}</div>}
      </div>
    </div>;
  }
}

export default XMain;
