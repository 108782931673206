import React, { Component } from 'react';
import { Modal } from '@cimpress/react-components';

export default class EscapableModal extends Component {

  captureModalKeyboardActions (e) {
    switch (e.key) {
      case 'Escape':
        this.props.onRequestHide();
        break;
      default:
        break;
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.captureModalKeyboardActions.bind(this));
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.captureModalKeyboardActions.bind(this), true);
  }

  render () {

    if (!this.props.show) {
      return null;
    }

    return <Modal {...this.props}>
      {this.props.children}
    </Modal>;
  }
}