import { Profile } from './types';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import Auth from '../../utils/Auth';
import { AppState } from '../store';
import { AuthActions } from '../storeActions';

export const onRefreshedToken = (accessToken: string) => ({ type: AuthActions.REFRESH_TOKEN, accessToken });
export const onRefreshedProfile = (profile: Profile) => ({ type: AuthActions.REFRESH_PROFILE, profile });
export const setLoading = (loading: boolean) => ({ type: AuthActions.LOGIN_LOADING, loading });
export const onLogout = () => ({ type: AuthActions.LOGOUT });

let auth;

export const initializeAuth = (): ThunkAction<void, AppState, void, AnyAction> => dispatch => {
  auth = new Auth(dispatch);
};

export const login = (): ThunkAction<void, AppState, void, AnyAction> => dispatch => {
  dispatch(setLoading(true));
  auth.login()
    .then(() => dispatch(setLoading(false)));
};

export const logout = (): ThunkAction<void, AppState, void, AnyAction> => dispatch => {
  auth.logout()
    .then(() => dispatch(onLogout()));
};
