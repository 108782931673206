import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import classNames from 'classnames';
import { analyzePlaceholder, SUBTYPES } from '../../../../../../utils/placeholderHelpers';

import imageIcon from './placeholder.svg';
import PlaceholderCommand from './placeholderCommand';

const PLACEHOLDER_ELEMENT = 'placeholder';
const PLACEHOLDER_ELEMENT_BLOCK = 'placeholderBlock';
const PLACEHOLDER_COMMAND = 'insertPlaceholder';
const PLACEHOLDER_BUTTON = 'placeholderButton';

export {
  PLACEHOLDER_ELEMENT,
  PLACEHOLDER_ELEMENT_BLOCK,
  PLACEHOLDER_COMMAND,
  PLACEHOLDER_BUTTON
};

export default class Placeholder extends Plugin {

  static get requires() {                                                  
    return [ Widget ];
  }

  static get pluginName() {
    return 'Placeholder';
  }

  init() {
    const editor = this.editor;
    const conversion = this.editor.conversion;

    editor.model.schema.register(PLACEHOLDER_ELEMENT, {
      inheritAllFrom: '$text',
      allowAttributes: ['type', 'content', 'sub'],
      isObject: true
    });

    editor.model.schema.register(PLACEHOLDER_ELEMENT_BLOCK, {
      allowWhere: ['$text'],
      allowAttributes: ['type', 'content', 'sub'],
      isObject: true
    });

    conversion.for( 'upcast' ).elementToElement( {
      view: {
        name: 'span',
        attributes: {
          type: 'placeholder',
          sub: 'normal'
        }
      },
      model: (viewElement, conversionApi) => {
        const modelWriter = conversionApi.writer;
        return modelWriter.createElement(
          PLACEHOLDER_ELEMENT, {
            content: viewElement.getAttribute('content'),
            sub: viewElement.getAttribute('sub')
          });
      }
    } )
      .elementToElement( {
        view: {
          name: 'span',
          attributes: {
            type: 'placeholder',
          }
        },
        model: (viewElement, conversionApi) => {
          const modelWriter = conversionApi.writer;
          return modelWriter.createElement(
            PLACEHOLDER_ELEMENT_BLOCK, {
              content: viewElement.getAttribute('content'),
              sub: viewElement.getAttribute('sub')
            });
        }
      } )
      .elementToElement( {
        view: {
          name: 'span',
          attributes: {
            type: 'placeholder',
          }
        },
        model: (viewElement, conversionApi) => {
          const modelWriter = conversionApi.writer;
          return modelWriter.createElement(
            PLACEHOLDER_ELEMENT_BLOCK, {
              content: viewElement.getAttribute('content'),
              sub: viewElement.getAttribute('sub')
            });
        }
      } );

    conversion.for( 'dataDowncast' )
      .elementToElement( {
        model: PLACEHOLDER_ELEMENT,
        view: (modelItem, conversionApi) => {
          const viewWriter = conversionApi.writer;
          return viewWriter.createEmptyElement(
            'span', {
              type: 'placeholder',
              content: modelItem.getAttribute('content'),
              sub: modelItem.getAttribute('sub')
            });
        }
      } )
      .elementToElement( {
        model: PLACEHOLDER_ELEMENT_BLOCK,
        view: (modelItem, conversionApi) => {
          const viewWriter = conversionApi.writer;
          return viewWriter.createEmptyElement(
            'span', {
              type: 'placeholder',
              content: modelItem.getAttribute('content'),
              sub: modelItem.getAttribute('sub')
            });
        }
      } );

    conversion.for( 'editingDowncast' )
      .elementToElement( {
        model: PLACEHOLDER_ELEMENT,
        view: (modelItem, conversionApi) => {
          const viewWriter = conversionApi.writer;
          const analyzedPlaceholder = analyzePlaceholder(modelItem.getAttribute('content') || '');
          const span = viewWriter.createContainerElement('span', {
            'data-display': analyzedPlaceholder.display,
            'sub': modelItem.getAttribute('sub'),
            class: classNames(
              'ed-ck-placeholder',
              { 'ph-condition': analyzedPlaceholder.subTypes.includes(SUBTYPES.CONDITION) },
              { 'ph-open': analyzedPlaceholder.subTypes.includes(SUBTYPES.OPEN) },
              { 'ph-close': analyzedPlaceholder.subTypes.includes(SUBTYPES.CLOSE) },
              { 'ph-formula': analyzedPlaceholder.subTypes.includes(SUBTYPES.FORMULA) },
            ) });
          return toWidget(span, viewWriter, { label: 'placeholderWidgetP' });
        }
      })
      .elementToElement( {
        model: PLACEHOLDER_ELEMENT_BLOCK,
        view: (modelItem, conversionApi) => {
          const viewWriter = conversionApi.writer;
          const analyzedPlaceholder = analyzePlaceholder(modelItem.getAttribute('content') || '');
          const span = viewWriter.createContainerElement('span', {
            'data-display': analyzedPlaceholder.display,
            'sub': modelItem.getAttribute('sub'),
            class: classNames(
              'ed-ck-placeholder',
              { 'ph-condition': analyzedPlaceholder.subTypes.includes(SUBTYPES.CONDITION) },
              { 'ph-open': analyzedPlaceholder.subTypes.includes(SUBTYPES.OPEN) },
              { 'ph-close': analyzedPlaceholder.subTypes.includes(SUBTYPES.CLOSE) },
              { 'ph-formula': analyzedPlaceholder.subTypes.includes(SUBTYPES.FORMULA) },
            ) });
          return toWidget(span, viewWriter, { label: 'placeholderWidgetB' });
        }
      } );

    this._configurePlaceholderButton();
  }

  _configurePlaceholderButton() {
    const editor = this.editor;
    const t = editor.t;
    const placeholderCommand = new PlaceholderCommand(editor);
    editor.commands.add(PLACEHOLDER_COMMAND, placeholderCommand);

    editor.ui.componentFactory.add(PLACEHOLDER_BUTTON, locale => {
      const button = new ButtonView(locale);
      button.set({
        label: t('Insert placeholder code'),
        icon: imageIcon,
        tooltip: true
      });

      placeholderCommand.isEnabled = true;
      //button.bind('isOn').to(placeholderCommand, 'value');
      button.on('execute', () => {
        editor.fire('onPlaceholderEdit', placeholderCommand.value, ((newContent) => {
          editor.editing.view.focus();
          placeholderCommand.execute(newContent);
        }));
      });

      return button;
    });
  }
}
