import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Uploader, { ThumbnailDisplay } from '@cimpress-technology/react-platform-uploader';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import LoadingPage  from './LoadingPage';
import { Alert } from '@cimpress/react-components';

interface ImageProps {
  selectedImage: UploadedFile;
  onChange: Function;
  savedImage: string;
  onRemovingImage: Function;
}
export interface UploadedFile{
  id: string;
  href: string;
  contentType: string;
}

export const initialImageState = {
  id: '',
  href: '',
  contentType: ''
};

const supportedContentType = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const ImageUploader: React.FC<ImageProps> = props => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.authReducer);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const messages = { dragAndDropDocument: t('drag_and_drop'), or: t('or'), chooseFile: t('browse_files') };
  const uploadedFile = props.selectedImage && props.selectedImage.id ?  props.selectedImage :  { href: props.savedImage };

  const changeHandler = files => {
    setUploading(false);
    if (files.length > 1) {
      throw new Error(t('errors.multiFile_selection_not_supported'));
    }
    if (!supportedContentType.includes(files[0].type)) {
      throw new Error(t('errors.unsupported_content_type'));
    }
    const file: UploadedFile = { id: files[0].id, href: files[0].url, contentType: files[0].type };
    props.onChange(file);
  };

  const removeImage = () => {
    if (props.savedImage) {
      props.onRemovingImage();
    } else {
      props.onChange(initialImageState);
    }
  };

  const onUploadFileError  = (error) =>{
    setUploading(false);
    setError(error.message || t('errors.error_uploading_file'));
  };

  return uploading ? <LoadingPage loadingMessage={t('uploading')}/>
    : error ? <Alert style={{ marginTop: '2%' }} message={error} status='danger' onDismiss={() => setError('')}/>
      : <div style={{ width: '100%' }}>
        <div>
          <Uploader
            onUploadFileSuccess={changeHandler}
            onDelete={removeImage}
            provideThumbnails={true}
            type='standard'
            uploadTenant="default"
            accessToken={accessToken}
            messages = {messages}
            deleteAfterDays = {'1'}
            onUploadFileStart={() => setUploading(true)}
            onUploadFileError = {error => onUploadFileError(error)}
          />
        </div>
        <div>
          { ((props.selectedImage && props.selectedImage.id) || props.savedImage) && <ThumbnailDisplay
            tenant="default"
            key={1}
            uploads={[uploadedFile]}
            onDelete={removeImage}
            accessToken={accessToken} >
          </ThumbnailDisplay> }
        </div>
      </div>;
};