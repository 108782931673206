import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { StickyContainer } from 'react-sticky';
import '../../../../styles/stickyToolbar.scss';
import AceEditor from 'react-ace';
import Split from 'react-split';

import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';

import { connect } from 'react-redux';
import * as appActions from '../../../../store/app/actions';
import { transformToDefaultHandlebars, transformToXML } from '../../dropTextHelpers';
import { RawEditorSettings } from '../../../../constants';
import PropTypes from 'prop-types';
import { Card } from '@cimpress/react-components';
import config from './config';

class RawBoard extends Component {

  constructor (props) {
    super(props);
    this.state = { content: this.props.templateObject };
    this.aceEditor = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.templateObject !== prevProps.templateObject) {
      this.setState({ content: this.props.templateObject });
    }
  }

  componentWillUnmount() {
    this.props.resetAppReducer();
  }

  render () {
    const { connectDropTarget } = this.props;
    let ActionBar = config.actionBar;
    let Properties = config.properties;
    const rawEditorProperties = this.props.settings ? this.props.settings.rawEditor || {} : {};

    return connectDropTarget(<div style={{ height: '100%' }}>
      <StickyContainer className='boardWrapper' style={{ display: 'flex' }}>
        <Split direction="horizontal" className="board-split" sizes={[100, 0]} gutterSize={8}>
          <div id='board' ref={this.props.boardRef} className={ 'board raw-board' + (this.props.boardToolbar ? ' toolbar' : '') }>
            <AceEditor
              className={''}
              ref={this.aceEditor}
              mode={rawEditorProperties.mode || RawEditorSettings.defaults.mode}
              theme={rawEditorProperties.theme || RawEditorSettings.defaults.theme}
              fontSize={rawEditorProperties.fontSize || RawEditorSettings.defaults.fontSize}
              showGutter
              highlightActiveLine
              enableBasicAutocompletion
              tabSize={2}
              readOnly={this.props.readOnly}
              value={this.props.templateObject}
              width={'100%'}
              height={'100%'}
              showPrintMargin={false}
              onChange={(newContent) => {
                this.props.onTemplateObjectChanged(newContent);
              }}
              editorProps={{ $blockScrolling: Infinity }} />
          </div>
          <div className='xsettings'>
            <ActionBar
              templateType={this.props.templateProperties.templateType}
              createTemplateRenderer={config.createTemplateRendererFunction}
              boardExporterPromise={config.exportTemplate}/>
            <div style={{ padding: '15px 15px 0' }}><strong>{this.props.t('properties')}</strong></div>
            <Card variant="layout" className='properties-card' >
              <Properties t={this.props.t}/>
            </Card>
          </div>
        </Split>
      </StickyContainer>
    </div>);
  }
}

const textTarget = {
  drop(props, monitor, component) {
    const editor = component.aceEditor.current.editor;
    const cursorPosition = editor.getCursorPosition();
    let valueToInsert;

    if (!props.settings.rawEditor.mode || props.settings.rawEditor.mode === 'xml') {
      valueToInsert = transformToXML(monitor.getItem());
    } else {
      valueToInsert = transformToDefaultHandlebars(monitor.getItem());
    }

    if (cursorPosition) {
      editor.session.insert(cursorPosition, valueToInsert);
    } else {
      editor.session.insert(valueToInsert); // To the end
    }
  }
};

const collect = (connect, monitor) => {
  return {
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
};

const mapStateToProps = state => {
  return {
    templateObject: state.appReducer.templateObject,
    settings: state.settingsReducer.settings,
    templateProperties: state.appReducer.templateProperties,
    accessToken: state.authReducer.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTemplateObjectChanged: (event) => {
      dispatch(appActions.templateObjectChanged(event));
      dispatch(appActions.setIsTemplateModified(true));
    },
    resetAppReducer: () => {
      dispatch(appActions.resetAppReducer());
    }
  };
};

RawBoard.propTypes = {
  readOnly: PropTypes.bool
};

RawBoard.defaultProps = {
  readOnly: false
};

export default connect(mapStateToProps, mapDispatchToProps)(DropTarget('TODO: FIX ME', textTarget, collect)(RawBoard));
