import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../locales/en-US/translations.json';
import fr from '../locales/fr-FR/translations.json';
import de from '../locales/de-DE/translations.json';
import nl from '../locales/nl-NL/translations.json';
import it from '../locales/it-IT/translations.json';
import ja from '../locales/ja-JP/translations.json';
import zh from '../locales/zh-CN/translations.json';

let missingKeysData: any[] = [];
let missingKeysDataCache = {};

let debug = () => (window.location && window.location.hostname === 'localhost');

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: { translations: en },
    fr: { translations: fr },
    de: { translations: de },
    nl: { translations: nl },
    it: { translations: it },
    ja: { translations: ja },
    zh: { translations: zh }
  },
  fallbackLng: 'en',
  debug: false,

  ns: ['translations'],
  // have a common namespace used around the full app
  defaultNS: 'translations',
  
  interpolation: {
    formatSeparator: ','
  },

  saveMissing: true,
  missingKeyHandler: (lng, ns, key, fallbackValue) => {

    let item = {
      language: lng,
      namespace: ns,
      key: key,
      fallbackValue: fallbackValue
    };

    let cacheKey = JSON.stringify(item);
    if (!missingKeysDataCache[cacheKey]) {
      if (debug()) {
        // eslint-disable-next-line
        console.log(item)
      } else {
        missingKeysDataCache[cacheKey] = item;
        missingKeysData.push(item);        
      }
    }
  },

  react: {
    wait: true
  }
});

export default i18n;
