import React, { Component } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';

import '../../styles/home.scss';
import { connect } from 'react-redux';
import { TextField, Table, FlexBox } from '@cimpress/react-components';
import { CommentsDrawerLink } from '@cimpress-technology/react-comments';
import Highlight from 'react-highlighter';
import Loading from '../generic/Loading';
import ErrorInfo from '../generic/ErrorInfo';
import LeftRightBlock from '../generic/LeftRightBlock';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';
import i18n from '../../utils/i18n';
import debounce from 'debounce';
import { TemplateType } from '../../constants';
import { listTemplates } from '../../api/stereotype.api';

const PER_PAGE = 15;

interface HomePageProps extends RouteComponentProps<any>, WithTranslation {
  accessToken: string;
  t: Function;
}

interface HomePageState {
  editNames: any;
  loading: boolean;
  templates: any;
  error: any;
  filterByKey?: string;
  filterBy?: string;
  showTemplateActions?: any;
  page?: number;
}

class HomePage extends Component<HomePageProps, HomePageState> {
  debounceUpdateFilter: any;

  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      editNames: {},
      loading: false,
      templates: undefined,
      error: null
    };

    this.debounceUpdateFilter = debounce(this.updateFilter, 300);

  }

  componentDidMount() {
    this.getTemplates();
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.accessToken !== this.props.accessToken) {
      this.getTemplates();
    }
  }

  _dictionaryToSortArray(dictionary) {
    return Object.keys(dictionary)
      .map((t) => dictionary[t])
      .sort((a, b) => (a.templateName || a.templateId).localeCompare((b.templateName || b.templateId)));
  }

  updateFilter(key) {
    this.setState({ filterBy: key, showTemplateActions: null });
  }

  getTemplates() {
    listTemplates(this.props.accessToken, [
      TemplateType.XEMAIL,
      TemplateType.XCSV,
      TemplateType.XDOC,
      TemplateType.RAW
    ])
      .then(templates => {
        this.setState({
          loading: false,
          templates: this._dictionaryToSortArray(templates),
          error: null
        });
      });
  }

  renderSearch() {
    return <TextField
      label={this.props.t('home-page.search_for_template')}
      value={this.state.filterByKey}
      onChange={(e) => {
        this.setState({ filterByKey: e.target.value });
        this.debounceUpdateFilter(e.target.value);
      }} />;
  }

  filterItem(t) {
    const fieldsToMatch = [t.templateId];
    if (t.templateName) {
      fieldsToMatch.push(t.templateName);
    }
    if (!this.state.filterBy) {
      return true;
    }

    return fieldsToMatch.some(f => f.toLowerCase().indexOf(this.state.filterBy && this.state.filterBy.toLowerCase()) !== -1);
  }

  renderItem(t) {
    const templateId = t.templateId;
    const displayName = t.templateName || t.templateId;
    const templateUrl = ((t.links || {}).self || {}).href;

    return <li key={templateId}>
      <FlexBox>
        <div style={{ flexGrow: 1000 }} className='item-template-name-section'>

          <span style={{ display: 'inline' }}>
            <Link key={templateId} to={'/designer/' + templateId}>
              {this.state.filterBy
                ? <Highlight search={this.state.filterBy}>{displayName}</Highlight>
                : displayName}
            </Link>
          </span>
        </div>
        <div>
          {templateUrl
            ? <div>
              <CommentsDrawerLink
                locale={i18n.language}
                key={`comments_drawer_${templateId}`}
                resourceUri={templateUrl}
                newestFirst editComments accessToken={this.props.accessToken} />
            </div>
            : null}
        </div>
      </FlexBox>
    </li>;
  }

  renderLoading(additionalContent) {
    return <div className='container margin-top'>
      <div>
        {additionalContent}
      </div>
      <br />
    </div>;
  }

  render() {
    const { t } = this.props;
    // @ts-ignore
    let hash = this.props.location.hash;
    if (hash && hash.length > 0 && hash.substring(0, 2) === '#!') {
      let route = hash.substring(2);
      return <Redirect to={route} />;
    }

    if (this.state.error) {
      return <div className='container margin-top'>
        <ErrorInfo
          message={t('home-page.failed_to_retrieve')}
          details={this.state.error}
          onRetry={() => this.getTemplates()} />
      </div>;
    }

    if (this.state.loading || this.state.templates === undefined) {
      return this.renderLoading(<Loading message={t('home-page.loading_list')} />);
    }

    if (this.state.templates.length === 0) {
      return <Redirect to='/samples/?noTemplates' />;
    }

    let filteredTemplates = this.state.templates.filter(t => this.filterItem(t));


    const columns = [
      {
        Header: t('table_template_name'),
        accessor: 'templateName',
        sortable: false,
        width: '100%',
        Cell: row => (
          <Link key={row.original.templateId} to={'/designer/' + row.original.templateId}>
            <span style={{ display: 'inline' }}>
              {this.state.filterBy
                ? <Highlight search={this.state.filterBy}>{row.original.templateName || row.original.templateId}</Highlight>
                : (row.original.templateName || row.original.templateId)}
            </span>
          </Link>
        ),
      }, {
        Header: t('table_template_type'),
        accessor: 'templateType',
        sortable: true,
        width: '100%',
        Cell: row => (
          <span>{t(`table_template_type_${row.original.templateType}`)}</span>
        ),
      }, {
        Header: t('table_isPublic'),
        accessor: 'table_isPublic',
        sortable: true,
        width: '100%',
        Cell: row => (
          <span>{(row.original.isPublic) ? t('table_isPublic_yes') : t('table_isPublic_no')}</span>
        ),
      }, {
        Header: t('table_createdBy'),
        accessor: 'createdBy',
        sortable: true,
        width: '100%',
      }
    ];

    return (
      <div className='container margin-top'>
        <LeftRightBlock
          left={<span />}
          right={<Link to={'/samples'}>
            <button className={'btn btn-primary'}>
              <Trans i18nKey={'home-page.create_new'} />
            </button>
          </Link>} />
        <br />

        {(filteredTemplates.length > PER_PAGE) || this.state.filterBy
          ? this.renderSearch()
          : null}

        <Table
          columns={columns}
          data={filteredTemplates}
          isBordered={false}
          hasHover={true}
          pageSize={20}
          paginationPosition={'bottomRight'}
          noDataText={t('home-page.no_match_found')}
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.authReducer.profile,
    accessToken: state.authReducer.accessToken
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomePage));
