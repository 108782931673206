import React, { Component } from 'react';

import XColumnDropTarget from './XColumnDropTarget';

import XColumnHeader from './XColumnHeader';
import classNames from 'classnames';
import { XColumnProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';
import { parseHandlebarsExpression, PropertyType } from '../../../../../../utils/helpers';
import { renderBlock } from '../../blockRenderer';
class XColumn extends Component<XColumnProps, XBlockState> {

  renderDropTarget(disable, position) {
    return <XColumnDropTarget
      disabled={disable}
      containerId={this.props.id}
      onDrop={this.props.renderProps.onAddOrMoveItem}
      position={position} />;
  }

  renderBody(_itemProps) {
    const disableDropWithin = this.props.draggingItemId === this.props.id || this.props.renderProps.disableDrop;

    return <div>

      {this.renderDropTarget(disableDropWithin, 0)}

      {(this.props.elements || []).map((element, index) => <React.Fragment key={index}>

        {renderBlock(
          element,
          Object.assign({}, this.props.renderProps, { disableDrop: disableDropWithin }),
          // Children of a column are not top-level nodes
          false
        )}

        {this.renderDropTarget(disableDropWithin, index + 1)}

      </React.Fragment>
      )}
    </div>;
  }

  render() {
    let itemProps = this.props.renderProps.flatStructure[this.props.id].properties;
    const defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';

    let className = classNames(
      defaultClass,
      'xemail-column-container',
      { 'xemail-empty-container': + (this.props.elements || []).length > 0 }
    );

    return <td valign='top' className={className} width={itemProps.width}>

      <div style={{
        backgroundColor: parseHandlebarsExpression(PropertyType.COLOR, itemProps.backgroundColor, this.props.renderProps.providedOriginalPayload),
        border: (itemProps.borderSize || '0px') + ' solid ' + parseHandlebarsExpression(PropertyType.COLOR, itemProps.borderColor, this.props.renderProps.providedOriginalPayload, 'transparent')
      }}
      {...this.props.defaultHandlers}>
        <XColumnHeader {...this.props} />
        {this.renderBody(itemProps)}
      </div>
    </td>;
  }
}

export default XColumn;
