import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';

function formatDate (d) {
  return moment(d).format('HH:mm:ss');
}

function statusLabel (s) {
  let title = {
    'RUNNING': <span className='badge badge-info'><Trans>in_progress</Trans></span>,
    'SENT': <span className='badge badge-success'><Trans>sent</Trans></span>,
    'FAILURE': <span className='badge badge-danger'><Trans>failed</Trans></span>
  };
  return title[s];
}

function validateEmails (recipients) {
  let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let mustacheResourceRegex = /{{.+?(?=}})}}/;
  let emails = (recipients || '').replace(/ /g, '').split(',');
  for (let i = 0; i < emails.length; i++) {
    const email = emails[i];
    if (!emailRegex.test(email) && !mustacheResourceRegex.test(email)) {
      return false;
    }
  }
  return true;
}

export {
  formatDate,
  statusLabel,
  validateEmails
};
