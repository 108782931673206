import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ColorUtils } from '../../utils/helpers';
import { TextField } from '@cimpress/react-components';
import ColorPicker from './ColorPicker';

import './TextFieldColorPicker.scss';

export default class TextFieldColorPicker extends Component {
  render () {
    return <TextField
      disabled={this.props.disabled}
      name={this.props.name}
      label={this.props.label}
      helpText={this.props.helpText}
      value={ColorUtils.rgb2hex(this.props.color)}
      onChange={this.props.onTextChange}
      rightAddon={
        <ColorPicker
          disabled={this.props.disabled}
          coverClassName={'btn color-picker-preview'}
          color={this.props.color}
          onChange={(hexColor) => this.props.onColorChange(hexColor)} />
      }
    />;
  }
}

ColorPicker.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  onTextChange: PropTypes.func,
  onColorChange: PropTypes.func
};
