import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CopyToClipboard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      additionalButtonStyle: ''
    };
  }

  copyToClipboard () {
    let textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = this.props.toCopy;

    document.body.appendChild(textArea);
    textArea.select();

    try {
      let successful = document.execCommand('copy');
      let btnStyle = successful ? 'btn-success' : 'btn-danger';
      setTimeout(() => {
        this.setState({
          additionalButtonStyle: btnStyle
        });
      }, 10);
    } catch (err) {
      //
    }

    document.body.removeChild(textArea);
    setTimeout(() => {
      this.setState({
        additionalButtonStyle: ''
      });
    }, 1000);
  }

  render () {
    return (
      <span onClick={this.copyToClipboard.bind(this)} className={this.props.className}>
        {this.props.children}
      </span>
    );
  }
}

CopyToClipboard.propTypes = {
  toCopy: PropTypes.string,
  className: PropTypes.string
};

CopyToClipboard.defaultProps = {
  toCopy: ''
};
