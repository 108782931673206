import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import ActionsBarButton from './base/ActionsBarButton';
import { saveAs } from 'file-saver';
import Popout from 'react-popout';
import EscapableModal from '../EscapableModal';
import Loading from '../../../generic/Loading';
import ErrorDetails from '../../../generic/ErrorDetails';
import DefaultButton from '../../../generic/buttons/DefaultButton';
import { Trans } from 'react-i18next';
import { getMaterializationContentType, checkIsBinaryResponse } from '../../../../utils/helpers';
import { getDesignerProfile } from '../../../../profiles/profiles';
import { csvTemplateContentType, xlsxTemplateContentType } from './../../../../constants';

class PreviewButton extends Component {

  constructor (props) {
    super(props);
    this.state = {
      completed: false
    };
  }

  materializeTemplate(contentType){
    return this.props.boardExporterPromise({
      templateObject: this.props.templateObject,
      edie: this.props.edie
    })
      .then((res) => {
        this.setState({
          previewPopout: true,
          previewContent: null
        });
        return res;
      })
      .then(res => {
        let materializationContentType = getMaterializationContentType(this.props.templateProperties.templateType, contentType);
        let r = this.props.createTemplateRenderer(
          contentType,
          materializationContentType
        );
        const isBinaryResponse = checkIsBinaryResponse(materializationContentType);
        return r.renderPreviewPromise(res, this.props.providedOriginalPayload, this.props.providedHeaders, this.props.accessToken, isBinaryResponse)
          .then(content => [content, materializationContentType]);
      });
  }

  preProcessContentType(){
    // xlsx preview shows csv preview of excel with a link to generate xlsx file.
    if (this.props.templateProperties.contentType.includes('xlsx')){
      return csvTemplateContentType;
    }
    return this.props.templateProperties.contentType;
  }

  previewTemplate () {
    this.setState({
      error: undefined
    }, () => {
      const contentType = this.preProcessContentType(this.props.templateProperties.contentType);
      this.materializeTemplate(contentType)
        .then(([content, materializationContentType]) => {
          this.setState({
            previewContent: content.result,
            previewContentBlob: content.blobResult,
            previewContentType: materializationContentType
          });
        })
        .then(() => {
          this.refs.popout.state.popoutWindow.focus();
        })
        .catch((err) => {
          this.setState({
            error: err,
            previewPopout: false,
            previewContent: null
          });
        });
    });
  }

  onClose () {
    this.setState({
      saving: false,
      completed: false,
      errorSaving: undefined
    });
  }

  downloadXlsx(){
    this.materializeTemplate(xlsxTemplateContentType)
      .then(([content, materializationContentType]) =>{
        const blob = new Blob([content.blobResult], { type: materializationContentType });
        saveAs(blob, 'preview.xlsx');
        this.setState({
          previewPopout: false,
          previewContent: null
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          previewPopout: false,
          previewContent: null
        });
      });
  }

  generateUrlFromBlob(){
    const blob = new Blob([this.state.previewContentBlob], { type: this.state.previewContentType });
    const fileURL = URL.createObjectURL(blob);
    return fileURL;
  }

  previewContent(){
    if (this.props.templateProperties.contentType.includes('xlsx')){
      return <>
        <div>{this.props.t('csvPreviewForExcel')} <a download='preview' onClick={() => this.downloadXlsx()} href={'/#'}>{this.props.t('csvPreviewForExcelLink')}</a></div>
        <div dangerouslySetInnerHTML={{ __html: this.state.previewContent }} /></>;
    }
    else if (this.props.templateProperties.contentType.includes('htmlpdf')){
      return <object name="preview" data={this.generateUrlFromBlob()} type={this.state.materializationContentType} width='100%' height='100%'>
        <p>{this.props.t('pdfRenderingNotSupportedByBrowser')}</p>
      </object>;
    }
    else {
      return <div dangerouslySetInnerHTML={{ __html: this.state.previewContent }} />;
    }
  }

  render () {
    const { t } = this.props;
    let loadingModal = <div style={{ width: '100%', textAlign: 'center' }}><Loading message={t('loading')} /></div>;

    const shouldRender = getDesignerProfile(this.props.designerProfile).features.preview.enabled;
    if (!shouldRender) {
      return null;
    }

    let previewPopout = null;
    let content = this.state.previewContent !== null
      ? <div style={{
        margin: '0',
        padding: '0',
        width: '100% !important',
        minWidth: '100%',
        WebkitTextSizeAdjust: '100%',
        msTextSizeAdjust: '100%',
        wordBreak: 'break-word',
        fontFamily: '"Open Sans", "San Francisco", "Segoe UI", "Helvetica Neue", Arial, sans-serif',
        fontSize: '14px',
        lineHeight: '1.286',
        color: '#333943'
      }}>
        {this.previewContent()}
      </div>
      : loadingModal;

    if (this.state.previewPopout === true) {
      previewPopout = (
        <Portal>
          <Popout ref='popout' title={t('email_preview')} onClosing={() => this.setState({ previewPopout: false })} options={{
            width: (o, w) => Math.max(w.innerWidth / 2, 900),
            height: (o, w) => w.outerHeight,
            top: (o, w) => ((w.outerHeight - o.height) / 2) + w.screenY,
            left: (o, w) => ((w.outerWidth - Math.max(w.innerWidth / 2, 900)) / 2) + w.screenX
          }}>
            {content}
          </Popout>
        </Portal>);
    }

    return <div>

      <EscapableModal status={'danger'} show={!!this.state.error} title={<span><Trans i18nKey={'preview_template'} /></span>}
        onRequestHide={() => this.setState({ error: undefined })}
        footer={<DefaultButton title={t('got_it')} onClick={() => this.setState({ error: undefined })} />}>
        <ErrorDetails title={t('failed_to_parse_template')} error={this.state.error} />
      </EscapableModal>

      <ActionsBarButton 
        onClick={() => this.previewTemplate()} 
        disabledTooltip={''} 
        title={t('action-bar.button_preview')} 
        faIcon={'fa-eye'} />
      {previewPopout}
    </div>;
  }
}

PreviewButton.propTypes = {
  boardExporterPromise: PropTypes.any,
  templateProperties: PropTypes.any,
  providedOriginalPayload: PropTypes.any,
  providedHeaders: PropTypes.any,
  accessToken: PropTypes.string,
  templateObject: PropTypes.any,
  edie: PropTypes.any
};

const mapStateToProps = state => {
  return {
    templateProperties: state.appReducer.templateProperties,
    designerProfile: state.designerProfiles.active,
    providedOriginalPayload: state.payload.providedOriginalPayload,
    providedHeaders: state.payload.headers,
    accessToken: state.authReducer.accessToken,
    templateObject: state.appReducer.templateObject,
    edie: state.edie
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewButton);
