import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';

interface ErrorPageProps extends WithTranslation {
  message: string;
  alternativeMessage?: string;
  data?: string;
}

class ErrorPage extends Component<ErrorPageProps> {
  render () {
    const data = this.props.data ? <pre className='errorData'>{this.props.data}</pre> : '';
    const alternativeMessage = this.props.alternativeMessage
      || <Trans i18nKey={'oops_cannot_display_page'}><Link to={'/'}>__not__used__</Link></Trans>;
    return (
      <div className='errorLayout card'>
        <h1>{this.props.message}</h1>
        <div>
          {alternativeMessage}
        </div>
        {data}
      </div>);
  }
}

export default withTranslation()(ErrorPage);
