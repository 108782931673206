import { TemplateProperties } from './../store/app/types'; import React, { Component } from 'react';
import { connect } from 'react-redux';
import DesignerApp from './Designer/DesignerApp';
import ErrorPage from './Pages/ErrorPage';
import LoadingPage from './Pages/LoadingPage';
import { WithTranslation, withTranslation } from 'react-i18next';
import { createTemplate, loadTemplate } from '../store/app/actions';
import { loadPayload, savePayload } from '../api/customizr.api';
import * as payloadActions from '../store/payload/actions';
import { RouteComponentProps } from 'react-router-dom';
import { EdieState } from '../store/edie/types';
import { ProfileName } from '../profiles/profiles';
import { getDesignerProfile } from '../profiles/profiles';
import { AppState } from '../store/store';
import { NavigationBlockingModal } from '../utils/navigationBlockingComponent';

interface AppContainerProps extends RouteComponentProps<any>, WithTranslation {
  accessToken: string;
  templateProperties: TemplateProperties;
  templateObject: any;
  loading: boolean;
  error: any;
  designerProfile: ProfileName;
  edie: EdieState;
  t: Function;
  isTemplateModified: boolean;
  createTemplate: typeof createTemplate;
  loadTemplate: typeof loadTemplate;
  setProvidedOriginalPayload: typeof payloadActions.setOriginalPayload;
  setCustomStereotypeHeaders: typeof payloadActions.setCustomStereotypeHeaders;
}

class AppContainer extends Component<AppContainerProps> {

  constructor (props: AppContainerProps) {
    super(props);
    this.loadTemplate();
  }

  retrieveTemplatePayload () {
    // @ts-ignore
    let search = new URLSearchParams((this.props.location || {}).search || '');
    let explicitPayload = search.get('payload');
    let explicitHeaders = search.get('headers');
    if (explicitPayload) {
      let payload = decodeURIComponent(explicitPayload);
      let headers = decodeURIComponent(explicitHeaders || '{}');
      try {
        const jsonPayload = JSON.parse(payload);
        const jsonHeaders = JSON.parse(headers);
        this.props.setProvidedOriginalPayload(jsonPayload);
        this.props.setCustomStereotypeHeaders(jsonHeaders);
        savePayload(this.props.accessToken, this.props.templateProperties.templateId || '', jsonPayload, jsonHeaders);
        search.delete('payload');
        search.delete('headers');
        // @ts-ignore
        this.props.history.push({
          pathname: '/designer/' + this.props.templateProperties.templateId,
          search: '?' + search.toString()
        });

        // Do not load from customizr in this case.
        return;

      } catch (e) {
        // ignore
      }
    }

    loadPayload(this.props.accessToken, this.props.templateProperties.templateId || '')
      .then(data => {
        if (!data){
          return;
        }
        if (data.payload){
          this.props.setProvidedOriginalPayload(data.payload);
        }
        if (data.headers){
          this.props.setCustomStereotypeHeaders(data.headers);
        }
      });
  }

  componentDidUpdate (prevProps /*, prevState, snapshot */) {
    if (this.props.accessToken !== prevProps.accessToken
      || (this.props.templateProperties.templateId
        && this.props.templateProperties.templateId !== this.props.match.params.templateId)
    ) {
      this.loadTemplate();
    }
    if (this.props.templateProperties.templateId !== prevProps.templateProperties.templateId) {
      this.retrieveTemplatePayload();
    }
  }

  loadTemplate() {
    // @ts-ignore
    const templateIdRouteParam = this.props.match.params.templateId || '';
    // @ts-ignore
    const searchParams = new URLSearchParams(this.props.location.search);
    const creating = templateIdRouteParam === '' ? searchParams.get('creating') : null;
    const contentType = creating ? searchParams.get('contentType') : null;
    if (creating) {
      this.props.createTemplate(this.props.accessToken, creating, contentType);
    }
    else if (templateIdRouteParam !== '') {
      this.props.loadTemplate(this.props.accessToken, templateIdRouteParam);
    }
    else {
      // @ts-ignore
      this.props.history.push('/');
    }
  }

  render() {
    const { t } = this.props;
    // @ts-ignore
    const searchParams = new URLSearchParams(this.props.location.search);

    if (this.props.error) {
      const err = this.props.error || { icon: <i className={'fa fa-user-times'} />, message: t('not_logged_in') };
      return <ErrorPage
        message={err.languageKey ? t(err.languageKey) : err.message}
        alternativeMessage={err.alternativeMessage}
        data={err.data} />;
    }

    if (this.props.loading || (this.props.templateObject == null && !searchParams.get('creating'))) {
      return <LoadingPage loadingMessage={t('loading_template')} />;
    }

    const creating = this.props.templateProperties.templateId === ''
      ? searchParams.get('creating')
      : null;

    const templateType = creating
      ? creating
      : this.props.templateProperties.templateType;

    const hasHeader = getDesignerProfile(this.props.designerProfile).header.enabled;

    return <>
      <NavigationBlockingModal
        shouldBlockNavigation={this.props.isTemplateModified}/>
      <DesignerApp
        //Payload provided as query parameter
        newPayload={searchParams.get('payload')}
        // Translations
        t={this.props.t}

        embedded={!hasHeader}

        // redux
        templateProperties={this.props.templateProperties}
        templateObject={this.props.templateObject}
        templateType={templateType}
        edie={this.props.edie}
      />
    </>;
  }
}

const mapStateToProps = (state: AppState) => {
  const appReducer = state.appReducer;
  return {
    loading: appReducer.loading,
    error: appReducer.error,
    loadingMessage: appReducer.loadingMessage,

    // Main template information
    templateProperties: appReducer.templateProperties,
    templateObject: appReducer.templateObject,
    isTemplateModified: appReducer.isTemplateModified,

    // profile
    designerProfile: state.designerProfiles.active,

    // TODO: This needs more thought. From design point of view, AppContainer should be generic
    //  and NOT know about a structure needed for a specific board.
    //  (assuming we'd still want multi-board support)
    edie: state.edie
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTemplate: (accessToken, templateType, contentType) => dispatch(createTemplate(accessToken, templateType, contentType)),
    loadTemplate: (accessToken, templateId) => dispatch(loadTemplate(accessToken, templateId)),
    setProvidedOriginalPayload: providedOriginalPayload => dispatch(payloadActions.setOriginalPayload(providedOriginalPayload)),
    setCustomStereotypeHeaders: headers => dispatch(payloadActions.setCustomStereotypeHeaders(headers)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
