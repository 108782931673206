import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { designersConfiguration } from '../../utils/helpers';
import { SideNav } from './components/SideNav';

import './DesignerApp.scss';

import ErrorPage from '../Pages/ErrorPage';

class DesignerApp extends Component {

  render () {
    let templateType = this.props.templateProperties.templateType;
    let designerConf = designersConfiguration[templateType];
    if (!designerConf) {
      return <ErrorPage message={'Not supported template!'}/>;
    }

    let Board = designerConf.board;

    return <div className={'app ' + (this.props.embedded ? 'embedded' : '')}>

      <SideNav Resources = {designerConf.resources} t={this.props.t}/>

      <div className='appContent'>
        <Board  t={this.props.t}/>
      </div>
    </div>;
  }
}

export default DragDropContext(HTML5Backend)(DesignerApp);
