import React, { Component } from 'react';

import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import XCsvCellHeader from './XCsvCellHeader';
import { TextField } from '@cimpress/react-components';
import { XCsvCellItemProps } from '../../../models/XBlockProps';

class XCsvCellItem extends Component<XCsvCellItemProps> {

  updateCellData (propertyName, value) {
    this.props.renderProps.onUpdateBlockMetaData({ id: this.props.id }, { [propertyName]: value });
  }

  render () {
    let itemProps = this.props.renderProps.flatStructure[this.props.id].properties;
    const { t } = this.props;

    return <div 
      className='section-panel csv-cell-container' {...this.props.defaultHandlers}>
      <XCsvCellHeader {...this.props}/>
      <div onMouseDown={e => e.stopPropagation()} >
        <TextField
          className='cell-text-input'
          value={itemProps.metadata.text} 
          onChange={(e) => this.updateCellData('text', e.target.value)}
          label={t('blocks.cell_text_placeholder')} />
      </div>
    </div>;
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.COLUMN,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.COLUMN, source, collect)(XCsvCellItem);
