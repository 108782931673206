import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from './DefaultButton';
import { useTranslation } from 'react-i18next';

let ConfirmButton = (props) => {
  const { t } = useTranslation();
  return <DefaultButton
    status={'primary'}
    title={props.title || t('save')}
    faIcon={props.faIcon}
    onClick={props.onClick}
    tooltip={props.tooltip}
    disabled={props.disabled}
    autoFocus={props.autoFocus}
    disabledTooltip={props.disabledTooltip}
  />;
};

ConfirmButton.propTypes = {
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  autoFocus: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string
};

ConfirmButton.defaultProps = {
  disabled: false,
  faIcon: 'fa-floppy-o'
};

export default ConfirmButton;
