import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';

import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import classNames from 'classnames';
import { DropTargetProps } from '../../../models/XBlockProps';

class XMainDropTarget extends Component<DropTargetProps> {

  render () {
    let target = <div className={classNames([
      'xemail-horizontal-drop-target',
      { 'xemail-dragging-can-drop-and-over': this.props.isDragging && this.props.canDrop && this.props.isOver },
      { 'xemail-dragging-can-drop': this.props.isDragging && this.props.canDrop && !this.props.isOver && !this.props.disabled }
    ])}>
      &nbsp;
    </div>;

    return this.props.disabled ? target : this.props.connectDropTarget(target);
  }
}
const ACCEPTED_BLOCK_TYPES = [
  EDIE_BLOCK_TYPE.TEXT,
  EDIE_BLOCK_TYPE.ROW,
  EDIE_BLOCK_TYPE.BUTTON,
  EDIE_BLOCK_TYPE.LOOP,
  EDIE_BLOCK_TYPE.CONDITION,
  EDIE_BLOCK_TYPE.VSPACER,
  EDIE_BLOCK_TYPE.IMAGE,
  EDIE_BLOCK_TYPE.RAW,
  EDIE_BLOCK_TYPE.MJML,
  EDIE_BLOCK_TYPE.SUB_TEMPLATE
];

const columnTarget = {
  drop (props, monitor, _component) {
    props.onDrop({ id: 'main' }, { type: monitor.getItemType(), metadata: monitor.getItem().resourceTypeMetadata, id: monitor.getItem().itemId  }, props.position);
  }
};

function collect (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    isDragging: ACCEPTED_BLOCK_TYPES.indexOf(monitor.getItemType()) !== -1
  };
}

export default DropTarget(ACCEPTED_BLOCK_TYPES, columnTarget, collect)(XMainDropTarget);
