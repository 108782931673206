import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Tooltip } from '@cimpress/react-components';
import LeftRightBlock from '../../../generic/LeftRightBlock';
import { Trans } from 'react-i18next';

class EmailStatusLegend extends Component {

  getNotificationsCount (type) {
    return this.props.pendingEmails.filter(a => a.status === type).length;
  }

  renderBadge (caption, count, className, showRunning) {
    if (count === 0) {
      return null;
    }

    let spinner = showRunning ? <i className={'fa fa-spinner fa-spin'} /> : null;

    return <Tooltip contents={caption}>
      <div className={'clearfix'}>
        <div className={'label ' + (count > 0 ? className : 'label-default')}>{count}</div>
        &nbsp;{caption}&nbsp;&nbsp;{spinner}
      </div>
    </Tooltip>;
  }

  render () {

    if (!this.props.pendingEmails || this.props.pendingEmails.length === 0) {
      return null;
    }

    const { t } = this.props;
    const hasRunningRequests = this.getNotificationsCount('RUNNING') > 0;

    return (
      <Portal>
        <div className='notifications'>
          <LeftRightBlock
            left={<h6><Trans>email_status</Trans></h6>}
            right={<button className='btn btn-link btn-sm' onClick={() => this.props.onDetailsClick()}>
              <Trans>details_ellip</Trans>
            </button>}
          />
          <div className='emailing-badge'>
            {this.renderBadge(t('successful'), this.getNotificationsCount('SENT'), 'label-success')}
          </div>
          <div className='emailing-badge'>
            {this.renderBadge(t('in_progress'), this.getNotificationsCount('RUNNING'), 'label-info', hasRunningRequests)}
          </div>
          <div className='emailing-badge'>
            {this.renderBadge(t('failed'), this.getNotificationsCount('FAILURE'), 'label-danger')}
          </div>
        </div>
      </Portal>
    );
  }
}

EmailStatusLegend.propTypes = {
  pendingEmails: PropTypes.array,
  onDetailsClick: PropTypes.func
};

export default EmailStatusLegend;
