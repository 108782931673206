import classNames from 'classnames';
import { useState } from 'react';
import React from 'react';
import PayloadModalButton from './payload/PayloadModalButton';

export const Context = React.createContext(false);

interface SideNavProps{
  t : Function;
  Resources: any;
}

export const SideNav = (props :SideNavProps ) => {
  let { t, Resources } = props;
  const [ showSideNav, setShowSideNav] = useState(true);

  let className = classNames(
    'sidebar',
    showSideNav ? 'active' : ''
  );
  return <div className={className}>
    <nav id="sidebar">
      <div style={{ marginBottom: '20px', marginLeft: '5px', cursor: 'pointer' }} onClick={() => setShowSideNav(!showSideNav)}>
        { showSideNav ? <span><strong><i className="fa fa-angle-double-left sideNav-icon" />&nbsp;&nbsp;{t('content')}</strong></span>
          : <i className="fa fa-angle-double-right sideNav-icon" />
        }
      </div>
      <div style={{ flexGrow: 1, overflowY: 'auto', padding: '0px 10px 0px 10px' }}>
        <Context.Provider value={showSideNav}>
          <PayloadModalButton
            t={t}
          />
          <hr className='horizontal-seperator'/>
          <Resources t={t}/>
        </Context.Provider>
      </div>
    </nav>
  </div>; 
};