export default class TemplateIncompatibleError {
  message: string;
  _data: any;

  constructor (message, data = undefined) {
    this.message = message;
    this._data = data;
  }

  get data () {
    return this._data;
  }
}
