import { ValueOf } from './helpers';

const SUBTYPES = {
  OPEN: 'open',
  CLOSE: 'close',
  NO_ESCAPE_HTML: 'noEscapeHtml',
  FORMULA: 'formula',
  CONDITION: 'condition'
} as const;

const removeHandlebarsSyntax = (placeholder: string) => {
  return placeholder.replace(/[{]{2,3}[#/]?|[}]{2,3}/g, '');
};

const calculateDisplayText = (placeholderText: string) => {
  const openingBracketsCount = (placeholderText.match(/[{]/g) || []).length;
  placeholderText = placeholderText.replace(/}+/g, '}'.repeat(openingBracketsCount));
  let displayText;
  if (placeholderText.includes(' ')) {
    // displayText = `${placeholderText.split(' ')[0]}(…)`
    displayText = '…';
  } else if (placeholderText.length > 5 && placeholderText.includes('.')) {
    displayText = placeholderText.split('.').slice(-1)
      .pop();
  } else {
    displayText = placeholderText;
  }
  displayText = removeHandlebarsSyntax(displayText);

  if (placeholderText.includes('{{{')) {
    displayText = `˂୵˃ ${displayText}`;
  }

  return displayText;
};

// TODO: Optimize this...
const analyzePlaceholder = (placeholder: string) => {
  let display = calculateDisplayText(placeholder);
  const subTypes: ValueOf<typeof SUBTYPES>[] = [];

  if (placeholder.includes('{{{')) {
    subTypes.push(SUBTYPES.NO_ESCAPE_HTML);
  }

  let conditions = ['if', 'not'];
  if (placeholder === '{{else}}' || conditions.some(x => placeholder.startsWith('{{#' + x) || placeholder.startsWith('{{/' + x))) {
    if (placeholder[2] === '#') {
      subTypes.push(SUBTYPES.OPEN);
      display = conditions.find(x => placeholder.startsWith('{{#' + x));
    } else if (placeholder[2] === '/') {
      subTypes.push(SUBTYPES.CLOSE);
      display = conditions.find(x => placeholder.startsWith('{{/' + x));
    } else {
      // leave default
    }
    subTypes.push(SUBTYPES.CONDITION);
  } else {
    if (placeholder.includes(' ')) {
      subTypes.push(SUBTYPES.FORMULA);
    }
    if (placeholder.includes('{{#')) {
      subTypes.push(SUBTYPES.OPEN);
    } else if (placeholder.includes('{{/')) {
      subTypes.push(SUBTYPES.CLOSE);
    }
  }


  return {
    subTypes,
    display,
    placeholder,
  };
};

const isPlaceholderValid = (placeholder: string) => {
  if (placeholder && placeholder.match(/^{{.+?(?=}})}}$/)) {
    const phContent = placeholder.substring(2, placeholder.length - 2);
    return !phContent.includes('{{') && !phContent.includes('}}') && !phContent.match(/\.\d+$/);
  }
  return false;
};


export {
  SUBTYPES,
  analyzePlaceholder,
  isPlaceholderValid
};
