import { previewDirect } from '../api/stereotype.api';

export default class TemplateRenderer {
  templateContentType: any;
  materializationContentType: any
  
  constructor (templateContentType, materializationContentType) {
    this.templateContentType = templateContentType;
    this.materializationContentType = materializationContentType;
  }

  preRender(template) {
    return template;
  }

  preProcessContentType() {
    return this.templateContentType;
  }

  renderPreviewPromise (template, payload, headers, accessToken, isBinaryResponse) {

    const contentType = this.preProcessContentType();
    const content = this.preRender(template);

    return previewDirect(template, payload, accessToken, contentType, content, headers, isBinaryResponse)
      .then((response) => {
        if (response.result) {
          let contentResult = response.result;          
          switch (this.materializationContentType) {
            case 'text/html':
            case 'text/mjml+html':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/pdf':  
              return {
                result: contentResult,
                blobResult: response.result
              };
            case 'text/plain':
            default:
              if (response.contentType.includes('application/json')) {
                contentResult = JSON.stringify(contentResult, null, 2);
              }
              const escapeHtml = contentResult.replace(/&/g, '&amp;').replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
              return {
                result: `<pre>${escapeHtml.replace(/\r\n?/g, '<br>')}</pre>`,
                blobResult: response.result
              };
          }
        } else {
          return null;
        }
      });
  }
}
