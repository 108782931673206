import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
const TAB_SIZE = 2;

export default class JSONRawEditor extends Component {
  render() {
    return <AceEditor
      value={this.props.payload}
      onChange={this.props.onChange}
      className={'ResourcesAvailablePayloadTextArea'}
      mode='json'
      name='payload'
      theme='github'
      fontSize={14}
      tabSize={TAB_SIZE}
      width={'100%'}
      showLineNumbers
      showGutter
      showPrintMargin={false}
      readOnly={this.props.disabled}
      enableSnippets
      enableLiveAutocompletion
      enableBasicAutocompletion
      setOptions={{ useWorker: false }}
      editorProps={{ $blockScrolling: Infinity }} />;
  }
}

JSONRawEditor.propTypes = {
  payload: PropTypes.string,
  onChange: PropTypes.func,
  disabled : PropTypes.bool
};
