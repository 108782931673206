import React, { Component } from 'react';
import { DragSource } from 'react-dnd';

import { draggableResource, FullWidthItem } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { XBlockProps } from '../../../models/XBlockProps';
import { XBlockState } from '../../../models/XBlockState';

interface XRowHeaderProps extends XBlockProps {
  className: string;
}

class XRowHeader extends Component<XRowHeaderProps, XBlockState> {

  render () {
    return draggableResource( this.props,
      <div className={`xemail-block-default-header xemail-row-header ${this.props.isElementFocused ? 'xboard-block-highlight' : ''}` + (this.props.className || '')} >
        <FullWidthItem>&nbsp;row</FullWidthItem>
      </div>
    );
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.ROW,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}

export default DragSource(EDIE_BLOCK_TYPE.ROW, source, collect)(XRowHeader);
