import React, { Component } from 'react';
import { Select, TextField, colors, Tooltip, Checkbox } from '@cimpress/react-components';
import LeftRightBlock from '../../../generic/LeftRightBlock';
import { connect } from 'react-redux';
import * as appActions from '../../../../store/app/actions';
import { withTranslation } from 'react-i18next';
import { LineEndings, convertContentTypeToName, addOrRemoveTagKeys } from '../../../../utils/helpers';
import { TemplateType, SupportedContentTypesValueLabel } from '../../../../constants';
import { IconLockAlt } from '@cimpress-technology/react-streamline-icons';
import { convertToOptions } from '../../../../utils/options';

class TemplateProperties extends Component {

  constructor (props) {
    super(props);
    this.state = {
      contentTypeLocked: true
    };
  }

  renderTemplateName() {
    const { t } = this.props;

    return <TextField
      name='templateName'
      label={t('toolkit.template_name')}
      value={this.props.templateProperties.templateName}
      onChange={(e) => this.props.onPropertiesChange('templateName', e.target.value)}
    />;
  }

  renderLineEnding() {
    if (this.props.templateProperties.templateType !== TemplateType.RAW) {
      return null;
    }

    const opts = LineEndings.supportedLineEndings();
    const selected = convertToOptions(LineEndings.getLineEnding(this.props.templateObject), opts);

    return <Select
      label={this.props.t('toolkit.raw_editor_line_endings')}
      value={selected}
      options={opts}
      onChange={(v) => this.props.onPropertiesChange('lineEnding', v.value)}
      isClearable={false}
      isSearchable={false}
    />;
  }

  renderContentTypeSelection () {
    const { t } = this.props;
    let { info } = colors;

    let readOnlyContentType = <TextField
      name='contentType'
      label={t('toolkit.content_type')}
      value={convertContentTypeToName(this.props.templateProperties.contentType)}
      readOnly={this.state.contentTypeLocked}
      rightAddon={
        <button className='btn btn-default'
          onClick={() => this.setState({ contentTypeLocked: !this.state.contentTypeLocked })}>
          <Tooltip direction={'top'}
            contents={t('toolkit.changing_content_type_is_expert_feature')}>
            <IconLockAlt color={info.base} />
          </Tooltip>
        </button>
      } />;

    let editableContentType = <Select
      label={t('toolkit.content_type')}
      value={convertToOptions(this.props.templateProperties.contentType, SupportedContentTypesValueLabel)}
      options={SupportedContentTypesValueLabel}
      onChange={(v) => this.props.onPropertiesChange('contentType', v.value)}
      isClearable={false}
    />;

    return this.state.contentTypeLocked ? readOnlyContentType : editableContentType;
  }

  renderVisibility() {
    const { t } = this.props;

    const opts = [{
      value: 'true', label: t('public')
    }, {
      value: 'false', label: t('private')
    }];

    return <Select
      label={t('template_visibility')}
      value={convertToOptions(this.props.templateProperties.isPublic.toString(), opts)}
      options={opts}
      onChange={(v) => this.props.onPropertiesChange('isPublic', v.value)}
      isClearable={false}
    />;
  }

  renderPartialTemplate() {
    const { t } = this.props;
    const metadata = this.props.templateProperties.metadata || [];
    let isPartialTemplate = !!metadata.find(tag => tag.key === 'isPartialTemplate');

    return <LeftRightBlock style={{ whiteSpace: 'normal' }}
      left={<Checkbox
        label={t('toolkit.partialTemplate')}
        checked={isPartialTemplate}
        onChange={() => this.props.onPropertiesChange('metadata', addOrRemoveTagKeys(metadata, 'isPartialTemplate') )} />}
      right={<Tooltip
        contents={t('toolkit.partialTemplateHelp')}
        direction={'right'}>
        <i className={'fa fa-2x fa-question-circle context-button-icon'} />
      </Tooltip>} />;
  }

  render () {
    return (
      <div className='propertyBar'>
        {this.renderTemplateName()}
        {this.renderLineEnding()}
        {this.renderVisibility()}
        {this.renderContentTypeSelection()}
        {[TemplateType.RAW, TemplateType.XEMAIL, TemplateType.XDOC].includes(this.props.templateProperties.templateType)
          && this.renderPartialTemplate()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    templateObject: state.appReducer.templateObject,
    templateProperties: state.appReducer.templateProperties
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPropertiesChange: (name, value) => {
      dispatch(appActions.templatePropertyChanged(name, value));
      dispatch(appActions.setIsTemplateModified(true));
    }
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TemplateProperties));
