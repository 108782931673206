import React, { Component } from 'react';

import { FullWidthVerticalCenter } from '../../commonComponents';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import classNames from 'classnames';
import { TerminalCommonProps } from '../../../models/XBlockProps';
import { XBlockStateWithContent } from '../../../models/XBlockState';
import { parseHandlebarsExpression, PropertyType } from '../../../../../../utils/helpers';

class XButton extends Component<TerminalCommonProps, XBlockStateWithContent> {
  constructor(props: TerminalCommonProps) {
    super(props);
    let itemProps = this.props.renderProps.item.properties;
    this.state = {
      content: itemProps.content
    };
  }

  renderHeader(item) {
    const itemProps = item.properties;
    const hasClass = !!itemProps.class;
    const style = hasClass ? undefined : {
      backgroundColor: parseHandlebarsExpression(PropertyType.COLOR, itemProps.backgroundColor, this.props.renderProps.providedOriginalPayload, '#188ec5'),
      color: parseHandlebarsExpression(PropertyType.COLOR, itemProps.color, this.props.renderProps.providedOriginalPayload, '#ffffff'),
      textAlign: itemProps.align,
      border: (itemProps.borderSize || '0px') + ' solid ' + parseHandlebarsExpression(PropertyType.COLOR, itemProps.borderColor, this.props.renderProps.providedOriginalPayload, 'transparent')
    };

    return this.props.connectDragSource && this.props.connectDragSource(<div className={'xemail-block-default-header'}>
      <FullWidthVerticalCenter>
        <div className={classNames({ 'xemail-button': !hasClass }, { 'btn': hasClass }, { [itemProps.class || '']: hasClass })} style={style}>
          {itemProps.content}
        </div>
      </FullWidthVerticalCenter>
    </div>);
  }

  render() {
    let defaultClass = this.props.isElementFocused ? 'xemail-block-default-higlight-container' : 'xemail-block-default-container';
    
    let className = classNames(
      defaultClass,
      'xemail-button-container'
    );    

    return <div className={className} {...this.props.defaultHandlers}>
      {this.renderHeader(this.props.renderProps.item)}
    </div>;
  }
}

const source = {
  beginDrag(props: TerminalCommonProps) {
    return {
      resourceType: EDIE_BLOCK_TYPE.BUTTON,
      itemId: props.id
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(EDIE_BLOCK_TYPE.BUTTON, source, collect)(XButton);
