/* eslint-disable quotes */
import React, { Component, Fragment } from 'react';
import { ColorUtils } from '../../../../../../utils/helpers';
import TextFieldColorPicker from '../../../../../generic/TextFieldColorPicker';
import { Select, TextField, RadioGroup, Radio, Checkbox } from '@cimpress/react-components';
import CssPaddingSelector from '../../../../../generic/CssPaddingSelector';
import {
  convertToOptions,
  SupportedAlignmentsOptions, SupportedBarcodesOptions,
  SupportedBorderSizesOptions
} from '../../../../../../utils/options';
import { XBlockPropertiesProps } from '../../../models/XBlockProps';
import { DEBOUNCE_TIME_INTERVAL } from '../../../../../../constants';
import debounce from 'debounce';

interface XImagePropertiesState {
  updates: any;
  imageSourceType: any;
  fileNameSelected?: string;
}

class XImageProperties extends Component<XBlockPropertiesProps, XImagePropertiesState> {
  updateProperties:Function;

  constructor(props) {
    super(props);
    this.state = {
      updates: props.properties,
      imageSourceType: !(props.properties.href) && !(props.properties.src) ? 'upload' : 'src',
    };
    this.updateProperties = debounce(this.confirm,DEBOUNCE_TIME_INTERVAL);
  }

  componentDidUpdate(prevProps,prevState){
    if (JSON.stringify(prevProps.properties) !== JSON.stringify(this.props.properties)) {
      this.setState({
        updates: this.props.properties
      });
    }
    if (JSON.stringify(prevState.updates) !== JSON.stringify(this.state.updates) && prevProps.id === this.props.id){
      this.updateProperties();
    }
    if (prevProps.id !== this.props.id){
      this.props.onUpdateItem({ id: prevProps.id }, this.state.updates);
    }
  }

  confirm() {
    this.props.onUpdateItem({ id: this.props.id }, this.state.updates);
  }

  updatedImageFromUpload(e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({ updates: Object.assign({}, this.state.updates, { src: reader.result }), fileNameSelected: file.name });
    };
    reader.readAsDataURL(file);
  }

  static getBarcodeUrl(value, type, renderValue) {
    return `https://stripes.trdlnk.cimpress.io/v0/barcode?entity=${value || ''}&format=${type || 'code128'}&displayEntity=${!renderValue || renderValue === 'true'}`;
  }

  renderConfigurationProperties() {
    let { t } = this.props;
    const props = this.state.updates || this.props.properties || {};

    if (this.isBarcode()) {
      return <Fragment>
        <Fragment>
          <TextField
            label={t('blocks.barcode_entity')}
            value={props.metadata.barcodeValue || ''}
            onChange={(e) => {
              let updates = JSON.parse(JSON.stringify(this.state.updates));
              updates.metadata.barcodeValue = e.target.value;
              updates.src = XImageProperties.getBarcodeUrl(updates.metadata.barcodeValue, updates.metadata.barcodeType, updates.metadata.barcodeRenderValue);
              this.setState({ updates });
            }}
            autoFocus />
          <Select
            name='barcode_type'
            label={t('blocks.barcode_type')}
            value={convertToOptions(props.metadata.barcodeType || 'CODE128', SupportedBarcodesOptions)}
            options={SupportedBarcodesOptions}
            onChange={(e: any) => {
              let updates = JSON.parse(JSON.stringify(this.state.updates));
              updates.metadata.barcodeType = e.value;
              updates.src = XImageProperties.getBarcodeUrl(updates.metadata.barcodeValue, updates.metadata.barcodeType, updates.metadata.barcodeRenderValue);
              this.setState({ updates });
            }}
            isClearable={true}
          />
          <Checkbox
            label={t('blocks.barcode_render_value')}
            checked={!props.metadata.barcodeRenderValue || props.metadata.barcodeRenderValue.toString().toLowerCase() === 'true'}
            onChange={() => {
              let current = !props.metadata.barcodeRenderValue || props.metadata.barcodeRenderValue.toString().toLowerCase() === 'true';
              let updates = JSON.parse(JSON.stringify(this.state.updates));
              updates.metadata.barcodeRenderValue = current ? 'false' : 'true';
              updates.src = XImageProperties.getBarcodeUrl(updates.metadata.barcodeValue, updates.metadata.barcodeType, updates.metadata.barcodeRenderValue);
              this.setState({ updates });
            }}
          />
          <TextField
            label={t('blocks.image_alt')}
            value={props.alt || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { alt: e.target.value }) })}
          />
          <TextField
            label={t('blocks.image_href')}
            value={props.href || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { href: e.target.value }) })}
            autoFocus
          />
          <TextField
            label={t('blocks.image_condition')}
            helpText={t('blocks.image_condition_help')}
            value={props.condition || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { condition: e.target.value }) })}
          />
          <TextField
            label={t('blocks.image_condition_alt')}
            helpText={t('blocks.image_condition_alt_help')}
            value={props.conditionAlt || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { conditionAlt: e.target.value }) })}
          />
          <TextField
            name='class'
            label={t('blocks.class')}
            helpText={t('blocks.class_help_text')}
            value={props.class}
            onChange={(e) => {
              this.setState({ updates: Object.assign({}, this.state.updates, { class: e.target.value }) });
            }} />

          {props.condition
            ? <Fragment>
              <br />
              {t('blocks.image-condition-sample-caption')}
              <div className={'image-condition-demo'}>
                <div className='image-condition-demo-group-begin'>{'{{#if '}{props.condition}{'}}'}</div>
                <div className='image-condition-demo-placeholder'>&lt;img ... &gt;</div>
                <div className='image-condition-demo-group-begin'>{'{{else}}'}</div>
                <div className='image-condition-demo-placeholder'>{props.conditionAlt}</div>
                <div className='image-condition-demo-group-begin'>{'{{/if}}'}</div>
              </div>
            </Fragment>
            : null}
        </Fragment>
      </Fragment>;
    }

    return <Fragment>

      <RadioGroup
        name='ximage-radiogroup'
        className='ximage-radiogroup'
        inline
        defaultSelected={this.state.imageSourceType}
        onChange={(e, value) => this.setState({ imageSourceType: value })}>
        <Radio label={t('blocks.image_from_file_upload')} value={'upload'} />
        <Radio label={t('blocks.image_src')} value={'src'} />
      </RadioGroup>
      {this.state.imageSourceType === 'upload'
        ? (
          <Fragment>
            <div className='ximage-upload-form'>
              <input
                id='image-file-upload'
                type='file'
                accept='image/*'
                onChange={(e) => this.updatedImageFromUpload(e)} />
              <label htmlFor='image-file-upload' className='btn btn-default labelForInputImageFile'>
                {t('blocks.image_choose_image')}
              </label>
              <em>{this.state.fileNameSelected || t('blocks.image_no_file_selected')}</em>
            </div>
            <TextField
              label={t('blocks.image_alt')}
              value={props.alt || ''}
              onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { alt: e.target.value }) })}
            />
            <TextField
              label={t('blocks.image_href')}
              value={props.href || ''}
              onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { href: e.target.value }) })}
              autoFocus
            />
          </Fragment>)
        : <Fragment>
          <TextField
            label={t('blocks.image_src')}
            value={props.src || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { src: e.target.value }) })}
            autoFocus />
          <TextField
            label={t('blocks.image_alt')}
            value={props.alt || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { alt: e.target.value }) })}
          />
          <TextField
            label={t('blocks.image_href')}
            value={props.href || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { href: e.target.value }) })}
            autoFocus
          />
          <TextField
            label={t('blocks.image_condition')}
            helpText={t('blocks.image_condition_help')}
            value={props.condition || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { condition: e.target.value }) })}
          />
          <TextField
            label={t('blocks.image_condition_alt')}
            helpText={t('blocks.image_condition_alt_help')}
            value={props.conditionAlt || ''}
            onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { conditionAlt: e.target.value }) })}
          />
          {props.condition
            ? <Fragment>
              <br />
              {t('blocks.image-condition-sample-caption')}
              <div className={'image-condition-demo'}>
                <div className='image-condition-demo-group-begin'>{'{{#if '}{props.condition}{'}}'}</div>
                <div className='image-condition-demo-placeholder'>&lt;img ... &gt;</div>
                <div className='image-condition-demo-group-begin'>{'{{else}}'}</div>
                <div className='image-condition-demo-placeholder'>{props.conditionAlt}</div>
                <div className='image-condition-demo-group-begin'>{'{{/if}}'}</div>
              </div>
            </Fragment>
            : null}
        </Fragment>
      }
    </Fragment>;
  }

  isBarcode() {
    const props = this.state.updates || this.props.properties || {};

    return (props.metadata || {}).uiType === 'barcode';
  }

  render() {
    const props = this.state.updates || this.props.properties || {};
    const { t } = this.props;

    const stylesProperties = <Fragment>
      <TextField
        label={t('blocks.width')}
        value={props.width}
        helpText={t('blocks.leave_empty_for_full_width')}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { width: e.target.value }) })}
      />
      <TextFieldColorPicker
        name='backgroundColor'
        label={t('blocks.container_background_color')}
        color={ColorUtils.rgb2hex(props.backgroundColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { backgroundColor: hexColor }) });
        }} />

      <CssPaddingSelector
        t={t}
        label={t('blocks.padding')}
        helpText={t('blocks.padding_help_text')}
        padding={props.padding}
        onChange={(value) => this.setState({ updates: Object.assign({}, this.state.updates, { padding: value }) })}
      />
      <Select
        name='borderSize'
        label={t('blocks.border_size')}
        value={convertToOptions(props.borderSize || '0', SupportedBorderSizesOptions)}
        options={SupportedBorderSizesOptions}
        onChange={(e: any) => this.setState({ updates: Object.assign({}, this.state.updates, { borderSize: e.value }) })}
        isClearable={true}
      />
      <TextFieldColorPicker
        name='borderColor'
        label={t('blocks.border_color')}
        color={ColorUtils.rgb2hex(props.borderColor)}
        onTextChange={(e) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { borderColor: e.target.value }) });
        }}
        onColorChange={(hexColor) => {
          this.setState({ updates: Object.assign({}, this.state.updates, { borderColor: hexColor }) });
        }}
      />
      <Select
        name='align'
        label={t('blocks.image_align')}
        value={convertToOptions(props.align, SupportedAlignmentsOptions)}
        options={SupportedAlignmentsOptions}
        onChange={(e) => this.setState({ updates: Object.assign({}, this.state.updates, { align: e ? e.value : null }) })}
        isClearable={true}
      />

    </Fragment>;

    const configurationProperties = this.renderConfigurationProperties();
    const isBarcode = this.isBarcode();

    return <React.Fragment>
      <h5>{isBarcode ? t('blocks.barcode_configuration_tab') : t('blocks.image_configuration_tab')}</h5>
      {configurationProperties}
      <h5>{isBarcode ? t('blocks.barcode_styles_tab') : t('blocks.image_styles_tab')}</h5>
      {stylesProperties}
    </React.Fragment>;
  }
}

export default XImageProperties;
