import React, { Component } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import Split from 'react-split';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import XEmailHeaders from './XEmailHeaders';
import { renderBlock, renderBlockSettings } from './blockRenderer';

import './styles/XEmailBoard.scss';
import * as edieActions from '../../../../store/edie/actions';
import * as appActions from '../../../../store/app/actions';
import { withTranslation } from 'react-i18next';
import { TemplateType } from '../../../../constants';
import { BlockProperties } from './BlockProperties';
import config from './config';

class XEmailBoard extends Component {

  constructor(props) {
    super(props);
    this.toolbarSpotRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.resetAppReducer();
    this.props.resetEdieReducer();
  }

  render() {

    if (!this.props.edie) {
      this.props.initializeEdie(this.props.templateObject);
      return null;
    }

    let options = this.props.edie.flatStructure['main'].properties || {};

    const renderProps = {
      t: this.props.t,
      onAddOrMoveItem: this.props.onAddOrMoveItem,
      onElseConditionAddItem: this.props.onElseConditionAddItem,
      onSelectItem: this.props.onSelectItem,
      onRemoveItem: this.props.onRemoveItem,
      onUpdateItem: this.props.onUpdateItem,
      onCloneItem: this.props.onCloneItem,
      onAddingNItems: this.props.onAddingNItems,
      toolbarSpotRef: this.toolbarSpotRef,
      flatStructure: this.props.edie.flatStructure,
      currentlySelectedElementId: this.props.edie.currentlySelectedElementId,
      providedOriginalPayload: this.props.payload?.providedOriginalPayload || {}
    };

    return <div className='full-width' style={{ height: '100%' }}>
      <StickyContainer className='boardWrapper' style={{ display: 'flex' }}>
        <Split direction="horizontal" className="board-split" sizes={[100, 0]} gutterSize={8}>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Sticky topOffset={0} bottomOffset={10}>
              {(props) => {
                let style = {};
                return <div style={Object.assign({}, props.style, style)}>
                  <div ref={this.toolbarSpotRef}>
                    {/*render toolbar */}
                  </div>
                </div>;
              }}
            </Sticky>
            <div className='xboard' style={{ backgroundColor: options.emailBackgroundColor }}>

              {this.props.templateProperties.templateType === TemplateType.XEMAIL && <div className={'xboard-header'}>
                <XEmailHeaders
                  t={this.props.t}
                  headers={this.props.edie.mimeHeaders}
                  onHeaderChange={(name, value) => {
                    this.props.onMimeHeaderUpdate(name, value);
                  }} />
              </div>}

              <div className='xemail'>
                {renderBlock(
                  'main',
                  renderProps
                )}
              </div>
            </div>
          </div>
          <BlockProperties
            renderProps={renderProps}
            edie={this.props.edie}
            templateProperties={this.props.templateProperties}
            renderBlockSettings={renderBlockSettings}
            config={config} />
        </Split>
      </StickyContainer>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    edie: state.edie,
    templateObject: state.appReducer.templateObject,
    templateProperties: state.appReducer.templateProperties,
    payload: state.payload,

    // profile
    designerProfile: state.designerProfiles.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeEdie: (templateObject) => {
      dispatch(edieActions.initStructure(templateObject));
    },
    onAddOrMoveItem: (parent, item, position) => {
      if (item.id) {
        dispatch(edieActions.moveItem(parent, item, position));
      } else {
        dispatch(edieActions.addItem(parent, item, position));
      }
      dispatch(appActions.setIsTemplateModified(true));
    },
    onElseConditionAddItem: (parent, item, position) => {
      if (item.id) {
        dispatch(edieActions.moveItem(parent, item, position));
      } else {
        dispatch(edieActions.elseConditionAddItem(parent, item, position));
      }
      dispatch(appActions.setIsTemplateModified(true));
    },
    onSelectItem: (itemId) => {
      dispatch(edieActions.selectItem(itemId));
    },
    onCloneItem: (parent, item) => {
      dispatch(edieActions.cloneItem(parent, item));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onUpdateItem: (item, data) => {
      dispatch(edieActions.updateItem(item, data));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onRemoveItem: (item) => {
      dispatch(edieActions.removeItem(item));
      dispatch(edieActions.selectItem('main'));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onAddingNItems: (parent, item) => {
      dispatch(edieActions.addNItem(parent, item));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onMimeHeaderUpdate: (name, value) => {
      dispatch(edieActions.setMimeHeader(name, value));
      dispatch(appActions.setIsTemplateModified(true));
    },
    onPlainTextUpdate: (content) => {
      dispatch(edieActions.setPlainTextVersion(content));
      dispatch(appActions.setIsTemplateModified(true));
    },
    resetAppReducer: () => {
      dispatch(appActions.resetAppReducer());
    },
    resetEdieReducer: () => {
      dispatch(edieActions.resetEdieReducer());
    },
  };
};

XEmailBoard.propTypes = {
  // redux data
  edie: PropTypes.any,
  templateObject: PropTypes.any,
  templateProperties: PropTypes.any,

  // redux action
  onAddItem: PropTypes.func,
  onElseConditionAddItem:  PropTypes.func,
  onSelectItem: PropTypes.func,
  onUpdateItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onMoveItem: PropTypes.func,
  onCloneItem: PropTypes.func,
  onMimeHeaderUpdate: PropTypes.func,
  onPlainTextUpdate: PropTypes.func,
};

XEmailBoard.defaultProps = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(XEmailBoard));
