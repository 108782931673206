import React, { Component } from 'react';
import XTextResource from './blocks/XText/XTextResource';
import XButtonResource from './blocks/XButton/XButtonResource';
import XRowResource from './blocks/XRow/XRowResource';
import XLoopResource from './blocks/XLoop/XLoopResource';
import XConditionResource from './blocks/XCondition/XConditionResource';
import XColumnResource from './blocks/XColumn/XColumnResource';
import XVerticalSpacerResource from './blocks/XVerticalSpacer/XVerticalSpacerResource';
import XImageResource from './blocks/XImage/XImageResource';
import XBarcodeResource from './blocks/XBarcode/XBarcodeResource';
import XRawHtmlResource from './blocks/XRawHtml/XRawHtmlResource';
import XRawMjmlResource from './blocks/XRawMjml/XRawMjmlResource';
import XSubTemplateResource from './blocks/XSubTemplate/XSubTemplateResource';
import { getDesignerProfile } from '../../../../profiles/profiles';
import { connect } from 'react-redux';
class XEmailResourcesGroup extends Component {
  render() {

    const templateComponents = getDesignerProfile(this.props.designerProfile).templateComponents;

    const componentsToRender = [
      XTextResource,
      XButtonResource,
      XRowResource,
      XColumnResource,
      XLoopResource,
      XConditionResource,
      XVerticalSpacerResource,
      XImageResource,
      XBarcodeResource,
      (templateComponents.htmlBlock.enabled ? XRawHtmlResource : null),
      (templateComponents.mjmlBlock.enabled ? XRawMjmlResource : null),
      (templateComponents.subTemplate.enabled ? XSubTemplateResource : null),
    ];

    const filteredComponents = componentsToRender.filter( a => a );
    
    return <React.Fragment>
      {filteredComponents.map((Component, index) => 
        <div key={index} className={'element-block'}>
          <Component/>
        </div>
      )}
    </React.Fragment>;
  }
}
const mapStateToProps = state => {
  return {
    // profile
    designerProfile: state.designerProfiles.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(XEmailResourcesGroup);
