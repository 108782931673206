import React, { Component } from 'react';
import { Tooltip } from '@cimpress/react-components';

import './XColumn.scss';
import { EDIE_BLOCK_TYPE } from '@cimpress-technology/edie-processors';
import { DragSource } from 'react-dnd';
import { draggableResource, FullWidthItem } from '../../commonComponents';
import classNames from 'classnames';
import { XColumnHeaderProps } from '../../../models/XBlockProps';

class XColumnHeader extends Component<XColumnHeaderProps> {

  render() {
    const item = this.props.renderProps.flatStructure[this.props.id];
    const itemProps = item.properties;
    const columnWidthInconsistency = this.props.renderProps.rowWidth !== 100;
    let className = classNames(
      'xemail-block-default-header',      
      `${this.props.isElementFocused ? 'xboard-block-highlight' : 'xemail-column-header'}`
    );

    return draggableResource( this.props, <div className={className}>
      <FullWidthItem>
        {itemProps.width
          ? <strong className={columnWidthInconsistency ? 'width-error' : ''}>
            {columnWidthInconsistency
              ? <Tooltip contents={'Total width of all columns must be 100%! Please adjust.'}>
                {itemProps.width}
              </Tooltip>
              : itemProps.width}
          </strong>
          : ' '}
      </FullWidthItem>
    </div>);
  }
}

const source = {
  beginDrag (props) {
    return {
      resourceType: EDIE_BLOCK_TYPE.COLUMN,
      itemId: props.id
    };
  }
};

function collect (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingItemId: (monitor.getItem() ? monitor.getItem().itemId : null)
  };
}

export default DragSource(EDIE_BLOCK_TYPE.COLUMN, source, collect)(XColumnHeader);
