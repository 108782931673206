import React, { Component } from 'react';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

interface LoadingPageProps {
  loadingMessage: string;
}

export default class LoadingPage extends Component<LoadingPageProps> {
  render () {
    return (
      <div className='loadingLayout'>
        <Spinner />
        <h2>{this.props.loadingMessage}</h2>
      </div>);

  }
}
