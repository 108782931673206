import Request from 'superagent';

const characters = 'abcdefghijklmnopqrstuwvxyzABCDEFGHIJKLMNOPQRSTUWVXYZ';

// must match /^[\w-_.]{3,20}$/ according to cim.link website
const generateShortId = (): string => {
  let shortId = 'td-';

  for (let i = 0; i < 8; i++) {
    shortId += characters[Math.floor(Math.random() * characters.length)];
  }

  return shortId;
};

const getShortUrl = (accessToken: string, longUrl: string): Promise<string> => {
  let shortId = generateShortId();

  return Request
    .put(`https://api.cork.cimpress.cloud/cimlink/v1/urls/${shortId}`)
    .set('Authorization', `Bearer ${accessToken}`)
    .set('Content-Type', 'application/json')
    .send({
      'destination': longUrl
    })
    .then(_a => `https://cim.link/${shortId}`);
};

export {
  getShortUrl
};
