export interface OptionType {
  value: string;
  label: string;
}

const toOptions = (ar: string[]): OptionType[] => ar.map(k => ({
  value: k,
  label: k
}));


export const convertToOptions = (selectedValue: string, opts: OptionType[]): OptionType | undefined => {
  return opts.find(a => a.value === selectedValue);
};

export const SupportedButtonPlatformClasses = ['btn-dark', 'btn-default', 'btn-primary', 'btn-outline-dark'];
export const SupportedButtonPlatformClassesOptions = toOptions(SupportedButtonPlatformClasses);

export const SupportedLineStyles = ['solid', 'dashed', 'dotted', 'double'];
export const SupportedLineStylesOptions = toOptions(SupportedLineStyles);

export const SupportedLineHeights = ['1px', '2px', '4px', '5px', '10px', '20px'];
export const SupportedLineHeightsOptions = toOptions(SupportedLineHeights);

export const SupportedBorderSizes = ['0px', '1px', '2px', '3px'];
export const SupportedBorderSizesOptions = toOptions(SupportedBorderSizes);

export const EmailWidths = ['500px', '550px', '600px', '650px', '700px', '80%', '100%'];
export const EmailWidthsOptions = toOptions(EmailWidths);

export const EmailStylesOptions = [{
  value: '',
  label: 'Regular'
}, {
  value: '{{> urn:stereotype:template:868fdd95-c008-462d-939c-3bd421a371e7 }}',
  label: 'Platform Styles'
}];

export const SupportedAlignments = ['left', 'center', 'right'];
export const SupportedAlignmentsOptions = toOptions(SupportedAlignments);

export const SupportedBarcodes =  ['CODE128', 'QR'];
export const SupportedBarcodesOptions = toOptions(SupportedBarcodes);

export const SupportedColumnWidths = ['5%', '10%', '14%', '15%', '16%', '17%', '20%', '25%', '30%', '33%', '34%',
  '35%', '40%', '45%', '50%', '55%', '60%', '65%', '70%',
  '75%', '80%', '85%', '90%', '95%', '100%'];
export const SupportedColumnWidthsOptions = toOptions(SupportedColumnWidths);

export const SupportedButtonWidths =  ['100%', '50px', '75px', '100px', '125px', '150px', '200px'];
export const SupportedButtonWidthsOptions = toOptions(SupportedButtonWidths);
export const DefaultColumnWidth = {
  1: ['100%'],
  2: ['50%','50%'],
  3: ['33%','34%','33%'],
  4: ['25%','25%','25%','25%'],
  5: ['20%','20%','20%','20%','20%'],
  6: ['16%','17%','17%','17%','17%','16%'],
  7: ['15%','14%','14%','14%','14%','14%','15%']
};
