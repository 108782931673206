import Request from 'superagent';
import { Stereotype } from '../constants';
import moment from 'moment-timezone';

const BASE_URL = process.env.REACT_APP_PUREMAIL_URL;

const sendEmail = (accessToken: string, idTemplate: string, jsonResources) => {
  return Request
    .post(BASE_URL + '/v1/send/' + idTemplate)
    .set('Authorization', 'Bearer ' + accessToken)
    .set('Content-Type', 'application/json')
    .set('x-cimpress-rel-blacklist', Stereotype.xCimpressRelBlacklist)
    .set('x-cimpress-accept-preference', Stereotype.acceptPreference)
    .set('x-cimpress-rel-curies', 'https://relations.cimpress.io/pool/print-job;fen')
    .send(jsonResources)
    .then(a => a.body);
};

const sendRawEmail = (accessToken: string, rawMime) => {
  return Request
    .post(BASE_URL + '/v1/sendRawEmail')
    .set('Authorization', 'Bearer ' + accessToken)
    .set('Content-Type', 'text/plain')
    .send(rawMime)
    .then(a => a.body);
};

const getStatus = (accessToken: string, requestId)  => {
  return Request
    .get(BASE_URL + '/v1/status/' + requestId + '?skipCache=' + Math.random())
    .set('Authorization', 'Bearer ' + accessToken)
    .then(a => Object.assign({}, a.body, { statusDate: moment().format() }));
};

export {
  sendEmail,
  sendRawEmail,
  getStatus
};
